import React, { useEffect, useState } from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import {
    CURRENCIES,
    CURRENCY_URL,
    CONTACT,
    REGISTERURL,
    CURRENCY_CONVERT_URL,
    currency25percent,
    bankingFixedCharges,
} from "../../constant";
import axios from "axios";
import Select from "react-select";
import CountrySelect from "./CountrySelect";

import convertA from "../../assets/images/solution/convert/convert-image-8.png";
import convertB from "../../assets/images/solution/convert/convert-image-9.png";
import convertC from "../../assets/images/solution/convert/convert2.svg";
import convertD from "../../assets/images/solution/convert/social-care.svg";
import convertE from "../../assets/images/solution/convert/convert-image-1.svg";
import convertF from "../../assets/images/solution/convert/convert-image-2.svg";
import convertG from "../../assets/images/solution/convert/convert-image-3.svg";

function Convert() {
    const [fromcurrency, setFromCurrency] = useState([]);
    const [tocurrency, setToCurrency] = useState([]);
    const [price, setPrice] = useState('1,000.00');
    const [fromsymbol, setFromSymbol] = useState("$");
    const [tosymbol, setToSymbol] = useState("€");
    const [sellcode, setSellcode] = useState("USD");
    const [buycode, setBuycode] = useState("EUR");
    const [fees, setFees] = useState("0.00");
    const [sellamount, setSellAmount] = useState("0.00");
    const [buyamount, setBuyAmount] = useState("0.00");
    const [rate, setRate] = useState("0.00");
    const [bankrate, setBankrate] = useState("0.00");
    const [convertError, setConvertError] = useState(null);
    const [quoteProgress, setQuoteProgress] = useState(false)
    
    useEffect(() => {
        handleResponse(sellcode, buycode, price);
    }, [sellcode, buycode]);

    useEffect(() => {
        price && setFeeAndRates()
        convertError && handleResponse(sellcode, buycode, price)
    }, [price])

    const setFeeAndRates = () => {
        const amount = parseFloat(price.replace(/,/g, ""))
        const sellprice = amount - ((amount * 0.25) / 100);
        setBuyAmount(sellprice * rate);

        let pricefee;
        if (currency25percent.indexOf(sellcode) > -1) {
            pricefee = (amount * 0.25) / 100;
            setFees(pricefee);
            setSellAmount(amount - pricefee);
        } else {
            pricefee = (amount * 0.4) / 100;
            setFees(pricefee);
            setSellAmount(amount - pricefee);
        }

        const bankingFixedCharges = 3 / 100;
        const bankExchageAmount = (amount * bankingFixedCharges) - pricefee;
        setBankrate(bankExchageAmount);
    }

    useEffect(() => {
        axios.get(CURRENCY_URL).then((response) => {
            response.data.fromCurrency.map((currencyData) =>
                setFromCurrency((prevCurr) => [...prevCurr, currencyData.currency])
            );
            response.data.toCurrency.map((currencyData) =>
                setToCurrency((prevCurr) => [...prevCurr, currencyData.currency])
            );
        });

        handleResponse(sellcode, buycode, price);
    }, []);

    const fromCurrencyFlag =
        fromcurrency?.map((fromCurrency) =>
            CURRENCIES.find((obj) => obj.code === fromCurrency)
        ) || [];

    const toCurrencyFlag =
        tocurrency?.map((toCurrency) =>
            CURRENCIES.find((obj) => obj.code === toCurrency)
        ) || [];

    const getNumbericCurrency = (event) => {
        const inputValue = event.target.value;
        
        // Check if the input is a non-negative decimal number or an empty string
        if (/^\d*(,\d*)?(\.\d*)?$/.test(inputValue) || inputValue === '') {
            setPrice(inputValue);
        } else {
            // If the input is not a valid decimal or a delete action, set price to the previous value
            setPrice((prevPrice) => prevPrice);
        }
    };

    const formatNumberWithCommas = (number) => {
        const roundedNumber = parseFloat(number).toFixed(2);
        const [integerPart, decimalPart] = roundedNumber.split('.');
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
    };

    const currencyFormat = (num) => {
        return parseInt(num) !== 0
            ? parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            : num;
    };

    const decimalToPlace = (numData, decimalPlace = 2) => {
        const match = parseFloat(numData).toString().match(new RegExp(`^-?\\d+(?:\\.\\d{0,${decimalPlace}})?`));
        return match ? match[0] : "";
    };

    const handleClickSymbol = (type, data) => {
        if (type === "from") {
            setFromSymbol(data.symbol);
            setSellcode(data.code);
            setBuycode(buycode);
        } else if (type === "to") {
            setToSymbol(data.symbol);
            setBuycode(data.code);
            setSellcode(sellcode);
        }
    };

    const handleResponse = (sellCurrency, buyCurrency, amount) => {
        setFeeAndRates()
        setQuoteProgress(true)
        const finalAmount = parseFloat(amount.replace(/,/g, ""))

        let data = JSON.stringify({
            sellCurrency: sellCurrency,
            buyCurrency: buyCurrency,
            fixedSide: "sell",
            amount: finalAmount,
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: CURRENCY_CONVERT_URL,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                setConvertError(null)
                setRate(response.data?.rate);
                let sellprice = finalAmount - ((finalAmount * 0.25) / 100);
                setBuyAmount(sellprice * response.data?.rate);
                const bankExchageAmount = (finalAmount * bankingFixedCharges) - fees;
                setBankrate(bankExchageAmount);
                setQuoteProgress(false)
            })
            .catch((error) => {
                setQuoteProgress(true)
                switch (error.response.data.message) {
                    case "rate_non_existent":
                        setConvertError(`${sellcode} currency rates not available`)
                        break;
                    default:
                        setConvertError(error.response.data.message)
                        break;
                }
            });
    };

    return (
        <>
            <div
                className="wrapper mobile_responsive convert-money  color_change"
                data-color="black"
                style={{ transition: "background-color 1s ease" }}>
                <div className="background_shadow background_img ">
                    <HeaderMenu />
                    <section className="container subpage   section_spacing">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-7 ">
                                    <div className="subpage_banner_section">
                                        <p className="text-lightgeeen text-start banner_p l-space-2  mb-29">
                                            Convert Money
                                        </p>
                                        <h1 className="mx-auto text-start banner_h1 ">
                                            Convert money for
                                            <span className="text-green"> less.</span>
                                        </h1>
                                        <h1 className="mx-auto text-start banner_h1 ">
                                            Trade <span className="text-green">confidently.</span>
                                        </h1>
                                        <h3 className="text-start banner_h3 text-white mt-4">
                                            Meet all your FX needs under one roof. <br></br>Convert
                                            into major world currencies with ease at competitive
                                            exchange rates.
                                        </h3>
                                    </div>
                                </div>
                                <div className="col-xl-4 offset-xl-1  col-lg-5">
                                    <div
                                        className="convert-box ms-auto w-100"
                                        style={{ maxWidth: "468px" }}>
                                        <div className="currencies_box d-flex justify-content-between">
                                            <div className="currencies_text">
                                                <p className="text-green mb-0">Convert</p>
                                                <div className="mb-0 d-flex align-items-center convert-amt">
                                                    <div>
                                                        <p className="mb-0 mt-0">
                                                            <span>{fromsymbol}</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            placeholder="Convert amt"
                                                            className="input-convert"
                                                            name="convert"
                                                            id="convert"
                                                            value={price}
                                                            onChange={getNumbericCurrency}
                                                            onBlur={(event) => {
                                                                const inputValue = event.target.value;
                                                                const formattedValue = inputValue !== '' ? formatNumberWithCommas(inputValue) : '';
                                                                setPrice(formattedValue);
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                        {/* Do not delete this drop down as this will use in future */}
                                                        {/* <Select
                                                            //menuIsOpen={true}
                                                            options={numericecurrency}
                                                            value={numericecurrency.find(
                                                                (option) => option.price == price
                                                            )}
                                                            onChange={getNumbericCurrency}
                                                            formatOptionLabel={(numericecurrency) => (
                                                                <>
                                                                <p className="mb-0 mt-0">{`${currencyFormat(
                                                                    numericecurrency.price
                                                                )}`}</p>
                                                                </>
                                                            )}
                                                            /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Currencies_select">
                                                <span className="d-flex align-items-center">
                                                    <div class="m-2">
                                                        <CountrySelect
                                                            //menuIsOpen={true}
                                                            type="from"
                                                            defaultvalue="USD"
                                                            currencyConvert={fromCurrencyFlag}
                                                            handleClickSymbol={handleClickSymbol}
                                                        />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <ul className="calculat mb-0">
                                                <li className="fl-calculat">
                                                    <div className="d-flex justify-content-between">
                                                        <span className="text-white">
                                                            {currencyFormat(decimalToPlace(fees, 4))} {sellcode}
                                                        </span>
                                                        <span>PayStreet fee</span>
                                                    </div>
                                                    <hr className="text-white m-0 hr-light"></hr>
                                                </li>
                                                <li className="sl-calculat">
                                                    <div className="d-flex justify-content-between">
                                                        <span className="text-white">
                                                            {`${currencyFormat(decimalToPlace(sellamount))}`} {sellcode}
                                                        </span>
                                                        <span>Amount we will convert</span>
                                                    </div>
                                                </li>
                                                <li className="tl-calculat">
                                                    <div className="d-flex justify-content-between">
                                                        <span className="text-white">
                                                            { convertError ? <span className="error" style={{fontSize: "15px"}}><i class="fal fa-info-circle fa-fw"></i></span>
                                                            : quoteProgress ? <i class="fas fa-spinner fa-spin"></i>
                                                            : <>
                                                                <span style={{ color: "#61CE65" }}>
                                                                    {`${currencyFormat(decimalToPlace(rate, 4))} ${buycode} `}
                                                                </span>
                                                                = 1.00 {sellcode}
                                                            </>
                                                            }
                                                        </span>
                                                        <span>Conversion rate</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="currencies_box d-flex justify-content-between">
                                            <div className="currencies_text">
                                                <p className="text-green mb-0">To</p>
                                                <p className="mb-0">
                                                    {tosymbol} {`${currencyFormat(buyamount)}`}
                                                </p>
                                            </div>
                                            <div className="Currencies_select">
                                                <span className="d-flex align-items-center">
                                                    <div class="container">
                                                        <CountrySelect
                                                            //menuIsOpen={true}
                                                            type="to"
                                                            defaultvalue="EUR"
                                                            currencyConvert={toCurrencyFlag}
                                                            handleClickSymbol={handleClickSymbol}
                                                        />
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-center convert-box-footer mb-0">
                                                <span>
                                                    Save up to
                                                </span>
                                                {` ${sellcode} ${currencyFormat(decimalToPlace(bankrate))} `}
                                                <span>
                                                    vis-à-vis other banks
                                                </span>
                                            </p>
                                            {convertError && <p className="error"><i class="fal fa-info-circle fa-fw"></i> {convertError}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section className="mb-321 ECI-section conver-three">
                    <div className="row gx-0">
                        <div className="col-xl-4 col-lg-12">
                            <div className="cv-banner-info-convert  child-1 h-100 d-flex justify-content-end">
                                <div>
                                    <img src={convertC} alt=".." />
                                    <h3>Empower your business</h3>
                                    <p>
                                        Get rid of inflated exchange rate markups or hidden fees and
                                        convert near mid-market rates.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12">
                            <div className="cv-banner-info-convert child-2 h-100 d-flex justify-content-start">
                                <div>
                                    <img src={convertD} alt="..." />
                                    <h3>Customer first</h3>
                                    <p>
                                        The fee we earn is used to move money, develop better
                                        products and provide continuous support to you round the
                                        clock.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-12">
                            <div className="cv-banner-info-convert child-3 h-100 d-flex justify-content-start">
                                <div>
                                    <img src={convertC} alt="..." />
                                    <h3>Integrity</h3>
                                    <p>
                                        End-to-end encryption and multiple layers of security keep
                                        your data safe. We never share your information with anyone.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="IP-page">
                    <div className="container mb-3">
                        <h2 className="text-white text-center page_sub_title mb_35">
                            Enjoy <span className="text-green">hassle free</span> conversion
                        </h2>
                        <p style={{ maxWidth: "907px", width: "100%", margin: "auto" }}>
                            Exchange money at best possible rates with PayStreet's transparent
                            pricing. Hedge against currency fluctuation with our competitive
                            forex services.
                        </p>
                    </div>
                    <div className="d-flex flex-wrap justify-content-center mb_75">
                        <ul className="nav" id="myTab">
                            <li className="nav-item">
                                <div className="currencies-fees border-end border-2 border-secondary">
                                    <a href="#home" className="nav-link " data-bs-toggle="tab">
                                        <h3 className="text-white  text-center">
                                            <span style={{ color: "#176B41" }}>0.25%</span> fees
                                        </h3>
                                        <p>USD SGD GBP EUR </p>
                                    </a>
                                </div>
                            </li>
                            <li className="nav-item">
                                <div className="currencies-fees ">
                                    <a href="#profile" className="nav-link" data-bs-toggle="tab">
                                        <h3 className="text-white  text-center">
                                            <span style={{ color: "#176B41" }}>0.40% </span>fees
                                        </h3>
                                        <p>Other currencies</p>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className="pb-203 color-black">
                    <div className=" Currencies_section">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="home22">
                                <div className="Currencies">
                                    <div className="Currencies_group">
                                        <div className="Currencies-pill">USD</div>
                                        <div className="Currencies-pill">SGD</div>
                                        <div className="Currencies-pill">EUR</div>
                                        <div className="Currencies-pill">GBP</div>
                                        <div className="Currencies-pill">HKD</div>
                                        <div className="Currencies-pill">IDR</div>
                                        <div className="Currencies-pill">JPY</div>
                                        <div className="Currencies-pill">PHP</div>
                                        <div className="Currencies-pill">THB</div>
                                    </div>
                                    <div aria-hidden="true" className="Currencies_group">
                                        <div className="Currencies-pill">AED</div>
                                        <div className="Currencies-pill">AUD</div>
                                        <div className="Currencies-pill">BDT</div>
                                        <div className="Currencies-pill">BND</div>
                                        <div className="Currencies-pill">CAD</div>
                                        <div className="Currencies-pill">CHF</div>
                                        <div className="Currencies-pill">CNH</div>
                                        <div className="Currencies-pill">CZK</div>
                                        <div className="Currencies-pill">DKK</div>
                                    </div>
                                </div>
                                <div className="Currencies">
                                    <div className="Currencies_group1">
                                        <div className="Currencies-pill">HRK</div>
                                        <div className="Currencies-pill">ILS</div>
                                        <div className="Currencies-pill">INR</div>
                                        <div className="Currencies-pill">KES</div>
                                        <div className="Currencies-pill">KRW</div>
                                        <div className="Currencies-pill">KWD</div>
                                        <div className="Currencies-pill">LKR</div>
                                        <div className="Currencies-pill">MXN</div>
                                        <div className="Currencies-pill">MYR</div>
                                    </div>
                                    <div aria-hidden="true" className="Currencies_group1">
                                        <div className="Currencies-pill">NOK</div>
                                        <div className="Currencies-pill">NPR</div>
                                        <div className="Currencies-pill">NZD</div>
                                        <div className="Currencies-pill">OMR</div>
                                        <div className="Currencies-pill">PKR</div>
                                        <div className="Currencies-pill">PLN</div>
                                        <div className="Currencies-pill">SAR</div>
                                        <div className="Currencies-pill">SEK</div>
                                        <div className="Currencies-pill">TRY</div>
                                    </div>
                                </div>
                                <div className="Currencies">
                                    <div className="Currencies_group2">
                                        <div className="Currencies-pill">qar</div>
                                        <div className="Currencies-pill">ils</div>
                                        <div className="Currencies-pill">kes</div>
                                        <div className="Currencies-pill">kwd</div>
                                        <div className="Currencies-pill">mxn</div>
                                        <div className="Currencies-pill">nzd</div>
                                        <div className="Currencies-pill">nik</div>
                                        <div className="Currencies-pill">omr</div>
                                        <div className="Currencies-pill">pln</div>
                                    </div>
                                    <div aria-hidden="true" className="Currencies_group2">
                                        <div className="Currencies-pill">qar</div>
                                        <div className="Currencies-pill">ils</div>
                                        <div className="Currencies-pill">kes</div>
                                        <div className="Currencies-pill">kwd</div>
                                        <div className="Currencies-pill">mxn</div>
                                        <div className="Currencies-pill">nzd</div>
                                        <div className="Currencies-pill">nik</div>
                                        <div className="Currencies-pill">omr</div>
                                        <div className="Currencies-pill">pln</div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="profile22">
                                <div className="Currencies">
                                    <div className="Currencies_group1">
                                        <div className="Currencies-pill">ils</div>
                                        <div className="Currencies-pill">kes</div>
                                        <div className="Currencies-pill">kwd</div>
                                        <div className="Currencies-pill">mxn</div>
                                        <div className="Currencies-pill">nzd</div>
                                        <div className="Currencies-pill">nik</div>
                                        <div className="Currencies-pill">omr</div>
                                        <div className="Currencies-pill">pln</div>
                                        <div className="Currencies-pill">qar</div>
                                    </div>
                                    <div aria-hidden="true" className="Currencies_group1">
                                        <div className="Currencies-pill">ils</div>
                                        <div className="Currencies-pill">kes</div>
                                        <div className="Currencies-pill">kwd</div>
                                        <div className="Currencies-pill">mxn</div>
                                        <div className="Currencies-pill">nzd</div>
                                        <div className="Currencies-pill">nik</div>
                                        <div className="Currencies-pill">omr</div>
                                        <div className="Currencies-pill">pln</div>
                                        <div className="Currencies-pill">qar</div>
                                    </div>
                                </div>
                                <div className="Currencies">
                                    <div className="Currencies_group2">
                                        <div className="Currencies-pill">qar</div>
                                        <div className="Currencies-pill">ils</div>
                                        <div className="Currencies-pill">kes</div>
                                        <div className="Currencies-pill">kwd</div>
                                        <div className="Currencies-pill">mxn</div>
                                        <div className="Currencies-pill">nzd</div>
                                        <div className="Currencies-pill">nik</div>
                                        <div className="Currencies-pill">omr</div>
                                        <div className="Currencies-pill">pln</div>
                                    </div>
                                    <div aria-hidden="true" className="Currencies_group2">
                                        <div className="Currencies-pill">qar</div>
                                        <div className="Currencies-pill">ils</div>
                                        <div className="Currencies-pill">kes</div>
                                        <div className="Currencies-pill">kwd</div>
                                        <div className="Currencies-pill">mxn</div>
                                        <div className="Currencies-pill">nzd</div>
                                        <div className="Currencies-pill">nik</div>
                                        <div className="Currencies-pill">omr</div>
                                        <div className="Currencies-pill">pln</div>
                                    </div>
                                </div>
                                <div className="Currencies">
                                    <div className="Currencies_group">
                                        <div className="Currencies-pill">aud</div>
                                        <div className="Currencies-pill">bhd</div>
                                        <div className="Currencies-pill">bgn</div>
                                        <div className="Currencies-pill">cad</div>
                                        <div className="Currencies-pill">huf</div>
                                        <div className="Currencies-pill">czk</div>
                                        <div className="Currencies-pill">dkk</div>
                                        <div className="Currencies-pill">eur</div>
                                        <div className="Currencies-pill">huf</div>
                                    </div>
                                    <div aria-hidden="true" className="Currencies_group">
                                        <div className="Currencies-pill">aud</div>
                                        <div className="Currencies-pill">bhd</div>
                                        <div className="Currencies-pill">bgn</div>
                                        <div className="Currencies-pill">cad</div>
                                        <div className="Currencies-pill">huf</div>
                                        <div className="Currencies-pill">czk</div>
                                        <div className="Currencies-pill">dkk</div>
                                        <div className="Currencies-pill">eur</div>
                                        <div className="Currencies-pill">huf</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-242 currency_exchange">
                    <div className="container position-relative" style={{ zIndex: "1" }}>
                        <h2
                            className="w-100 text-white lh-normal text-start fw-700 page_sub_title mb-54"
                            style={{ maxWidth: "949px" }}>
                            Your gateway to the global currency exchange with simple and
                            competitive FX solutions.
                        </h2>
                        <a href={REGISTERURL} style={{ textDecoration: "none" }}>
                            <button className="btn convert-start-btn">Get started</button>
                        </a>
                    </div>
                </section>
                <section
                    className="smart_toolsets mobile_responsive color_change convert-page-white"
                    id="smart-toolsets"
                    data-color="indigo"
                    style={{ transition: "background-color 1s ease" }}>
                    <div className="py-245">
                        <div className="container-fluid">
                            <div className="container smart_toolsets_content solution_page">
                                <p className="green_small_text">FX MADE EASY</p>
                                <h2 className="smart_toolsets_content_title">
                                    Beat your bank and convert at 9x less the cost. Make every
                                    dollar count.
                                </h2>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text_box">
                                            <h2>
                                                <span>Easy</span> and <span>instantaneous</span> FX
                                                settlement
                                            </h2>
                                            <p>
                                                Manage and convert among multiple currencies in seconds
                                                with the <span>click of a button</span>. Get currency in
                                                the wallet instantly.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img_box ps-2 justify-content-end d-flex">
                                            <img src={convertE} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                                        <div className="img_box pe-2">
                                            <img src={convertF} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                                        <div className="text_box">
                                            <h2>
                                                The <span>more</span> you trade, the <span>less </span>
                                                you pay.
                                            </h2>
                                            <p>
                                                Get qualified for higher discount on existing fees with
                                                larger conversion volume. Let us know your needs and get
                                                <span>market beating offers</span> for your business.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text_box">
                                            <h2>
                                                <span>Hedge risk</span> with our multi-currency
                                                management.
                                            </h2>
                                            <p>
                                                <span>Minimise your exposure</span> against
                                                unpredictable FX market risk. Retain funds in currency
                                                wallets and convert only when you come across your
                                                desired FX rate.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img_box pe-2 justify-content-end d-flex">
                                            <img src={convertG} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div
                className="footer-bg mobile_responsive color_change"
                data-color="black">
                <section className="">
                    <div className="container scale_business_section">
                        <div className="container-fluid">
                            <div className="row">
                                <h2>
                                    <span>Pay less, trade more.</span>
                                    <br></br> We aspire to provide you with the best FX solutions
                                    near mid-market rates.
                                </h2>
                                <p className="text-white mtb">
                                    Join today. Open an account within minutes.
                                </p>
                                <div className="tab-center mob-center">
                                    <a href={CONTACT}>
                                        <button type="button" className="btn button_white me-4">
                                            Talk to us
                                            <i className="fal fa-long-arrow-right"></i>
                                        </button>
                                    </a>
                                    <a href={REGISTERURL}>
                                        <button
                                            type="button"
                                            className="btn button_white get_started">
                                            Get started
                                            <i className="fal fa-long-arrow-right"></i>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
}

export default Convert;
