import React from 'react'

const PageLoading = () => {

  const spinnerStyle = {
    width: '1.5rem',
    height: '1.5rem',
  };

  return (

    <div className="d-flex justify-content-center position-relative top-50" >
      <span className="spinner-border" role="status" aria-hidden="true" style={spinnerStyle} ></span>
    </div>
  )
}

export default PageLoading
