import React, { useEffect, useState } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import tabletBottom from '../../../assets/images/tablets/tab4.png'
import tabletLeft from '../../../assets/images/tablets/tab3.png'
import tabletRight from '../../../assets/images/tablets/tab2.png'
import tabletFront from '../../../assets/images/tablets/tab1.png'

export const Tablet1 = () => {
    const [parallaxAttr1, setParallaxAttr1] = useState({});
    const [parallaxAttr2, setParallaxAttr2] = useState({});
    const [parallaxAttr3, setParallaxAttr3] = useState({});
    const [parallaxAttr4, setParallaxAttr4] = useState({});
    const [parallaxAttr5, setParallaxAttr5] = useState({});
    const [parallaxAttr6, setParallaxAttr6] = useState({});
    const [parallaxAttr7, setParallaxAttr7] = useState({});
    const [width, setWidth] = useState();

    useEffect(() => {
        setWidth(window.innerWidth);

        if(window.innerWidth >= 1920) {
            setParallaxAttr1({x: [0, -25], y: [150, 230], scale: [0.5, 0.8], startScroll: 1500, endScroll: 2000});
            setParallaxAttr2({x: [0, 25], y: [50, 130], scale: [0.5, 0.8], startScroll: 1500, endScroll: 2000});
            setParallaxAttr3({x: [0, 0], y: [-100, 80], scale: [0.5, 0.8], startScroll: 500, endScroll: 2000});
            setParallaxAttr4({x: [0, 0], y: [-245, 100], scale: [1, 0.9, 'easeOutBack'], startScroll: 500, endScroll: 2000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -600], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-600, -400], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else if(window.innerWidth >= 1600) {
            setParallaxAttr1({x: [0, -25], y: [175, 245], scale: [0.5, 0.9], startScroll: 1500, endScroll: 2000});
            setParallaxAttr2({x: [0, 25], y: [75, 145], scale: [0.5, 0.9], startScroll: 1500, endScroll: 2000});
            setParallaxAttr3({x: [0, 0], y: [-100, 100], scale: [0.5, 0.8], startScroll: 500, endScroll: 2000});
            setParallaxAttr4({x: [0, 0], y: [-215, 100], scale: [1, 0.9, 'easeOutBack'], startScroll: 500, endScroll: 2000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -600], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-500, -400], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else if(window.innerWidth >= 1440) {
            setParallaxAttr1({x: [0, -25], y: [225, 285], scale: [0.5, 0.9], startScroll: 1500, endScroll: 2000});
            setParallaxAttr2({x: [0, 25], y: [125, 185], scale: [0.5, 0.9], startScroll: 1500, endScroll: 2000});
            setParallaxAttr3({x: [0, 0], y: [-115, 150], scale: [0.5, 0.8], startScroll: 500, endScroll: 2000});
            setParallaxAttr4({x: [0, 0], y: [-185, 100], scale: [1, 0.9, 'easeOutBack'], startScroll: 500, endScroll: 2000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -600], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-500, -400], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else if(window.innerWidth >= 1366) {
            setParallaxAttr1({x: [0, -25], y: [210, 260], scale: [0.5, 0.9], startScroll: 1000, endScroll: 2000});
            setParallaxAttr2({x: [0, 25], y: [110, 160], scale: [0.5, 0.9], startScroll: 1000, endScroll: 2000});
            setParallaxAttr3({x: [0, 0], y: [-115, 150], scale: [0.5, 0.8], startScroll: 500, endScroll: 2000});
            setParallaxAttr4({x: [0, 0], y: [-185, 100], scale: [0.8, 0.7, 'easeOutBack'], startScroll: 500, endScroll: 2000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -600], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-500, -400], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else if(window.innerWidth >= 1280) {
            setParallaxAttr1({x: [0, -25], y: [190, 260], scale: [0.5, 0.9], startScroll: 600, endScroll: 1800});
            setParallaxAttr2({x: [0, 25], y: [90, 160], scale: [0.5, 0.9], startScroll: 600, endScroll: 1800});
            setParallaxAttr3({x: [0, 0], y: [-70, 140], scale: [0.5, 0.8], startScroll: 600, endScroll: 1800});
            setParallaxAttr4({x: [0, 0], y: [-185, 100], scale: [0.8, 0.7, 'easeOutBack'], startScroll: 500, endScroll: 2000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -600], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-500, -400], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else if(window.innerWidth >= 1200) {
            setParallaxAttr1({x: [0, -25], y: [190, 260], scale: [0.5, 0.9], startScroll: 600, endScroll: 1800});
            setParallaxAttr2({x: [0, 25], y: [90, 160], scale: [0.5, 0.9], startScroll: 600, endScroll: 1800});
            setParallaxAttr3({x: [0, 0], y: [-70, 140], scale: [0.5, 0.8], startScroll: 600, endScroll: 1800});
            setParallaxAttr4({x: [0, 0], y: [-185, 100], scale: [0.8, 0.7, 'easeOutBack'], startScroll: 500, endScroll: 2000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -600], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-500, -400], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else if(window.innerWidth >= 992) {
            setParallaxAttr1({x: [0, -25], y: [250, 250], scale: [0.5, 0.9], startScroll: 500, endScroll: 1600});
            setParallaxAttr2({x: [0, 25], y: [150, 150], scale: [0.5, 0.9], startScroll: 500, endScroll: 1600});
            setParallaxAttr3({x: [0, 0], y: [-50, 150], scale: [0.5, 0.8], startScroll: 500, endScroll: 1600});
            setParallaxAttr4({x: [0, 0], y: [-180, 100], scale: [0.8, 0.7, 'easeOutBack'], startScroll: 500, endScroll: 2000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -600], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-600, -400], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        }  else if(window.innerWidth >= 768) {
            setParallaxAttr1({x: [0, -25], y: [200, 370], scale: [0.5, 0.9], startScroll: 500, endScroll: 1600});
            setParallaxAttr2({x: [0, 25], y: [100, 270], scale: [0.5, 0.9], startScroll: 500, endScroll: 1600});
            setParallaxAttr3({x: [0, 0], y: [-40, 240], scale: [0.5, 0.8], startScroll: 500, endScroll: 1600});
            setParallaxAttr4({x: [0, 0], y: [-90, 50], scale: [0.8, 0.7, 'easeOutBack'], startScroll: 500, endScroll: 1000});
            setParallaxAttr5({x: [0, 0], y: [-1200, -1100], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-800, -800], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else if(window.innerWidth >= 560) {
            setParallaxAttr1({x: [0, -25], y: [200, 370], scale: [0.5, 0.9], startScroll: 500, endScroll: 1600});
            setParallaxAttr2({x: [0, 25], y: [100, 270], scale: [0.5, 0.9], startScroll: 500, endScroll: 1600});
            setParallaxAttr3({x: [0, 0], y: [-40, 240], scale: [0.5, 0.8], startScroll: 500, endScroll: 1600});
            setParallaxAttr4({x: [0, 0], y: [-90, 50], scale: [0.8, 0.7, 'easeOutBack'], startScroll: 500, endScroll: 1000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -700], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-550, -550], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else if(window.innerWidth >= 360) {
            setParallaxAttr1({x: [0, -25], y: [150, 350], scale: [0.5, 0.9], startScroll: 200, endScroll: 1000});
            setParallaxAttr2({x: [0, 25], y: [50, 250], scale: [0.5, 0.9], startScroll: 200, endScroll: 1000});
            setParallaxAttr3({x: [0, 0], y: [0, 220], scale: [0.5, 0.8], startScroll: 500, endScroll: 1000});
            setParallaxAttr4({x: [0, 0], y: [-90, 30], scale: [0.7, 0.75, 'easeOutBack'], startScroll: 500, endScroll: 1000});
            setParallaxAttr5({x: [0, 0], y: [-1000, -1000], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-800, -800], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-600, -600], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } else {
            setParallaxAttr1({x: [0, -25], y: [140, 280], scale: [0.5, 0.9], startScroll: 200, endScroll: 1000});
            setParallaxAttr2({x: [0, 25], y: [40, 180], scale: [0.5, 0.9], startScroll: 200, endScroll: 1000});
            setParallaxAttr3({x: [0, 0], y: [0, 160], scale: [0.5, 0.8], startScroll: 200, endScroll: 1000});
            setParallaxAttr4({x: [0, 0], y: [-90, 30], scale: [0.7, 0.75, 'easeOutBack'], startScroll: 500, endScroll: 1000});
            setParallaxAttr5({x: [0, 0], y: [-1200, -1100], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr6({x: [0, 0], y: [-1000, -900], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
            setParallaxAttr7({x: [0, 0], y: [-800, -800], scale: [0.8, 0.9, 'easeOutBack'], startScroll: 0, endScroll: 2000, speed: 100});
        } 

        window.addEventListener("resize", () => setWidth(window.innerWidth));
      }, []);
    return (
        <div className="overlay-animation-container">
            <ParallaxProvider>
                <Parallax 
                    translateX={parallaxAttr1.x} 
                    translateY={parallaxAttr1.y}
                    scale={parallaxAttr1.scale}
                    startScroll={parallaxAttr1.startScroll}
                    endScroll={parallaxAttr1.endScroll}
                >
                    <img src={tabletRight} className="img-fluid w-50" alt="..."/>
                </Parallax>
                <Parallax 
                    translateX={parallaxAttr2.x} 
                    translateY={parallaxAttr2.y}
                    scale={parallaxAttr2.scale}
                    startScroll={parallaxAttr2.startScroll}
                    endScroll={parallaxAttr2.endScroll}
                >
                    <img src={tabletLeft} className="img-fluid w-50" alt="..."/>
                </Parallax>
                <Parallax 
                    translateX={parallaxAttr3.x} 
                    translateY={parallaxAttr3.y} 
                    scale={parallaxAttr3.scale}
                    startScroll={parallaxAttr3.startScroll}
                    endScroll={parallaxAttr3.endScroll}
                >
                    <img src={tabletBottom} className="img-fluid w-50" alt="..."/>
                </Parallax>
                <Parallax 
                    translateX={parallaxAttr4.x}
                    translateY={parallaxAttr4.y}
                    scale={parallaxAttr4.scale}
                    // startScroll={parallaxAttr4.startScroll}
                    // endScroll={parallaxAttr4.endScroll}
                >
                    <img src={tabletFront} className="img-fluid w-40" alt="..."/>
                </Parallax>
                <Parallax className="animate-text"
                        translateX={parallaxAttr5.x}
                        translateY={parallaxAttr5.y}
                        scale={parallaxAttr5.scale}
                        speed={parallaxAttr5.speed}
                    >
                        <div className='overlay-animate'>
                        No hidden fee
                        </div>
                    </Parallax>
                    <Parallax  className="animate-text"
                        translateX={parallaxAttr6.x}
                        translateY={parallaxAttr6.y}
                        scale={parallaxAttr6.scale}
                        speed={parallaxAttr6.speed}
                    >
                        <div className='overlay-animate'>
                        Easy to use platform
                        </div>
                        
                    </Parallax>
                    <Parallax  className="animate-text"
                        translateX={parallaxAttr7.x}
                        translateY={parallaxAttr7.y}
                        scale={parallaxAttr7.scale}
                        speed={parallaxAttr7.speed}
                    >                        
                        <div className='overlay-animate'>
                            100 % secure
                        </div>
                    </Parallax>
            </ParallaxProvider>
        </div>
  )
}
