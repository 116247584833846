import React, { useState } from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import { CONTACT, REGISTERURL } from "../../constant";
import { Laptop } from "../Parallax/B2b/Laptop";
import zeroGif from "../../assets/images/solution-image.gif";
import zeroGifMob from "../../assets/images/solution-mob.gif";
import { InView } from 'react-intersection-observer';

import b2bFlag from "../../assets/images/b2b/b-to-b-flag.png";
import b2bCart1 from "../../assets/images/b2b/solution-image-1.svg";
import b2bCart2 from "../../assets/images/b2b/solution-image-2.svg";
import b2bCart3 from "../../assets/images/b2b/solution-image-3.svg";
import b2bCart4 from "../../assets/images/b2b/solution-image-4.svg";

function B2BCrossBorder() {
	const [isGifLoad, setIsGifLoad] = useState(false);
	const handleIntersection = (inView, entry) => {
		if (inView && !isGifLoad) {
			/* Section is now visible, fadeIn content */
			setIsGifLoad(true)
		}
	};

	return (
		<>
			<div className="wrapper mobile_responsive b2b-page color_change" data-color="black" style={{ 'transition': 'background-color 1s ease' }}>
				<div className="background_shadow background_image ">
					<HeaderMenu />
					<section className="section_spacing">
						<div className="subpage_banner_section banner_section ">
							<p className="mb-4 text-lightgeeen banner_p l-space-2">CROSS-BORDER PAYMENTS AND FX PLATFORM</p>
							<h1 className="m-auto Banner_title banner_h1">Powering cross-border payments </h1> <h1 className="m-auto Banner_title shift-text-left-b2b">securely for<span>&nbsp;</span>
								<span className="text-green typewriter"></span>
								{/* corporation. */}
							</h1>
						</div>
					</section>
				</div>
				<section className="text-center animated-shadow mt-5" style={{ overflow: "hidden" }}>
					<Laptop />
				</section>
				<section className="IP-page image">
					{/* <div style={{ marginTop: "-120px" }}>
						<img src="/images/solution-image-5.png" alt="..." className="w-100" />
					</div> */}
					<div className="container-fluid  pt-200px">
						<div className="row">
							<div className="col-lg-10 offset-lg-1">
								<h2 className="text-white text-center px-4 page_sub_title">A single <span className="text-green">integrated</span> account for <br className="d-none d-xl-block" /> multiple activities that helps you scale your business globally.</h2>
							</div>
						</div>
					</div>
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<InView as="div" onChange={handleIntersection}>
									<div className="w-100 balances-declaration b2b-page-scoller-animate d-none d-lg-block">
										{isGifLoad && <img src={zeroGif} className="rotate-img w-100" alt="..." />}
									</div>
									<div className="w-100 balances-declaration b2b-page-scoller-animate d-block d-lg-none">
										{isGifLoad && <img src={zeroGifMob} className="rotate-img w-100" alt="..." />}
									</div>
								</InView>
							</div>
						</div>
					</div>
					<div className="container-fluid mb_50">
						<div className="row">
							<div className="col-lg-8 offset-lg-2">
								<h2 className="text-white text-center page_sub_title mb_35 "><span className="text-green">Send money</span> or <span className="text-green">convert</span> into more than 35 currencies with ease.</h2>
								<p style={{ maxWidth: '907px', width: '100%', margin: 'auto' }}>Make transactions effortlessly, eliminating the complexities and delays associated with traditional methods. Enjoy competitive FX rates and low fees.</p>
							</div>
						</div>

					</div>
					<div className="container-fluid mt-80">
						<div className="row">
							<div className="col-lg-6 offset-lg-3">
								<div className="img_box text-center">
									<video
										class="force-autoplay" width="100%" playsinline="" 
										muted="" autoplay=""  loop disablepictureinpicture=""
									>
										<source src="../images/b2b.mov" type="video/mp4; codecs=&quot;hvc1&quot;"></source>
											<source src="../images/b2b.webm" type="video/webm" />
											Sorry, your browser doesn't support embedded videos.
									</video>

								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="mobile_responsive color_change" id="smart-toolsets" data-color="indigo" style={{ 'transition': 'background-color 1s ease' }}>
					<div className="py-245">
						<div className="container">
							<div className="smart_toolsets_content">
								<p className="green_small_text">A SINGLE INTEGRATED ACCOUNT</p>
								<h2 className="smart_toolsets_content_title">Experience a complete suite of banking tools at your fingertips.</h2>
								<div className="row mb_150 align-items-center">
									<div className="col-lg-6  ">
										<div className="text_box">
											<h2><span>Local</span> and <span>global</span> settlement accounts</h2>
											<p>Collect local or international payments in <span>IBANs under your company name.</span> Share your business account details and start accepting funds in multiple currencies just like a bank.</p>
										</div>
									</div>
									<div className="col-lg-6 order-md-2 order-2 order-lg-1">
										<div className="img_box ps-2 justify-content-end d-flex">
											<img src={b2bCart1} className="img-fluid" alt="..." />
										</div>
									</div>
								</div>
								<div className="row mb_150 align-items-center">
									<div className="col-lg-6  order-md-2 order-2 order-lg-1">
										<div className="img_box pe-2">
											<img src={b2bCart2} className="img-fluid" alt="..." />
										</div>
									</div>
									<div className="col-lg-6 order-md-1 order-1 order-lg-2">
										<div className="text_box">
											<h2><span>Competitive</span> pricing for all your business needs</h2>
											<p><span>Low and transparent fees</span> made available even to small enterprises. Extremely <span>low conversion markups</span> that allow inexpensive access to exchange rates at the interbank level.</p>
										</div>
									</div>
								</div>
								<div className="row mb_150 align-items-center">
									<div className="col-lg-6">
										<div className="text_box">
											<h2>Available <span>24/7</span> from anywhere in the world </h2>
											<p>Experience the all-in-one account at your <span>fingertips</span> so you never lose control of your business activities wherever you go. Now, one tap away with iOS and Android <span>mobile app.</span></p>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="img_box pe-2 justify-content-end d-flex">
											<img alt="..." src={b2bCart3} className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="row align-items-center">
									<div className="col-lg-6 order-md-2 order-2 order-lg-1">
										<div className="img_box d-flex pe-2">
											<img src={b2bCart4} className="img-fluid" alt="..." />
										</div>
									</div>
									<div className="col-lg-6 order-md-1 order-1 order-lg-2">
										<div className="text_box">
											<h2>Highly <span>robust</span> platform</h2>
											<p>We never compromise with the safety of your information. Our <span>advanced security parameters</span> meet the highest standards set for financial platforms.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div className="footer-bg mobile_responsive color_change" data-color="black">
				<section className="container ">
					<div className="scale_business_section">
						<div className="row">
							<h2><span>Built to last.</span>
							</h2>
							<h2>We've created the product with care keeping convenience and user experience in mind.</h2>
							<p className="text-white text-start mtb">Open an account within minutes.</p>
							<div className="tab-center mob-center">
								<a href={CONTACT}><button type="button" className="btn button_white me-4">Talk to us
									<i className="fal fa-long-arrow-right"></i>
								</button></a>
								<a href={REGISTERURL}><button type="button" className="btn button_white get_started">Get started
									<i className="fal fa-long-arrow-right"></i>
								</button> </a>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		</>
	);
}

export default B2BCrossBorder;