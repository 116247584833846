import React from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import { Tablet } from "../Parallax/Send/Tablet";
import { REGISTERURL, CONTACT } from "../../constant";
import WorldMoney from "../../assets/images/solution/sendmoney/worldmoney.gif";
import oneMobile from "../../assets/images/solution/sendmoney/onemobile.svg";
import network from "../../assets/images/solution/sendmoney/network2.svg";
import network2 from "../../assets/images/solution/sendmoney/network.svg";
import twoMobile from "../../assets/images/solution/sendmoney/twomobile.svg";

function SendMoney() {
    return (
        <>
            <div className="wrapper mobile_responsive send-money color_change color-black" data-color="black">
                <div className="background_shadow   background_img ">

                    <HeaderMenu />

                    <section className="section_spacing-p1  send_money_page">
                        <div className="banner_section subpage_banner_section banner_section ">
                            <p className="send_money_title l-space-2" style={{ 'text-transform': 'uppercase' }}>Send money</p>
                            <h2 className="m-auto Banner_title banner_h1 mb-4">A smarter way to pay  <span className="send_money_globally">globally.</span></h2>
                            <p className="text-center text-white banner_h3 mb-100">Send money worldwide in more than 40 currencies either through local channels or SWIFT network.</p>
                            {/* <div className="text-center"><img src={WorldMoney} className="img-fluid w_90" alt="..." /></div> */}
                            <div className="container">
                            <div className="row">
                            <div className="col-md-10 offset-md-1">
                            <video class="force-autoplay" id="connect-tools-video" width="100%" playsinline=""
                                        muted="" autoplay=""  loop disablepictureinpicture="">
                                        <source src="../images/send/worldmoney.mov" type="video/mp4; codecs=&quot;hvc1&quot;"></source>
                                <source src="../images/send/worldmoney.webm" type="video/webm" ></source>
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            </div>
                           </div>
                            </div>
                        </div>
                    </section>
                    
                </div>
                {/* <section className="text-center animated-shadow mt-5" style={{ overflow: "hidden" }}>
                    <Tablet />
                </section> */}
                <section
                    className="section_spacing-110 pb-0 d-flex align-items-center justify-content-center pattern flex-column">
                   
                    <div className="container-fluid send-money-back-session">
                        <section className="container py-120">
                            <div className="row">
                                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                                    <div className="cv-banner-info-send-money h-100 d-flex justify-content-end">
                                        <h3><span>Payments</span> that put you in control</h3>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-md-12  offset-lg-1">
                                    <div className="cv-banner-info-send-money h-100 d-flex justify-content-end">
                                        <p className="mt-3">Pay your suppliers, foreign employees, vendors, freelancers and individuals faster and almost ten times cheaper than banks.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-12 ">
                                    <div className="cv-banner-info-send-money h-100 d-flex justify-content-end">
                                        <div style={{ maxWidth: '490px' }}>
                                            <hr></hr>
                                            <p className="send_money_heading"><span className="send_money_circle"></span> FASTER PAYMENTS, SMARTER BUSINESS</p>
                                            <h4>Worldwide reach</h4>
                                            <p className="send_money_text">Send money all over the world. Expand your business freely. Reduce your dependence on untrustworthy payment rails.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="cv-banner-info-send-money h-100 d-flex justify-content-start">
                                        <div style={{ maxWidth: '490px' }}>
                                            <hr></hr>
                                            <p className="send_money_heading"> ACCESS ANYTIME, ANYWHERE</p>
                                            <h4>24/7 availability</h4>
                                            <p className="send_money_text">Send money from anywhere anytime and relax. We will handle it for you. Payment solutions that are simple and intuitive.</p></div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="cv-banner-info-send-money h-100 d-flex justify-content-start">
                                        <div style={{ maxWidth: '490px' }}>
                                            <hr></hr>
                                            <p className="send_money_heading"> RELIABLE AND SECURE</p>
                                            <h4>Top-notch security</h4>
                                            <p className="send_money_text">Never worry about your money's security. Strong encryption and secure payment rails protect your data and transactions effectively.</p></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>
                <section className="smart_toolsets_money mobile_responsive color_change smart_send_money" id="smart-toolsets" data-color="indigo">
                    <div className="py-245">
                        <div className="container-fluid">
                            <div className="container smart_toolsets_content">
                                <p className="green_small_text">Move Money, For Less</p>
                                <h2 className="smart_toolsets_content_title">At PayStreet, we care for your payments so you can take care of your business.</h2>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text_box">
                                            <h2><span>One-stop shop</span> For all your banking needs</h2>
                                            <p>
                                            Avoid complex and expensive FX conversion fees while you send payments across the world. With PayStreet's <span> multi-currency IBAN Wallets, </span> avoid the need to keep a local bank account for sending money.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img_box d-flex justify-content-end ps-2">
                                            <img src={oneMobile} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                                        <div className="img_box pe-2">
                                            <img src={twoMobile} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                                        <div className="text_box">
                                            <h2>Distance is<span> no longer</span> a constraint for your business.</h2>
                                            <p>Expand business overseas and <span>get rid of regulatory hassles. </span>
                                                Reduce your dependence on untrustworthy payment rails. Avoid long delays and reduce expenses on sending money through PayStreet's intelligent payment routing mechanism.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text_box">
                                            <h2>Your business, our passion.<span>No red tape.</span></h2>
                                            <p><span>Cut through the delays</span> and  expenses associated with managing multiple banking relationships. Your dedicated account manager would ensure all your needs are catered to and every query is addressed to a tee.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img_box d-flex justify-content-end ps-2">
                                            <img src={network2} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row  align-items-center">
                                    <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                                        <div className="img_box pe-2">
                                            <img src={network} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                                        <div className="text_box">
                                            <h2><span>Get more </span>Out of your money</h2>
                                            <p>Payments are even <span>faster to people already on PayStreet! </span>Send them money instantly and for an even lower fee than usual.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="footer-bg mobile_responsive color_change" data-color="black"  >
                <section className="container  send-money-footer">
                    <div className="scale_business_section">
                        <div className="container-fluid">
                            <div className="row">
                                <h2>
                                    <span>Send money the easy way.</span>
                                    <br></br>
                                    Make cross border payments worldwide much cheaper and more conveniently than your bank.
                                </h2>
                                <p className="text-white text-start mtb">Join today. Open an account within minutes.</p>
                                <div className="tab-center mob-center mt-3">
                                    <a href={CONTACT}><button type="button" className="btn button_white me-4">Talk to us
                                    <i className="fal fa-long-arrow-right"></i>
                                    </button></a>
                                    <a href={REGISTERURL}><button type="button" className="btn button_white get_started">Get started
                                    <i className="fal fa-long-arrow-right"></i>
                                    </button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />

            </div>
        </>
    );
}

export default SendMoney;