import React from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import { CONTACT, REGISTERURL } from "../../constant";

import currency from "../../assets/images/solution/store/currency.png";
import iconA from "../../assets/images/solution/store/icon-1.svg";
import iconB from "../../assets/images/solution/store/icon-2.svg";
import iconC from "../../assets/images/solution/store/icon-3.svg";
import iconD from "../../assets/images/solution/store/icon-4.svg";
import bankA from "../../assets/images/solution/store/bank-1.svg";
import bankB from "../../assets/images/solution/store/bank-2.svg";
import bankC from "../../assets/images/solution/store/bank-3.svg";
import bankD from "../../assets/images/solution/store/bank-4.svg";
import 'animate.css';

function StoreMoney() {
    return (
        <>
            <div className="wrapper mobile_responsive color_change store" data-color="black" style={{ 'transition': 'background-color 1s ease' }}>
                <div className="background_shadow background_img">
                    <HeaderMenu />
                    <section className="section_store_spacing-p1  send_money_page">
                        <div className="container">
                            <div className="subpage_banner_section banner_section">
                                <p className="send_money_title l-space-2" style={{ 'text-transform': 'uppercase' }}>Store money</p>
                                <div className="text-center local-global-svg">
                                    <object type="image/svg+xml" data="images/store.svg"></object>
                                </div>

                                <p className="text-center text-white banner_h3 mb-5 animate__animated animate__fadeInUp animate__delay-3s ">A smart way to collect and store business funds with convenience <br className="d-none d-md-block" /> from anywhere in the world.</p>
                                <div className="text-center">
                                    {/* <img src={currency} className="img-fluid w_90" alt="..." /> */}
                                    <video
										class="force-autoplay" width="90%" playsinline="" autoplay=""  loop
										muted=""  disablepictureinpicture=""
									>
										<source src="../images/store/store.mov" type="video/mp4; codecs=&quot;hvc1&quot;"></source>
                                        <source src="../images/store/store.webm" type="video/webm" ></source>
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                    {/* <video class="force-autoplay" id="zero-fees-video" playsinline="" muted="" autoplay  loop  disablepictureinpicture="" preload="metadata" width="100%">
                                        <source src="../images/store/store.mov" type="video/mp4; codecs=&quot;hvc1&quot;"/>
                                            <source src="../images/store/store.webm" type="video/webm"/>
                                                Sorry, your browser doesn't support embedded videos.
                                    </video> */}
                                 
                                    </div>
                                </div>
                            </div>
                    </section>
                </div>
                <section
                    className="section_store_slider mt-5 pb-0 d-flex align-items-center justify-content-center pattern flex-column ">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-5 col-lg-12">
                                <div className="store-carousel-heading cv-banner-info-send-money h-100 d-flex justify-content-end">
                                    <h3>Money <br className="d-none d-xl-block" />management, <span>simplified.</span></h3>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-12">
                                <div className="slick-carousel-store">
                                    <div className="slide-wrap store-slider">
                                        <div className="slide-content slide-1">
                                            <p className="slide-text-heading">Streamline your finances</p>
                                            <p className="slide-text">Hold and manage your funds with just one account in more 35 different currencies.</p>
                                        </div>
                                        <div className="img_box img_icon_slider">
                                            <img src={iconA} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="slide-wrap store-slider">
                                        <div className="slide-content slide-2">
                                            <p className="slide-text-heading">Access powerful FX capabilities</p>
                                            <p className="slide-text">Connect with PayStreet's robust cross-border solutions and handle all your operations from one place with ease.</p>
                                        </div>
                                        <div className="img_box img_icon_slider">
                                            <img src={iconB} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="slide-wrap store-slider">
                                        <div className="slide-content slide-3">
                                            <p className="slide-text-heading">Minimise FX costs and save more</p>
                                            <p className="slide-text">Convert among multiple currencies at the click of a button. Low and competitive FX rates guaranteed.</p>
                                        </div>
                                        <div className="img_box img_icon_slider">
                                            <img src={iconC} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="slide-wrap store-slider">
                                        <div className="slide-content slide-4">
                                            <p className="slide-text-heading">Top-notch security guaranteed</p>
                                            <p className="slide-text">We maintain the highest security and latest encryption technology to ensure your funds remain safe.</p>
                                        </div>
                                        <div className="img_box img_icon_slider">
                                            <img src={iconD} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mobile_responsive color_change  smart_send_money" id="smart-toolsets" data-color="indigo" style={{ 'transition': 'background-color 1s ease' }}>
                    <div className="py-245">
                        <div className="">
                            <div className="container smart_toolsets_content">
                                <p className="green_small_text">ELEGANT BANKING FOR BUSINESSES</p>
                                <h2 className="smart_toolsets_content_title">Empower your global financial journey with a wallet that speaks multiple languages.</h2>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text_box">
                                            <h2>Centralised management <span>pools</span> all incoming funds for easy reconciliation  </h2>
                                            <p>Whether you receive money through SWIFT or local channels, other PayStreet accounts or intra-account conversion, the <span>centralisation of funds</span> at one place makes its management easy.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img_box d-flex justify-content-end ps-2">
                                            <img src={bankA} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                                        <div className="img_box pe-2">
                                            <img src={bankB} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                                        <div className="text_box">
                                            <h2>Get more bang for your
                                                buck! Convert when the rate is <span>just right</span> </h2>
                                            <p>Collect funds from all over the world and keep them safely in wallets for as long as you want. <span>Time payments and conversions</span> for better value. Convert or withdraw in bulk when the rate is favourable.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text_box">
                                            <h2><span>Maximise</span> your cross border capabilities effortlessly</h2>
                                            <p>The global multi-currency wallet <span>seamlessly</span> connects with bank accounts and FX solutions, thus allowing the business to efficiently make use of every cross-border tool on the platform. </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img_box d-flex justify-content-end ps-2">
                                            <img src={bankC} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row  align-items-center">
                                    <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                                        <div className="img_box pe-2">
                                            <img src={bankD} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                                        <div className="text_box">
                                            <h2>Experience the power of <span>borderless banking</span></h2>
                                            <p>Manage your funds <span>anytime, anywhere</span>. Enjoy great FX rates, convenient conversion and payment processes and trustworthy security while you expand your business across the world.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="footer-bg mobile_responsive color_change" data-color="black" style={{ 'transition': 'background-color 1s ease' }}>
                <section className="container ">
                    <div className="scale_business_section">
                        <div className="container-fluid">
                            <div className="row">
                                <h2>
                                    <span>Do more with your money.</span>
                                    <br></br>
                                    Unleash your potential with currency wallets designed for efficient cross-border commerce.
                                </h2>
                                <p className="text-white text-start mtb">Join today. Open an account within minutes.</p>
                                <div className="mt-3 tab-center mob-center">
                                    <a href={CONTACT}><button type="button" className="btn button_white me-4">Talk to us
                                        <i className="fal fa-long-arrow-right"></i>
                                    </button></a>
                                    <a href={REGISTERURL}><button type="button" className="btn button_white get_started">Get started
                                        <i className="fal fa-long-arrow-right"></i>
                                    </button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        </>
    );
}

export default StoreMoney;