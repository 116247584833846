import React from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";

function Solution() {
	return (
        <>
			<div className="wrapper color_change" data-color="black" >
				<div className="background_shadow">		
				<HeaderMenu />
					<section className=" section_spacing background_img">
						<div className="subpage_banner_section banner_section ">
							<p className="mb-4 text-lightgeeen banner_p">B2B cross-border and FX platform</p>
							<h1 className="m-auto Banner_title banner_h1">Powering cross-border payments securely for <span className="text-green">startups.</span></h1>
						</div>
					</section>
				</div>
				<section className="IP-page image">
					<div style={{marginTop:"-120px"}}>
						<img src="/images/solution-image-5.png" alt="..." className="w-100" />
					</div>
					<div className="px-3 container pt-430px">
						<h2 className="text-white text-center px-4 page_sub_title">A single <span className="text-green">integrated</span> account for multiple activities that helps you scale your business globally.</h2>
					</div>
					<div className="w-100 balances-declaration">
						<div className="container d-flex align-items-center justify-content-center position-relative px-5">
							<img src="/images/solution-image-10.png" className="rotate-img" alt="..." />
								<h2 className="animate fadeInLeft three top">Pay <span className="text-green">S$ 0</span>/month</h2>
								<h2 className="animate fadeInRight four bottom">No minimum balance</h2>
						</div>
					</div>
					<div className="container mb_50">
						<h2 className="text-white text-center page_sub_title mb_35 "><span className="text-green">Send money</span> or <span className="text-green">convert</span> into XX different currencies with ease.</h2>
						<p style={{ maxWidth: '907px', width: '100%', margin: 'auto' }}>Get rid of high mark ups or hidden fees with PayStreet's transparent pricing. Hedge against currency fluctuation with our competitive forex services.</p>
					</div>
					<div className="d-flex flex-wrap justify-content-center mb_75">
						<div className="currencies-fees border-end border-2 border-secondary">
							<h3 className="text-lightgeeen text-center">0.4% Fees</h3>
							<p>xx currencies</p>
						</div>
						<div className="currencies-fees ">
							<h3 className="text-white  text-center">0.6% Fees</h3>
							<p>xx currencies</p>
						</div>
					</div>
				</section>
				<section className="pb-120px color-black">
					<div className=" Currencies_section">
						<div className="Currencies mb-5">
							<div className="Currencies_group">
								<div className="Currencies-pill">aud</div>
								<div className="Currencies-pill">bhd</div>
								<div className="Currencies-pill">bgn</div>
								<div className="Currencies-pill">cad</div>
								<div className="Currencies-pill">huf</div>
								<div className="Currencies-pill">czk</div>
								<div className="Currencies-pill">dkk</div>
								<div className="Currencies-pill">eur</div>
								<div className="Currencies-pill">huf</div>
							</div>
							<div aria-hidden="true" className="Currencies_group">
								<div className="Currencies-pill">aud</div>
								<div className="Currencies-pill">bhd</div>
								<div className="Currencies-pill">bgn</div>
								<div className="Currencies-pill">cad</div>
								<div className="Currencies-pill">huf</div>
								<div className="Currencies-pill">czk</div>
								<div className="Currencies-pill">dkk</div>
								<div className="Currencies-pill">eur</div>
								<div className="Currencies-pill">huf</div>
							</div>
						</div>
						<div className="Currencies mb-5">
							<div className="Currencies_group1">
								<div className="Currencies-pill">ils</div>
								<div className="Currencies-pill">kes</div>
								<div className="Currencies-pill">kwd</div>
								<div className="Currencies-pill">mxn</div>
								<div className="Currencies-pill">nzd</div>
								<div className="Currencies-pill">nik</div>
								<div className="Currencies-pill">omr</div>
								<div className="Currencies-pill">pln</div>
								<div className="Currencies-pill">qar</div>
							</div>
							<div aria-hidden="true" className="Currencies_group1">
								<div className="Currencies-pill">ils</div>
								<div className="Currencies-pill">kes</div>
								<div className="Currencies-pill">kwd</div>
								<div className="Currencies-pill">mxn</div>
								<div className="Currencies-pill">nzd</div>
								<div className="Currencies-pill">nik</div>
								<div className="Currencies-pill">omr</div>
								<div className="Currencies-pill">pln</div>
								<div className="Currencies-pill">qar</div>
							</div>
						</div>
						<div className="Currencies mb-5">
							<div className="Currencies_group2">

								<div className="Currencies-pill">qar</div>
								<div className="Currencies-pill">ils</div>
								<div className="Currencies-pill">kes</div>
								<div className="Currencies-pill">kwd</div>
								<div className="Currencies-pill">mxn</div>
								<div className="Currencies-pill">nzd</div>
								<div className="Currencies-pill">nik</div>
								<div className="Currencies-pill">omr</div>
								<div className="Currencies-pill">pln</div>
							</div>
							<div aria-hidden="true" className="Currencies_group2">
								<div className="Currencies-pill">qar</div>
								<div className="Currencies-pill">ils</div>
								<div className="Currencies-pill">kes</div>
								<div className="Currencies-pill">kwd</div>
								<div className="Currencies-pill">mxn</div>
								<div className="Currencies-pill">nzd</div>
								<div className="Currencies-pill">nik</div>
								<div className="Currencies-pill">omr</div>
								<div className="Currencies-pill">pln</div>
							</div>
						</div>
					</div>
				</section>
				<section className="container smart_toolsets color_change" id="smart-toolsets" data-color="indigo" >
					<div className="py-245">
						<div className="container">
							<div className="smart_toolsets_content solution_page">
								<p className="green_small_text">A SINGLE INTEGRATED ACCOUNT</p>
								<h2 className="smart_toolsets_content_title">Experience a complete suite of banking tools at your fingertips.</h2>
								<div className="row mb_150 align-items-center">
									<div className="col-lg-6  ">
										<div className="text_box">
											<h2><span>Local</span> and <span>global</span> settlement accounts</h2>
											<p>Collect local or international payments in <span>IBANs under your company name.</span> Share your business account details and start accepting funds in multiple currencies just like a bank.</p>
										</div>
									</div>
									<div className="col-lg-6 order-md-2 order-2 order-lg-1">
										<div className="img_box ps-2 justify-content-end d-flex">
											<img src="/images/solution-image-1.svg" className="img-fluid" alt="..." />
										</div>
									</div>
								</div>
								<div className="row mb_150 align-items-center">
									<div className="col-lg-6  order-md-2 order-2 order-lg-1">
										<div className="img_box pe-2">
											<img src="/images/solution-image-2.svg" className="img-fluid" alt="..."/>
										</div>
									</div>
									<div className="col-lg-6 order-md-1 order-1 order-lg-2">
										<div className="text_box">
											<h2><span>Competitive</span> pricing for all your business needs</h2>
											<p><span>Low and transparent fees</span> made available even to small enterprises. Extremely <span>low conversion markups</span> that allow inexpensive access to exchange rates at the interbank level.</p>
										</div>
									</div>
								</div>
								<div className="row mb_150 align-items-center">
									<div className="col-lg-6">
										<div className="text_box">
											<h2>Available <span>24/7</span> from anywhere in the world </h2>
											<p>Experience the all-in-one account at your <span>fingertips</span> so you never lose control of your business activities wherever you go. Now, one tap away with iOS and Android <span>mobile app</span></p>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="img_box pe-2 justify-content-end d-flex">
											<img alt="..." src="/images/solution-image-3.svg" className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="row align-items-center">
									<div className="col-lg-6 order-md-2 order-2 order-lg-1">
										<div className="img_box d-flex pe-2">
											<img src="/images/solution-image-4.svg" className="img-fluid" alt="..." />
										</div>
									</div>
									<div className="col-lg-6 order-md-1 order-1 order-lg-2">
										<div className="text_box">
											<h2>Highly <span>robust</span> platform</h2>
											<p>We never compromise with the safety of your information. Our <span>advanced security parameters</span> meet the highest standards set for financial platforms</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="color-black ">
					<div className="container scale_business_section background_image">
						<div className="container-fluid">
							<div className="row">
								<h2 className="mb-44"><span>Built to last.</span>
								<br></br>We've created the product with care keeping convenience and user experience in mind.</h2>
								<p className="text-white mb-46">Open an account within minutes.</p>
								<div>
									<button type="button" className="btn button_white me-4">Talk to us  
										<img src="images/next_black.png" alt=".." className="img-fluid" />
									</button>
									<button type="button" className="btn button_white get_started">Get started
										<img src="images/next_white.png" alt=".." className="img-fluid" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		</>
	);
}

export default Solution;