import React, { useState } from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import { Tablet1 } from "../Parallax/Home/Tablet1";
import { REGISTERURL } from "../../constant";
import { InView } from "react-intersection-observer";
import {
  AccountOpeningMail,
} from "../../constant";
import quote from "../../assets/images/home/quote.png";
import client from "../../assets/images/home/client.png";
import mobBg from "../../assets/images/home/tab1.png";
import mobGif from "../../assets/images/home/ps-home.svg";
import homeCart1 from "../../assets/images/home/homecart1.svg";
import homeCart2 from "../../assets/images/home/homecart2.svg";
import homeCart3 from "../../assets/images/home/homecart3.svg";
import homeCart4 from "../../assets/images/home/homecart4.svg";
import image_1 from "../../assets/images/home/smart_image_1.png";
import image_2 from "../../assets/images/home/smart_image_2.png";
import image_3 from "../../assets/images/home/smart_image_3.png";
import image_4 from "../../assets/images/home/smart_image_4.png";
import image_5 from "../../assets/images/home/smart_image_5.png";
import image_6 from "../../assets/images/home/smart_image_6.png";
import axios from "axios";
import PageLoading from "../components/loading/page-loading";

function Home() {
  const handleIntersection = (inView, entry) => {
    if (inView) {
      /* Section is now visible, fadeIn content */
      entry.target.classList.add("fadeIn");
    }
  };

  const [email, setEmail] = useState({
    emailAddress: ''
  })
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [loading, setLoading] = useState(false);
  const [apiScsMessage, setApiMessage] = useState("")
  const [apiErrMessage, setApiErrMessage] = useState("")
  const EmailChangehandler = (e) => {
    const name = e.target.name
    const value = e.target.value
    setEmail({ ...email, [name]: value })
  }

  const EmailSubmitHandler = () => {
    try {
      setApiMessage('')
      setApiErrMessage('')
      if (emailRegex.test(email.emailAddress)) {
        setLoading(true)
        axios.post(AccountOpeningMail, email).then((res) => {
          if (res.data.code === 200) {
          setLoading(false);
            setApiMessage(res.data.message)
            // window.location.href = REGISTERURL
          } else {
            setApiErrMessage(res.data.message)
            setLoading(false);
          setEmail({
            emailAddress: ''
          })
          }
        }).catch((err => {
          setApiErrMessage(err.response.data.message)
          setLoading(false);
        }))
      } else {
        setApiErrMessage('Enter a valid email')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div
        className="wrapper mobile_responsive color_change home "
        data-color="black"
        style={{ transition: "background-color 1s ease" }}>
        <div className="background_shadow background_image ">
          <HeaderMenu />
          <section className="section_spacing-p1">
            <div className="banner_section">
              <h1 className="banner_title">
                <div className="slidingVertical">
                  <span>Send</span>
                  <span>Convert</span>
                  <span>Collect</span>
                  <span>Store</span>
                </div>
                &nbsp;money
              </h1>
              <h2 className="banner_sub_title">
                A cross‑border payments platform that meets all your banking and
                financial needs.
              </h2>
              <p className="mb-4">
                Scale your business now. Open business banking account for free
                within minutes.
              </p>
              {/* <div className="vertical-line" /> */}
              <div className="input-group banner_input_btn">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your email"
                  name="emailAddress"
                  onChange={EmailChangehandler}
                  value={email.emailAddress}
                />
                <div className="input-group-append">
                  <button className="btn" type="button" onClick={EmailSubmitHandler} disabled={loading}>
                    {loading ? <PageLoading /> : 'Get started'}
                  </button>
                </div>
              </div>
              <div className="mt-3">
                {apiScsMessage && <p className="text-success">
                  {apiScsMessage} </p>}
                {apiErrMessage && <p className="text-danger">
                  {apiErrMessage} </p>}
              </div>
              {/* {apiScsMessage && <span className="text-warning text-center d-block">
                {apiScsMessage} </span>} */}
            </div>
          </section>
        </div>
        <section
          className="text-center mt-5 tab-container d-none d-lg-block"
          style={{ overflow: "hidden" }}>
          <Tablet1 />
        </section>
        <div className="relative home-mob-ticker d-none d-lg-none">
          <div className="overlay-animation-container text-center">
            <img src={mobBg} className="home-anim-bg" />
            <div className="sliding-text">
              <object type="image/svg+xml" data={mobGif}></object>
            </div>
          </div>
        </div>
        <section className="section_spacing-110 pb-0 d-flex align-items-center justify-content-center pattern flex-column d-none d-xl-block">
          <InView as="div" className="hide" onChange={handleIntersection}>
            <section className="home_page_group d-none d-md-none d-lg-block">
              <div className="container-fluid mt-4 ">
                <div className="home-page-four-cart">
                  <div className="row ">
                    <div className="col-xl-3 col-lg-6 col-md-6">
                      <div className="home-4444 animate" data-wow-duration="2s" data-wow-delay="0.5s">
                        <img className="w-100" src={homeCart1} alt=".." />
                        <p className="home-444-heading">Send</p>
                        <p className="home-444-para">
                          Send money locally or worldwide in more than 40 currencies. Pay faster and much cheaper than banks.
                        </p>
                        <a href="/sendmoney" className="btn home-4444-btn">
                          Send money features{" "}
                          <i className="fal fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6">
                      <div
                        className="home-4444 animate"
                        data-wow-duration="2s"
                        data-wow-delay="1s">
                        <img className="w-100" src={homeCart2} alt="..." />
                        <p className="home-444-heading">Collect</p>
                        <p className="home-444-para">
                          Receive funds in more than 35 currencies using local
                          and global bank accounts under your business name
                        </p>
                        <a href="/collect" className="btn home-4444-btn">
                          Virtual account features{" "}
                          <i className="fal fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6">
                      <div
                        className="home-4444 animate"
                        data-wow-duration="2s"
                        data-wow-delay="1.5s">
                        <img className="w-100" src={homeCart3} alt="..." />
                        <p className="home-444-heading">Store</p>
                        <p className="home-444-para">
                          Hold and manage money in multi-currency wallets. Fund wallet for free and pay others or convert money.
                        </p>
                        <a href="/store" className="btn home-4444-btn">
                          Currency wallet features{" "}
                          <i className="fal fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6">
                      <div
                        className="home-4444 animate"
                        data-wow-duration="2s"
                        data-wow-delay="2s">
                        <img className="w-100" src={homeCart4} alt="..." />
                        <p className="home-444-heading">Convert</p>
                        <p className="home-444-para">
                          Convert in real-time into all primary global currencies at competitive FX rates. Hedge exposure to currency risk.
                        </p>
                        <a href="/convert" className="btn home-4444-btn">
                          FX conversion features{" "}
                          <i className="fal fa-long-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </InView>

        </section>
        <div className="home-mobile-slider d-flex d-xl-none">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="home-mobile-carousel">
                  <div className="slick-carousel-mobile">
                    <div>
                      <div className="slide-wrap">
                        <div className="home-4444">
                          <img className="w-100" src={homeCart1} alt=".." />
                          <p className="home-444-heading">Send</p>
                          <p className="home-444-para">
                            Send money locally or worldwide in more than 40 currencies. Pay faster and much cheaper than banks.
                          </p>
                          <a href="/sendmoney" className="btn home-4444-btn">
                            Send money features{" "}
                            <i className="fal fa-long-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="slide-wrap">
                        <div className="home-4444" >
                          <img className="w-100" src={homeCart2} alt="..." />
                          <p className="home-444-heading">Collect</p>
                          <p className="home-444-para">
                            Receive funds in more than 35 currencies using local
                            and global bank accounts under your business name
                          </p>
                          <a href="/collect" className="btn home-4444-btn">
                            Virtual account features{" "}
                            <i className="fal fa-long-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="slide-wrap">
                        <div className="home-4444">
                          <img className="w-100" src={homeCart3} alt="..." />
                          <p className="home-444-heading">Store</p>
                          <p className="home-444-para">
                            Hold and manage money in multi-currency wallets. Fund wallet for free and pay others or convert money.
                          </p>
                          <a href="/store" className="btn home-4444-btn">
                            Currency wallet features{" "}
                            <i className="fal fa-long-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="slide-wrap">
                        <div className="home-4444" >
                          <img className="w-100" src={homeCart4} alt="..." />
                          <p className="home-444-heading">Convert</p>
                          <p className="home-444-para">
                            Convert in real-time into all primary global currencies at competitive FX rates. Hedge exposure to currency risk.
                          </p>
                          <a href="/convert" className="btn home-4444-btn">
                            FX conversion features{" "}
                            <i className="fal fa-long-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="mobile_responsive smart_toolsets color_change"
          id="smart-toolsets"
          data-color="indigo">
          <div className="py-245">
            <div className="container">
              <div className="smart_toolsets_content">
                <p className="green_small_text">Operate without borders</p>
                <h2 className="smart_toolsets_content_title">
                  Bringing smart financial protocols for fast growing
                  businesses.
                </h2>
                <div className="row mb_150 align-items-center">
                  <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                    <div className="img_box pe-2">
                      <img src={image_1} alt="..." className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                    <div className="text_box">
                      <h2>
                        Spend<span> less </span>and reduce costs
                      </h2>
                      <p>
                        Get low-cost smart financial payment solutions tailored
                        to your business needs. <span>Save money</span> and fuel
                        your business growth. We are a fintech platform like
                        your digital bank. At your fingertips and accessible
                        from anywhere on the planet!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb_150 align-items-center">
                  <div className="col-lg-6">
                    <div className="text_box">
                      <h2>
                        <span>No</span> platform fee or hidden charges
                      </h2>
                      <p>
                        With <span> no hidden platform fees </span>
                        and transparent pricing, reduce your costs. Use easy to
                        manage multi-currency wallets. No minimum balance or
                        number of transactions required.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="img_box d-flex justify-content-end ps-2">
                      <img src={image_2} alt="..." className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="row mb_150 align-items-center">
                  <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                    <div className="img_box pe-2">
                      <img src={image_3} alt="..." className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                    <div className="text_box">
                      <h2>
                        Get <span>local</span> and <span>global</span> bank
                        accounts
                      </h2>
                      <p>
                        Fulfil business requirement from anywhere in the world
                        by opening bank accounts in multiple currencies
                        including <span>USD, GBP, EUR, CNY, JPY, SGD, HKD</span>{" "}
                        and many more. Yes! And that too without the hassle of
                        visiting the bank or engaging in excessive paperwork.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb_150 align-items-center">
                  <div className="col-lg-6">
                    <div className="text_box">
                      <h2>
                        <span>Safety</span> ensured always
                      </h2>
                      <p>
                        Your trust in us is our number one priority. Funds move
                        only when <b>YOU </b>
                        move them, thanks to an extra layer of security within
                        the platform. <span>
                          Two-factor authentication
                        </span>{" "}
                        blocks unknown access to your account.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="img_box d-flex justify-content-end ps-2">
                      <img src={image_4} alt="..." className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="row mb_150 align-items-center">
                  <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                    <div className="img_box pe-2">
                      <img src={image_5} alt="..." className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                    <div className="text_box">
                      <h2>
                        Your <span>data</span> is stored safely
                      </h2>
                      <p>
                        Customer privacy is paramount and we never share your
                        information with anyone, unless mandated by law or legal
                        compliance. Our <span>digital security encryption</span>{" "}
                        and access protocols are of the highest standards and
                        ensure that your data remains safe with us.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="text_box ps-2">
                      <h2>
                        Access your account easily on the <span>phone</span> too
                      </h2>
                      <p>
                        Get all you do with your account at your fingertips with
                        PayStreet's <span>mobile app.</span> Coming soon on
                        Google Play Store and iOS App Store.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="img_box d-flex justify-content-end">
                      <img src={image_6} alt="img" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="footer-bg mobile_responsive color_change"
        data-color="black">
        <section className="container testimonial_section">
          <div className="container-fluid">
            <div className="testimonial ">
              <h2 className="mb_92">
                What our clients <span>say</span>
              </h2>
              <div className="slick-carousel">
                <div>
                  <div className="slide-wrap">
                    <div className="slide_img">
                      <img src={quote} alt="..." />
                      <div className="client_img">
                        {/* <img src={client} alt="..." /> */}
                      </div>
                    </div>
                    <div className="slide-content">
                      <p className="slide-text">
                        I highly recommend PayStreet. The platform's process for
                        remitting money is seamless and secure and its
                        competitive exchange rates and low fees have saved me a
                        substantial amount of money compared to traditional
                        banking methods. The platform's user interface is
                        intuitive and user-friendly, making it easy to navigate
                        and initiate payments. The transaction speed is
                        impressive, with funds being transferred swiftly and
                        securely to recipients in different countries. PayStreet
                        has genuinely provided me with a convenient and
                        cost-effective solution for conducting global
                        transactions.{" "}
                      </p>
                      <p className="slide-author-name">Randall J. Bush</p>
                      <span className="slide-company-name">
                        Rite Solutions LLC
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-wrap">
                    <div className="slide_img">
                      <img src={quote} alt="..." />
                      <div className="client_img">
                        {/* <img src={client} alt="..." /> */}
                      </div>
                    </div>
                    <div className="slide-content">
                      <p className="slide-text">
                        As a business owner with global operations, I have
                        always faced challenges when it comes to transferring
                        funds across borders efficiently and securely. However,
                        since discovering PayStreet, my experience has been
                        nothing short of exceptional. PayStreet has truly
                        changed the way I conduct international transactions for
                        my business. Its user-friendly interface, speedy
                        processing, robust security, competitive rates, and
                        excellent customer support have made it an invaluable
                        tool in streamlining my financial operations.
                      </p>
                      <p className="slide-author-name">Rodney Budge </p>
                      <span className="slide-company-name">
                        Adept Computer Consultants Inc
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-wrap">
                    <div className="slide_img">
                      <img src={quote} alt="..." />
                      <div className="client_img">
                        {/* <img src={client} alt="..." /> */}
                      </div>
                    </div>
                    <div className="slide-content">
                      <p className="slide-text">
                        PayStreet has significantly simplified the process of
                        sending and receiving international payments. With this
                        platform, I no longer have to deal with the complexities
                        of traditional banking systems or worry about excessive
                        fees and slow transaction times. The customer support
                        team has been prompt and knowledgeable, providing
                        assistance whenever I've had questions or encountered
                        any issues. Their commitment to customer satisfaction is
                        commendable. I highly recommend it to anyone in need of
                        a reliable and efficient solution for cross-border
                        payments.
                      </p>
                      <p className="slide-author-name">Madhavan S Nair </p>
                      <span className="slide-company-name">
                        New Edge Commercial Pte Ltd
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="slide-wrap">
                    <div className="slide_img">
                      <img src={quote} alt="..." />
                      <div className="client_img">
                        {/* <img src={client} alt="..." /> */}
                      </div>
                    </div>
                    <div className="slide-content">
                      <p className="slide-text">
                        PayStreet has made international transactions incredibly
                        smooth and efficient for my business. What truly stands
                        it apart from other similar platforms is its customer
                        support which has been exceptional. Whenever I've
                        encountered any issues or had questions, the support
                        team has been quick to respond and provide helpful
                        guidance. Their responsiveness and expertise have made
                        my experience with the platform even more
                        satisfying.PayStreet saves time, enhances security, and
                        offers a seamless user experience. Kudos to the team for
                        coming up with an inexpensive, reliable and smart
                        solution for cross-border payments.
                      </p>
                      <p className="slide-author-name">Ruth Martinez </p>
                      <span className="slide-company-name">
                        Trium AI Solutions Inc.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="scale_business_section">
            <div className="container-fluid">
              <div className="row">
                <h2 className="mb_50">
                  <span>Scale your business now. </span>
                  <br />
                  Open an account within minutes.
                </h2>
                <div className="tab-center mob-center">
                  <a href="/contact">
                    <button type="button" className="btn button_white me-4">
                      Talk to us
                      <i className="fal fa-long-arrow-right"></i>
                    </button>
                  </a>
                  <a href={REGISTERURL}>
                    <button
                      type="button"
                      className="btn button_white get_started">
                      Get started
                      <i className="fal fa-long-arrow-right"></i>
                    </button>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Home;
