import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { NavLink, useLocation } from "react-router-dom";
import { URL, REGISTERURL, ADDRESS, PHONE, COMPANYTIME, EMAIL } from '../../constant';
import logo from "../../assets/images/logo.png";
import blackLogo from "../../assets/images/black_logo.png";

export default function HeaderMenuLite() {
    var location = useLocation();
    return (
        <>
            <header className="header_custom header-lite header-normal-light ">
                <nav className="navbar navbar-expand-xl navbar-lite-header">
                    <div className="container-fluid">
                        <NavLink to={"/"} className="navbar-brand">
                        <img src={logo} alt="..." className="logo-light" />
                        <img src={blackLogo} alt="..." className="logo-dark"/>
                        </NavLink>
                        <button type="button" className="navbar-toggle x collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse megamenu-header-lite" id="main_nav">
                        <ul className="navbar-nav ms-auto d-block d-xl-none d-xxl-none mob-login-register mt-4 mb-4">
                            <li className="nav-item login_btn"><a className="nav-link" href={URL}> Login </a></li>
                            <li className="nav-item"><HashLink smooth to={REGISTERURL} className="nav-link apply_now text-white">Apply now</HashLink></li>
                        </ul>
                            <ul className="navbar-nav navbar-change-hover">
                                {/* <li className={location.pathname === '/' ? 'nav-item active' : 'nav-item'} > <a className="nav-link" href="/">Home </a> </li> */}
                                <li className={location.pathname === '/b2b' || location.pathname === '/incorporation' ? 'nav-item dropdown has-megamenu active' : 'nav-item dropdown has-megamenu'}>
                                    <a className="nav-link dropdown-toggle " href="#" data-bs-toggle="dropdown"> Products  </a>
                                    <div className="dropdown-menu megamenu header-mega-lite" role="menu">
                                        <div className="container-fluid">
                                            <div className="row mega-menu-dropdown mega-menu-dropdown-lite">
                                                <div className="col-xl-3 col-lg-12 p-0 d-none d-xl-block">
                                                    <div className="row">
                                                        <div className="col-xl-10 pr-0">
                                                            <h4>Products </h4>
                                                            <p>
                                                                Comprehensive solutions that cover every aspect of your business, from incorporation and business banking to accounting and financing.
                                                            </p>
                                                        </div>
                                                        <div className="col-2  d-none d-xl-flex d-xxl-flex  mega-menu-border">
                                                            <div className="col-6 mega-menu-border"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-9 col-12">
                                                    <div className="row">
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li>
                                                                    <a className="dropdown-item-mega" href={"/b2b"}>
                                                                        <h5>Payments platform <span className="mega-menu-arrow">
                                                                            <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                                viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                                <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                            </svg>
                                                                        </span></h5>
                                                                        <p>Powering payments securely for businesses</p>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li><a className="dropdown-item-mega" href="/incorporation"> <h5>Corporate support  <span className="mega-menu-arrow">
                                                                    <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                        viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                        <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                    </svg>
                                                                </span> </h5>
                                                                    <p>Empowering your business from inception to success</p>
                                                                </a></li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </li>
                                <li className={location.pathname === '/sendmoney' || location.pathname === '/store' || location.pathname === '/collect' || location.pathname === '/convert' ? 'nav-item dropdown has-megamenu active' : 'nav-item dropdown has-megamenu'}>
                                    <a className="nav-link dropdown-toggle " href="#" data-bs-toggle="dropdown"> Solutions  </a>
                                     <div className="dropdown-menu megamenu header-mega-lite" role="menu">
                                        <div className="container-fluid">
                                            <div className="row mega-menu-dropdown mega-menu-dropdown-lite">
                                                <div className="col-xl-3 col-lg-12 p-0 d-none d-xl-block">
                                                    <div className="row">
                                                        <div className="col-xl-10 pr-0">
                                                            <h4>Solutions </h4>
                                                            <p>
                                                                Making cross-border payments affordable and accessible for businesses, regardless of their location, currency or banking infrastructure.
                                                            </p>
                                                        </div>
                                                        <div className="col-2  d-none d-xl-flex d-xxl-flex  mega-menu-border">
                                                            <div className="col-6 mega-menu-border"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-9 col-12">
                                                    <div className="row">
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li>
                                                                    <a className="dropdown-item-mega" href={"/sendmoney"}>
                                                                        <h5>Send <span className="mega-menu-arrow">
                                                                            <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                                viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                                <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                            </svg>
                                                                        </span></h5>
                                                                        <p>Send funds with ease in more than 40 currencie</p>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li><a className="dropdown-item-mega" href="/collect"> <h5>Collect  <span className="mega-menu-arrow">
                                                                    <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                        viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                        <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                    </svg>
                                                                </span> </h5>
                                                                    <p>Safely receive money in excess of 35 currencies</p>
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li>
                                                                    <a className="dropdown-item-mega" href={"/convert"}>
                                                                        <h5> Convert <span className="mega-menu-arrow">
                                                                            <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                                viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                                <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                            </svg>
                                                                        </span>
                                                                        </h5>
                                                                        <p>Swift FX conversion to all key global currencies</p>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li><a className="dropdown-item-mega" href="/store"> <h5>Store  <span className="mega-menu-arrow">
                                                                    <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                        viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                        <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                    </svg>
                                                                </span></h5>
                                                                    <p>Hold and manage money in multicurrency wallets</p>
                                                                </a></li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </li>
                                <li className={location.pathname === '/about-us' ? 'nav-item dropdown has-megamenu active' : 'nav-item dropdown has-megamenu'}>
                                    <a className="nav-link dropdown-toggle " href="#" data-bs-toggle="dropdown" isActive={false}> Company  </a>
                                    <div className="dropdown-menu megamenu header-mega-lite" role="menu">

                                        <div className="container-fluid">
                                            <div className="row mega-menu-dropdown mega-menu-dropdown-lite">
                                                <div className="col-xl-3 col-lg-12 p-0 d-none d-xl-block">
                                                    <div className="row">
                                                        <div className="col-xl-10 pr-0">
                                                            <h4>COMPANY </h4>
                                                            <p>
                                                                Streamline your global financial operations. Say goodbye to the hassle of traditional banking and hello to the power of borderless payments.
                                                            </p>
                                                        </div>
                                                        <div className="col-2  d-none d-xl-flex d-xxl-flex  mega-menu-border">
                                                            <div className="col-6 mega-menu-border"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-9 col-12">
                                                    <div className="row">
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6 ">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li>
                                                                    <a className="dropdown-item-mega" href={"/about-us"}>
                                                                        <h5>About Paystreet <span className="mega-menu-arrow">
                                                                            <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                                viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                                <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                            </svg>
                                                                        </span></h5>
                                                                        <p>Helping businesses to transact globally with confidence</p>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6 ">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li>
                                                                    <a className="dropdown-item-mega" href={"/about-us"}>
                                                                        <h5>Mission <span className="mega-menu-arrow">
                                                                            <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                                viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                                <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                            </svg>
                                                                        </span></h5>
                                                                        <p>Making international payments fast, secure and seamless</p>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6 ">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li><a className="dropdown-item-mega" id="whyclickd" href="/about-us#why-us-section" > <h5>Why us?  <span className="mega-menu-arrow">
                                                                    <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                        viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                        <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                    </svg>
                                                                </span></h5>
                                                                    <p>Solving challenges for businesses around the globe</p>
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6 ">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li><a className="dropdown-item-mega" href="/about-us#ethos-scroll-section"> <h5>Ethos  <span className="mega-menu-arrow">
                                                                    <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                        viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                        <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                    </svg>
                                                                </span></h5>
                                                                    <p>Dedicated to delivering exceptional service</p>
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </li>

                                <li className={location.pathname === '/contact' || location.pathname === '/blog' || location.pathname === '/faq' ? 'nav-item dropdown has-megamenu active' : 'nav-item dropdown has-megamenu'}>
                                    <a className="nav-link dropdown-toggle " href="#" data-bs-toggle="dropdown"> Resources  </a>
                                    <div className="dropdown-menu megamenu header-mega-lite" role="menu">
                                        <div className="container-fluid">
                                            <div className="row mega-menu-dropdown mega-menu-dropdown-lite">
                                                <div className="col-xl-3 col-lg-12 p-0 d-none d-xl-block">
                                                    <div className="row">
                                                        <div className="col-xl-10 pr-0">
                                                            <h4>Resources </h4>
                                                            <p>
                                                                Navigate the platform easily. Whether you have a question about our product, need assistance with a technical issue or want to provide feedback, we are here to help.
                                                            </p>
                                                        </div>
                                                        <div className="col-2  d-none d-xl-flex d-xxl-flex  mega-menu-border">
                                                            <div className="col-6 mega-menu-border"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-9 col-12">
                                                    <div className="row">
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li>
                                                                    <a className="dropdown-item-mega" href="/blog">
                                                                        <h5>Blog and News<span className="mega-menu-arrow">
                                                                            <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                                viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                                <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                            </svg>
                                                                        </span></h5>
                                                                        <p>Latest trends, PayStreet updates and media resources</p>
                                                                    </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li><a className="dropdown-item-mega" href={"/faq"}> <h5> FAQs <span className="mega-menu-arrow">
                                                                    <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                        viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                        <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                    </svg>
                                                                </span> </h5> <p>Quick and easy answers to your common questions </p></a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li><a className="dropdown-item-mega" href="/#"> <h5>Guide  <span className="mega-menu-arrow">

                                                                    <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                        viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                        <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                    </svg>
                                                                </span> </h5>
                                                                    <p>Valuable tips on FX and cross-border payments </p>
                                                                </a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-xl-3 col-sm-12 col-lg-3 col-md-6">
                                                            <h4 className="d-none d-xl-block d-xxl-block ">&nbsp; </h4>
                                                            <ul className="list-unstyled menu-pr">
                                                                <li>
                                                                    <a className="dropdown-item-mega" href={"/contact"}>
                                                                        <h5>Contact us
                                                                            <span className="mega-menu-arrow">
                                                                                <svg version='1.1' width='24' fill='#A1ECC7' xmlns="http://www.w3.org/2000/svg" x='0px' y='0px'
                                                                                    viewBox='335 335 30 30' enableBackground='new 335 335 30 30' >
                                                                                    <path d='M364.759,350c-0.01-0.395-0.128-0.631-0.279-0.802l-8.342-10.267c-0.393-0.556-1.286-0.644-1.845-0.192
                                                                                c-0.558,0.451-0.588,1.294-0.14,1.797l6.636,8.181h-24.261c-0.709,0-1.283,0.575-1.283,1.283c0,0.708,0.575,1.283,1.283,1.283
                                                                                h24.261l-6.636,8.18c-0.509,0.508-0.363,1.396,0.173,1.874c0.411,0.367,1.512,0.248,1.812-0.27l8.342-10.266
                                                                                c0.217-0.267,0.283-0.464,0.281-0.803L364.759,350z'/>
                                                                                </svg>
                                                                            </span>
                                                                        </h5>
                                                                        <p>Ask questions, report issues or provide feedback</p>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </li>

                            </ul>

                            <ul className="navbar-nav ms-auto d-none d-xl-flex d-xxl-flex">
                                <li className="nav-item login_btn"><a className="nav-link" href={URL}> Log in </a></li>
                                <li className="nav-item"><HashLink smooth to={REGISTERURL} className="nav-link apply_now text-white">Apply now</HashLink></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}