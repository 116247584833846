import React, { useState } from "react";
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
// import BlogSingle from "./BlogSingle";
import blogSlider from "../../assets/images/blog/blog-silder.png";
import blogSlider2 from "../../assets/images/blog/iso.png";
import blogSlider3 from "../../assets/images/blog/blog-silder.png";
import blogSlider4 from "../../assets/images/blog/neo-bank.png";
import blogCart1 from "../../assets/images/blog/blog-1.png";
import blogCart2 from "../../assets/images/blog/blog-2.png";
import blogCart3 from "../../assets/images/blog/blog-3.png";
import blogCart4 from "../../assets/images/blog/bulding-payment.png";
import SubscribeComponent from "../components/Subscribe/SubscribeComponent";

function Blog() {
    const [blog, setBlog] = useState('viewall');

    function handleClick(name) {
        setBlog(name);
    }

    return (
        <>
            <div className="blog-home mobile_responsive_blog blog_color_change color-tnc-indigo" style={{ 'transition': 'background-color 1s ease' }} data-color="indigo">
                <div className="wrapper-lite blog-home">
                    <HeaderMenuLite />
                    <div className="container" id="blog-page-section">
                        <div className="banner_section">
                            <p className="green_small_text mt-80 l-space-2 mb-0">Blogs and news</p>
                        </div>
                        <div className="slick-carousel-blog">
                            <div>
                                <div className="slide-wrap">
                                    <div className="slide-content">
                                        <div className="slide_img">
                                            <a href={"/iso-20022"}>
                                                <img src={blogSlider} alt="..." className="w-100" />
                                            </a>
                                        </div>
                                        <div className="slide-caption-outer">
                                            <div className="blog-date-time">PayStreet team <span>•</span>May 8, 2023<span>•</span>5 mins read</div>
                                            <h2 className="blog-slider-title">ISO 20022</h2>
                                            <p>is a standards development methodology. Through the following note, with context taken from SWIFT (<a href="https://www.swift.com/iso-20022-standards" target="_blank" className="link2">https://www.swift.com/iso-20022-standards</a>), an attempt has been made to explain to... </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="slide-wrap">
                                    <div className="slide-content">
                                        <div className="slide_img">
                                            <a href={"/cbdc"}>
                                                <img src={blogSlider2} alt="..." className="w-100" />
                                            </a>
                                        </div>
                                        <div className="slide-caption-outer">
                                            <div className="blog-date-time">PayStreet team <span>•</span>May 15, 2023<span>•</span>5 mins read</div>
                                            <h2 className="blog-slider-title">Central Bank Digital Currency (CBDC)</h2>
                                            <p>Central bank digital currencies (CBDCs) are a form of digital currency issued by a country’s
                                                central bank. They are similar to cryptocurrencies, except that their value is...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="slide-wrap">
                                    <div className="slide-content">
                                        <div className="slide_img">
                                            <a href={"/efficient-system-for-payment"}>
                                                <img src={blogSlider3} alt="..." className="w-100" />
                                            </a>
                                        </div>
                                        <div className="slide-caption-outer">
                                            <div className="blog-date-time">PayStreet team <span>•</span>May 22, 2023<span>•</span>5 mins read</div>
                                            <h2 className="blog-slider-title">Building an efficient system for Payments of... </h2>
                                            <p>We know that cross-border payments have already become quick and transparent, with far less friction preventing them from arriving at their destination on time...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="slide-wrap">
                                    <div className="slide-content">
                                        <div className="slide_img">
                                            <a href={"/blog-single"}>
                                                <img src={blogSlider4} alt="..." className="w-100" />
                                            </a>
                                        </div>
                                        <div className="slide-caption-outer">
                                            <div className="blog-date-time">PayStreet team  <span>•</span>May 29, 2023<span>•</span>5 mins read</div>
                                            <h2 className="blog-slider-title">Neo Banks vs Traditional Banks</h2>
                                            <p>The Neo-banks are changing the canvas of fin-tech by rapidly bridging the gap between the services that typically the traditional banks offer and the evolving expectations of...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div className="slide-wrap">
                                    <div className="slide-content">
                                        <div className="slide_img">
                                        <a href={"/blog-single"}>
                                                <img src={blogSlider} alt="..." className="w-100" />
                                            </a>
                                        </div>
                                        <div className="slide-caption-outer">
                                            <div className="blog-date-time">John Doe <span>•</span>April 3, 2023<span>•</span>5 mins read</div>
                                            <h2 className="blog-slider-title">A guide to how PayStreet keeps your money safe</h2>
                                            <p>Whether you receive money through SWIFT or local channels, other PayStreet accounts or intra-account conversion, the <span className="text-green">centralisation of funds</span> at one place.</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <div className="row py-4">
                            <div className="col-xl-5 col-lg-5">
                                <div className="input-group banner_input_btn blog-search">
                                    <input type="text" className="form-control" placeholder="Search" />
                                    <div className="input-group-append">
                                        <button className="btn" type="button">Search</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-7 ">
                                <div className="blog-tab-section">
                                    <ul className="nav nav-tabs" id="myTab">
                                        <li className="nav-item">
                                            <a href="#viewall" onClick={() => handleClick('viewall')} className="nav-link active" data-bs-toggle="tab">View all</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#globalpayments" onClick={() => handleClick('globalpayments')} className="nav-link" data-bs-toggle="tab">Global payments</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#business" onClick={() => handleClick('business')} className="nav-link" data-bs-toggle="tab">Business</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#fintech" onClick={() => handleClick('fintech')} className="nav-link" data-bs-toggle="tab">Fintech</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#other" onClick={() => handleClick('other')} className="nav-link" data-bs-toggle="tab">Other</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row py-4">
                            <div className="col-md-12">
                                <div className="tab-content-blog-section">

                                    <div className="row blog-outer">

                                        {(blog === 'fintech' || blog === 'viewall') && <div className="col-12 col-md-6 col-lg-4 mb-62">
                                            <a href={"/cbdc"}>
                                                <img src={blogCart3} alt="..." className="img-fluid" />
                                            </a>
                                            <div className="blog-date-time py-2">PayStreet team <span>•</span>May 15, 2023<span>•</span>5 mins read</div>
                                            <h4 className="related-title"><a href={"/cbdc"}>Central Bank Digital Currency <i className="fal fa-long-arrow-right"></i></a></h4>
                                            <p>Central bank digital currencies (CBDCs) are a form of digital... </p>
                                            <ul className="link-related-btn">
                                                <li className="link-single-blog">Business</li>
                                                <li className="link-single-blog">Send Money</li>
                                            </ul>
                                        </div>}
                                        {(blog === 'globalpayments' || blog === 'viewall') &&
                                            <>
                                                <div className="col-12 col-md-6 col-lg-4 mb-62">
                                                    <a href={"/iso-20022"}>
                                                        <img src={blogCart2} alt="..." className="img-fluid" />
                                                    </a>
                                                    <div className="blog-date-time py-2">PayStreet team <span>•</span>May 8, 2023<span>•</span>5 mins read</div>
                                                    <h4 className="related-title"><a href={"/iso-20022"}>ISO 20022 <i className="fal fa-long-arrow-right"></i></a></h4>
                                                    <p>ISO 20022 is a standards development methodology. </p>
                                                    <ul className="link-related-btn">
                                                        <li className="link-single-blog">Business</li>
                                                        <li className="link-single-blog">Payments</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 mb-62">
                                                    <a href={"/efficient-system-for-payment"}>
                                                        <img src={blogCart4} alt="..." className="img-fluid" />
                                                    </a>
                                                    <div className="blog-date-time py-2">PayStreet team <span>•</span>May 22, 2023<span>•</span>5 mins read</div>
                                                    <h4 className="related-title"><a href={"/efficient-system-for-payment"}>Building an efficient system... <i className="fal fa-long-arrow-right"></i></a></h4>
                                                    <p>We know that cross-border payments have already become quick and...</p>
                                                    <ul className="link-related-btn">
                                                        <li className="link-single-blog">Business</li>
                                                        <li className="link-single-blog">Send Money</li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 mb-62">
                                                    <a href={"/blog-single"}>
                                                        <img src={blogCart1} alt="..." className="img-fluid" />
                                                    </a>
                                                    <div className="blog-date-time py-2">PayStreet team <span>•</span>May 29, 2023<span>•</span>5 mins read</div>
                                                    <h4 className="related-title"><a href={"/blog-single"}>Neo Banks vs Traditional Banks <i className="fal fa-long-arrow-right"></i></a></h4>
                                                    <p>The Neo-banks are changing the canvas of fin-tech by rapidly bridging... </p>
                                                    <ul className="link-related-btn">
                                                        <li className="link-single-blog">Business</li>
                                                        <li className="link-single-blog">Send Money</li>
                                                    </ul>
                                                </div>
                                            </>}
                                        {(blog === 'business') &&

                                            <div className="col-12 col-md-6 col-lg-4 mb-62">

                                                <div class="blog-empty">

                                                    <b>No Blog Found!</b>

                                                </div>

                                            </div>}

                                        {(blog === 'other') &&

                                            <div className="col-12 col-md-6 col-lg-4 mb-62">

                                                <div class="blog-empty">

                                                    <b>No Blog Found!</b>

                                                </div>

                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="footer-bg ">
                    <div className="container scale_business_section contact-subtribe">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <SubscribeComponent 
                                        heading={<><span>Take your business to new heights.</span><br></br> Get expert insights, platform news and valuable tips straight to your inbox.</>}
                                        subheading={<p className="text-white mtb">Join our community today.</p>}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div >
        </>
    );
}

export default Blog;