import React, { useState } from "react";
// import { HashLink } from 'react-router-hash-link';
// import HeaderLite from "../partials/HeaderLite";
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
import { REGISTERURL } from "../../constant";
import { Link } from "react-scroll";
import support from "../../assets/images/support.png";

function FaqPage() {
    const [scoll, setScroll] = useState('');

    // console.log(window.innerWidth);
    if (window.innerWidth <= 1200 && window.innerWidth >= 900) {
        setScroll('-200');
    }

    return (
        <>
            <div className="faq_mobile_responsive faq_color_change color-tnc-indigo" style={{ 'transition': 'background-color 1s ease' }} data-color="indigo">
                <div className="wrapper-lite ">
                    <HeaderMenuLite />

                    <div className="faq-page smart_toolsets_trem" data-color="indigo" style={{ 'transition': 'background-color 1s ease' }}>
                        <div className="mt-80 faq-color-change">
                            <div id="smart-toolsets" data-color="indigo">
                                <div className="container">
                                    <div className="smart_toolsets_content_faq">
                                        <div className="row gx-15">
                                        <div className="col-md-12">
                                                <h1 className="faq-tnc-heading d-block d-lg-none mb-5">Frequently asked questions</h1>
                                            </div>
                                            <div className="col-lg-4 ">
                                            <div className="faq-sidebar mobile-policy-sticky d-block d-lg-none">
                                            <div className="input-group mb-3">
                                                        <input type="text" className="form-control  search-box" placeholder="Search" aria-describedby="button-addon2" />
                                                        <button className="btn btn-outline-secondary " type="button" id="button-addon2"> <i class="fa-solid fa-magnifying-glass"></i>
                                                        </button>
                                                    </div>
                                                 <div class="dropdown dropright mobile-menu-policy">
                                                     <button class="btn btn-tpt dropdown-toggle p-0" type="button" data-bs-toggle="dropdown">Category
                                                         <span class="caret"></span></button>
                                                     <div class="dropdown-menu" role="menu">
                                                     <ul className="list-group animated-line  term-condition-left">
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_0" offset={-200}>
                                                                <span>General</span>
                                                            </Link>
                                                        </li>
                                                        {/* <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_1" offset={-200}>
                                                                <span>Send money</span>
                                                            </Link>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_2" offset={-220}>
                                                                <span>Convert money</span>
                                                            </Link>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_3" offset={-220}>
                                                                <span>Balance</span>
                                                            </Link>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_4" offset={-220}>
                                                                <span>Setting related</span>
                                                            </Link>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_5" offset={-220}>
                                                                <span>PayStreet treasury</span>
                                                            </Link>
                                                        </li> */}
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_1" offset={-220}>
                                                                <span>Security</span>
                                                            </Link>
                                                        </li>
                                                        {/* <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_7" offset={-220}>
                                                                <span>Privacy policy</span>
                                                            </Link>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_8" offset={-220}>
                                                                <span>Referral</span>
                                                            </Link>
                                                        </li> */}
                                                    </ul>
                                                     </div>
                                                 </div>
                                                
                                            </div>
                                                <div className="faq-sidebar d-none d-lg-block">
                                                    <div className="input-group mb-3">
                                                        <input type="text" className="form-control  search-box" placeholder="Search" aria-describedby="button-addon2" />
                                                        <button className="btn btn-outline-secondary " type="button" id="button-addon2">  <i class="fa-solid fa-magnifying-glass"></i>
                                                        </button>
                                                    </div>
                                                    <ul className="list-group animated-line  term-condition-left ">
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_0" offset={-200}>
                                                                <span>General</span>
                                                            </Link>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <Link activeClassNclassName="active" smooth spy to="section_1" offset={-220}>
                                                                <span>Security</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                    <div className="info-box d-none d-lg-block">
                                                        <div className="text-center">
                                                        <img src={support} alt="..." className="mb-2" />
                                                             <p>
                                                            Can't find the answer you are looking for? Please reach us at <a href="mailto:help@paystreet.io">help@paystreet.io</a></p></div>
                                                        <div></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 faq-content" >
                                                <section id="section_0">
                                                    <h1 className="faq-tnc-heading d-none d-lg-block">Frequently asked questions</h1>
                                                    <p className="faq_heading mt-1">General</p>
                                                    <div className="box_general">
                                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingOne">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                       What is PayStreet? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body">PayStreet is a cross-border payments platform for startups, enterprises and corporations of all sizes. Your account on PayStreet offers facilities to: 
                                                                    <ul>
                                                                        <li>Send money locally and globally</li>
                                                                        <li>Convert into other currencies</li>
                                                                        <li>Store money in multi-currency wallets</li>
                                                                        <li>Collect money using accounts named under your business </li>
                                                                    </ul>
                                                                    all at transparent and competitive rates. PayStreet is built keeping user-experience in mind. Operate efficiently and rev-up your business performance with an easy-to-use platform available online at  <a href="www.paystreet.io" className="box-link">www.paystreet.io</a>.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingTwo">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                                      How much does it cost to use PayStreet? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body">
                                                                    PayStreet is free for all. There is no account opening charge, monthly usage charge or minimum balance requirement. Fees is only levied on transactions and conversions. We charge a flat % fee based on the currencies that you wish to make a payment in. The conversion is done for you at extremely competitive near mid-market rates.</div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingThree">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                                    How do I apply for an account? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample" >
                                                                    <div className="accordion-body">Log on to <a href="www.paystreet.io/sign-up" className="box-link">www.paystreet.io/sign-up </a> to open a business account. Opening an account on PayStreet is simple and intuitive. It takes only a few minutes. PayStreet users are required to fill in some basic information about their company and submit key documents. Typically, required documents include: 
                                                                    <div>- Certificate of incorporation </div>
                                                                    <div>- Business registration number </div>
                                                                    <div>- Company bylaws </div>
                                                                    <div>- Ultimate beneficial owner information </div>
                                                                    <div>- A picture of government ID (e.g., passport) </div>
                                                                    <div>- Proof of residence </div>
                                                                    <div>- Additional documents and information where applicable. </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingfour">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapsefour">
                                                                    What documents do I need to onboard on PayStreet? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body">You would need to provide the following documents for onboarding on PayStreet: 
                                                                    <div>- Certificate of incorporation </div>
                                                                    <div>- Business registration number </div>
                                                                    <div>- Company bylaws </div>
                                                                    <div>- Ultimate beneficial owner information </div>
                                                                    <div>- A picture of government ID (e.g., passport) </div>
                                                                    <div>- Proof of residence </div>
                                                                    <div>- Additional documents and information where applicable. </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingfive">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                                                    What companies do you agree to take on as customers? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapsefive" className="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample" >
                                                                    <div className="accordion-body">Any corporation that is not involved with drugs, internet gambling, adult entertainment is welcome to open an account on PayStreet regardless of its size. </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingsix">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                                                    Why should I make payments with PayStreet? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapsesix" className="accordion-collapse collapse" aria-labelledby="flush-headingsix"  data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body">When you transfer money via banks, not only are you charged a high processing fee, you are also exposed to excessive FX margins. The process is also time consuming and troublesome. With PayStreet, not only do we give you access to rates that can slash your transaction costs by as much as 70%, you are also able to make global payments in minutes. </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingseven">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                                                                    Which currencies and countries can I send money in? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseseven" className="accordion-collapse collapse" aria-labelledby="flush-headingseven"  data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body">We enable overseas payments and FX conversions in 37 major currencies to 180 countries in the world including USD, GBP, EUR, HKD, AUD, JPY amongst others. </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingeight">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseeight" aria-expanded="false" aria-controls="flush-collapseeight">
                                                                    How long does a payment take?  
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseeight" className="accordion-collapse collapse" aria-labelledby="flush-headingeight"  data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body">For international payments in Singapore Dollar and Indonesian Rupiah, the average processing time is under an hour. We also deliver same day payments for 20+ currencies including USD, EUR, GBP, HKD, INR, MYR and more for funds received before the daily cut off time. Depending on the currency you are making the payment in you can expect your funds to reach from the same day to 2 working days upon our receipt of your funds. </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-headingnine">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsenine" aria-expanded="false" aria-controls="flush-collapsenine">
                                                                    Can my international customers pay me into my PayStreet account? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapsenine" className="accordion-collapse collapse" aria-labelledby="flush-headingnine"  data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body">Yes! You can open a global receiving account in Singapore Dollar, US Dollar, Euro, Sterling Pound and Indonesian Rupiah in an instant. Your customers can make payments to you in SGD, USD, EUR, GBP and IDR as if they were making a local payment - thus saving you and your customer valuable time and money. </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section id="section_1">
                                                    <p className="faq_heading" id="section_1">Security</p>
                                                    <div className="box_send_money">
                                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                                        <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-securityone">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-securityone" aria-expanded="false" aria-controls="flush-collapse-securityone">
                                                                    Where are my funds kept? 
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapse-securityone" className="accordion-collapse collapse" aria-labelledby="flush-securityone" >
                                                                    <div className="accordion-body">Inward and outward remittance on PayStreet platform is powered by Wallex Technologies Pte. Ltd. ("Wallex") under a legal agreement. Wallex holds a licence as a Major Payment Institution, issued by the Monetary Authority of Singapore (MAS) under the Payment Services Act 2019. All customer funds received by Wallex are safeguarded and kept in a customer segregated account which is separate from all other PayStreet operations accounts. We operate within a very strict framework both for compliance and for the safety of funds in our custody. 
                                                                   </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="flush-securitytwo">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-securitytwo" aria-expanded="false" aria-controls="flush-collapse-securitytwo">
                                                                    How safe is operating on PayStreet? (Alternatively, how do you ensure account safety?)  
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapse-securitytwo" className="accordion-collapse collapse" aria-labelledby="flush-securitytwo" >
                                                                    <div className="accordion-body">PayStreet’s digital security is second to none. We have made robust arrangements to ensure that your data remains safe with us. These include: 
                                                                    <div>- Use of bcrypt algorigthm to store passwords </div>
                                                                    <div>- HTTPS requirement on all pages </div>
                                                                    <div>- Time based one-time passwords for two-factor authentication </div>
                                                                    <div>- Encryption of the entire information uploaded on platform </div>
                                                                    <div>- Third party digital audit every year </div>
                                                                   </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div className="info-box d-block d-lg-none">
                                                        <div>
                                                        <img src={support} alt="..." />
                                                             <p>
                                                            Can't find the answer you are looking for? Please reach us at <a href="mailto:help@paystreet.io">help@paystreet.io</a></p></div>
                                                        <div></div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bg">
                    <section className="container">
                        <div className="scale_business_section">
                            <div className="container-fluid">
                                <div className="row">
                                    <h2><span>Grow with PayStreet</span><br />Scale your business now. Enroll today!
                                    </h2>
                                    <p className="text-white text-start mtb">Open an account within minutes.</p>
                                    <div className="tab-center mob-center">
                                        <a href="/contact"><button type="button" className="btn button_white me-4">Talk to us
                                            <i className="fal fa-long-arrow-right"></i>
                                        </button></a>
                                        <a href={REGISTERURL}><button type="button" className="btn button_white get_started">Get started
                                            <i className="fal fa-long-arrow-right"></i>
                                        </button> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>

        </>
    );
}

export default FaqPage;