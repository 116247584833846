import React, { useState, useEffect } from "react";
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookShareCount,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  LineShareButton,
} from "react-share";

import blogSlider from "../../assets/images/blog/iso.png";
import blogCart1 from "../../assets/images/blog/blog-3.png";
import blogCart2 from "../../assets/images/blog/bulding-payment.png";
import blogCart3 from "../../assets/images/blog/blog-1.png";

import shareIcon from "../../assets/images/blog/share.svg";
import fbImg from "../../assets/images/blog/fb.svg";
import inImg from "../../assets/images/blog/in.svg";
import twImg from "../../assets/images/blog/tw.svg";
import mailImg from "../../assets/images/blog/mail.svg";
import copyImg from "../../assets/images/blog/copy.svg";
import { Helmet } from "react-helmet";
import { blogArr } from "./Blog-data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubscribeComponent from "../components/Subscribe/SubscribeComponent";

function BlogSingleIso() {
  var blog = [];
  const params = useParams();

  blogArr.forEach((item) => {
    if (item.title.toLowerCase().replace(/ /g, "-") === "iso-20022") {
      blog = item;
    }
  });
  // console.log("FDF",blog.shortDescription.substring(0, 155));

  async function copyToClip() {
    await navigator.clipboard.writeText(window.location.href);
    notify();
  }
  const notify = () => toast("Copied");
  return (
    <>
      <Helmet>
        <title>{`Paystreet: ${blog.title}`}</title>
        <meta name="description" content={`${blog.shortDescription.substring(0, 155)}...`}></meta>

        {/* Open Graph meta tags for Facebook */}
        <meta property="og:image" content={`${blog.image}`} />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta
          name="twitter:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />
        <meta name="twitter:image" content={`${blog.image}`} />

        {/* LinkedIn Article Rich Media meta tags */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />
        <meta property="og:image" content={`${blog.image}`} />
      </Helmet>
      <div
        className="mobile_responsive_blog color-tnc-indigo"
        style={{ transition: "background-color 1s ease" }}
        data-color="indigo">
        <div className="wrapper-lite blog-single">
          <HeaderMenuLite />
          <div className="container">
            <div className="blog-single-img-outer">
              <img src={blogSlider} alt="..." className="img-fluid" />
            </div>
            <div className="row">
              <div className="col-lg-11 col-md-10">
                <div className="blog-content-single">
                  <div className="blog-date-time">
                    PayStreet team <span>•</span>May 8, 2023<span>•</span>5 mins
                    read
                  </div>
                  <h2 className="text-green page_sub_title mb-3 ">ISO 20022</h2>
                  <p>
                    <strong>ISO 20022</strong> is a standards development
                    methodology. Through the following note, with context taken
                    from SWIFT (
                    <a
                      href="https://www.swift.com/iso-20022-standards"
                      target="_blank"
                      className="link2">
                      https://www.swift.com/iso-20022-standards
                    </a>
                    ), an attempt has been made to explain to the readers, the
                    benefits of the standard, how it differs from MT, and
                    potentially help the users learn about market practices and
                    better processes. <br />
                  </p>

                  <p>
                    ISO 20022 is an ISO standard for electronic data interchange
                    between financial institutions. It describes a metadata
                    repository containing descriptions of messages and business
                    processes, and a maintenance process for the repository
                    content. The standard covers financial information
                    transferred between financial institutions that includes
                    payment transactions, securities trading and settlement
                    information, credit and debit card transactions and other
                    financial information. <br />
                  </p>
                  <p>
                    The repository contains a huge amount of financial services
                    metadata that has been shared and standardized across the
                    industry. The metadata is stored in UML models with a
                    special ISO 20022 UML Profile. Underlying all of this is the
                    ISO 20022 metamodel - a model of the models. The UML profile
                    is the metamodel transformed into UML. The metadata is
                    transformed into the syntax of messages used in financial
                    networks. The first syntax supported for messages was XML
                    Schema.
                    <br />
                  </p>
                  <h3 className="text-green blog-sub-title-h3">
                    What are the benefits of ISO 20022 and how does it differ
                    from MT?
                  </h3>
                  <p>
                    When exchanging financial information, including via MT
                    messages, specialists in different domains or countries have
                    developed their own jargon and can use different words to
                    refer to the same concept. The ISO 20022 standard can help
                    to overcome barriers linked to the use of these different
                    syntaxes and semantics. It is a global, open standard that
                    describes a modelling methodology to capture – in a syntax
                    independent way – financial business areas, business
                    transactions and associated message flows.
                    <br />
                  </p>
                  <p>
                    The ISO 20022 methodology starts with the definition of the
                    business model which is a central dictionary of commonly
                    agreed business items used in financial communications. The
                    second layer builds on the first by defining logical
                    messages using the previously defined business concepts.
                    Within these, individual elements, such as ‘Interbank
                    Settlement Amount’ and ‘Interbank Settlement Date’ can be
                    reused. Finally, the syntax (physical representation) of the
                    logical message will be specified. <br />
                  </p>
                  <p>
                    Thanks to its structured and richer data elements, ISO 20022
                    enables counterparties, intermediaries, and beneficiaries to
                    increase automation in transaction processing, reducing
                    costly manual interventions, and improving visibility on
                    cash flows and cash positions, for example, in payment
                    transactions. It provides banks with better insight into the
                    business purpose of a financial transaction, enabling the
                    provision of improved value-added services. <br />
                  </p>
                  <p>
                    Businesses, as payment users, can gain more insight from
                    their payments data into the behaviours and choices of their
                    customers, as more complete and accurate party data leads to
                    more effective and efficient screening, compliance, and
                    anti-money laundering (AML) processes. Lastly, payments
                    scenarios such as ‘Payment on Behalf of’ (POBO) can be fully
                    supported, avoiding complex workarounds.
                    <br />
                  </p>
                  <h3 className="text-green blog-sub-title-h3">
                    How does ISO 20022 organise financial message definitions?
                  </h3>
                  <p>
                    ISO 20022 is a standards development methodology used by
                    multiple business domains. These include payments clearing
                    and settlement, payment initiation, cash management,
                    securities trades and settlement, account management and
                    trade services management. <br />
                  </p>
                  <p>
                    The Swift community has approved the adoption of ISO 20022
                    for cross-border payments and reporting (CBPR+) messages,
                    starting March 2023, for general availability, and from
                    August 2022 (on an opt-in basis). This is the focus of the
                    ISO 20022 programme, encompassing standards market
                    practices, translation rules and services, and customer and
                    vendor awareness campaigns. The aim is to ensure financial
                    institutions can send ISO 20022 messages from November 2022,
                    whatever the format preference of the other banks in the
                    payment chain. <br />
                  </p>
                  <p>
                    Payment market infrastructures, including major reserve
                    currencies such as EUR, USD, GBP, SGD, and AUD, are also
                    adopting ISO 20022 and are committed to go-live by the end
                    of 2023. Swift encourages and supports the adoption of ISO
                    20022 (HVPS+) by instant and real-time gross settlement
                    systems to align with the richer format of cross-border
                    payments. <br />
                  </p>
                  <p>
                    We are also helping to enable other market initiatives,
                    including the SRDII and CSDR European regulatory
                    requirements, to complement ISO 15022-based messages in
                    securities. Other business domains will continue to use
                    existing standards (FIN/MTs), including corporate to bank,
                    securities, trade, forex and market infrastructures. <br />
                  </p>
                  <h3 className="text-green blog-sub-title-h3">
                    What is CBPR+?
                  </h3>
                  <p>
                    Cross-border Payments and Reporting Plus (CBPR+) are the
                    usage guidelines that define how ISO 20022 is used for
                    Cross-border Payments and Reporting Plus (CBPR+) is the
                    usage guidelines that define how ISO 20022 will be used for
                    correspondent banking on the Swift network. The CBPR+
                    working group has created a collection of usage guidelines,
                    covering payment, clearing and settlement (pacs) and cash
                    management (camt) messages. Translation rules have also been
                    created for selected messages in this collection, related to
                    the equivalent Swift MT Category 1, 2 and 9 sets of
                    messages. <br />
                  </p>
                  <p>
                    The usage guidelines are publicly available on MyStandards
                    and include downloadable XML Schema, PDF and Excel
                    specifications. The MT CBPR+ translation rules and logic are
                    available on the CBPR+ translation portal.
                    <br />
                  </p>
                  <h3 className="text-green blog-sub-title-h3">
                    What is HVPS+?
                  </h3>
                  <p>
                    High-Value Payments Plus (HVPS+) are the usage guidelines
                    which originated as part of the ISO 20022 harmonization
                    High-Value Payments Plus (HVPS+) is a usage guideline
                    template which originated as part of the ISO 20022
                    harmonization initiative. It defines how ISO 20022 should be
                    used for high-value payments market infrastructures. This
                    group has specified usage guidelines that market
                    infrastructures should use with their participants. <br />
                  </p>
                  <p>
                    The HVPS+ usage guidelines are publicly available on
                    MyStandards, and cover payment, clearing and settlement
                    (pacs) and cash management (camt) messages. To ensure
                    consistent usage and interoperability, ISO 20022 guidelines
                    for HVPS+ and CBPR+ are fully aligned, allowing for minor
                    business differences, such as settlement method.
                    <br />
                  </p>
                  <h3 className="text-green blog-sub-title-h3">
                    When will MT messages for cross-border payments and
                    reporting be decommissioned?
                  </h3>
                  <p>
                    MT messages supporting cross-border payments and reporting
                    transactions will be decommissioned in November 2025. The
                    coexistence period, starting from 20 March 2023, will be
                    facilitated by the In-flow Translation service, a central
                    translation service providing interoperability between the
                    ISO 20022 and MT formats. <br />
                  </p>
                  <h3 className="text-green blog-sub-title-h3">
                    Will there be any standard release for MT and MX from now
                    until the decommissioning of MT in November 2025?
                  </h3>
                  <p>
                    As per Swift board paper IR864, "no further maintenance is
                    planned for MT category 1, 2, or 9 messages (so no ongoing
                    maintenance for FIN/MT payments applications) <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-1 col-md-2">
                <div className="blog-social-fixed">
                  <ul>
                    <li>
                      <span className="social-media">
                        <img src={shareIcon} />
                      </span>
                    </li>
                    <li>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={blog.title}>
                        <img src={fbImg} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton url={window.location.href}>
                        <img src={inImg} />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={window.location.href}>
                        <img src={twImg} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <span className="social-media">
                        <img src={copyImg} onClick={copyToClip} />
                        <ToastContainer
                          position="bottom-right"
                          autoClose={5000}
                          hideProgressBar
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="blog-related">
              <div className="row">
                <h2>Related blogs</h2>

                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/cbdc"}>
                    <img src={blogCart1} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 15, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/cbdc"}>
                      Central Bank Digital Currency
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    Central bank digital currencies (CBDCs) are a form of
                    digital...
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/efficient-system-for-payment"}>
                    <img src={blogCart2} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 22, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/efficient-system-for-payment"}>
                      Building an efficient system...
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    We know that cross-border payments have already become quick
                    and...
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/blog-single"}>
                    <img src={blogCart3} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 29, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/blog-single"}>
                      Neo Banks vs Traditional Banks
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    The Neo-banks are changing the canvas of fin-tech by rapidly
                    bridging...
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bg">
          {/* <section className="testimonial_section"> */}
          <div className="container scale_business_section contact-subtribe">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <SubscribeComponent 
                      heading={<><span>Start crossing borders with ease.</span>
                        <br></br> Unlock a world of possibilities for your business
                        with just a few clicks.</>}
                      subheading={<p className="text-white mtb">Join our community today.</p>}
                      showVerticle={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </section> */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default BlogSingleIso;
