import React from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import { CONTACT } from "../../constant";
// import { REGISTERURL } from "../../constant";
import companyImg from "../../assets/images/incorporation/image_1.png";
import processA from "../../assets/images/incorporation/process-1.png";
import processB from "../../assets/images/incorporation/process-2.png";
import processC from "../../assets/images/incorporation/process-3.png";
import processD from "../../assets/images/incorporation/process-4.png";
import worldGif from "../../assets/images/incorporation/world-map.gif";
import worldGifMob from "../../assets/images/incorporation/map-mob.png";
import listA from "../../assets/images/incorporation/list-a.svg";
import listB from "../../assets/images/incorporation/list-b.svg";
import listC from "../../assets/images/incorporation/list-c.svg";
import processMob from "../../assets/images/incorporation/process-mob.svg";

function Incorporation() {
    return (
        <>
            <div className="wrapper contact_page color_change incorporation" data-color="black" style={{ 'transition': "background-color 1s ease" }}>
                <div className="background_shadow">
                    <HeaderMenu />
                    <section className="section_spacing background_img">
                        <div className="subpage_banner_section banner_section ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="subpage_banner_section">
                                            <p className="text-lightgeeen text-start banner_p l-space-2 mt-80 mb-29">INCORPORATION AND CORPORATE SUPPORT</p>
                                            <h1 className="mx-auto text-start banner_h1 mb-34">ideate, <br />build,<br /><span className="text-green"> succeed.</span></h1>
                                            <h4 className="text-start banner_h3 text-white mb-100">Launch your business from anywhere in the
                                                world. Let our experienced partners assist you
                                                in  realizing your dreams.</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 offset-lg-1">
                                        <div className="inc-bnr-img ">
                                            {/* <img src={companyImg} alt="..." className="img-fluid" /> */}
                                            <video
										class="force-autoplay" width="100%" playsinline="" autoplay=""  loop
										muted=""  disablepictureinpicture=""
									>
										<source src="../images/incorporation/incorporation.mov" type="video/mp4; codecs=&quot;hvc1&quot;"></source>
                                        <source src="../images/incorporation/incorporation.webm" ></source>
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section className="incorp-2-section">
                    <div className="row gx-0">
                        <div className="col-xl-5 col-lg-12 col-md-12">
                            <div className="cv-banner-info child-1 h-100  justify-content-end">
                                <h3>Empowering your
                                    business from inception
                                    to success.</h3>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-12 col-md-12">
                            <div className="row gx-0 d-none d-lg-flex" style={{ backgroundColor: "#1A211F" }}>
                                <div className="col-lg-5">
                                    <div className="incop-info child-2 h-100 justify-content-start">
                                        <ul className="nav" >
                                            <li className="nav-item">
                                                <a href="#effortless" className="nav-link active" data-bs-toggle="tab"> <h6>Effortless and smooth</h6></a>
                                            </li>
                                            <hr />
                                            <li className="nav-item">
                                                <a href="#expert" className="nav-link" data-bs-toggle="tab"> <h6>Expert guidance</h6></a>
                                            </li>
                                            <hr />
                                            <li className="nav-item">
                                                <a href="#competitive" className="nav-link" data-bs-toggle="tab"><h6>Competitive price</h6></a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="incorp-info child-2 h-100 justify-content-start d-flex align-items-center incorp-text">
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="effortless">
                                                <p className="mb-0">
                                                    Submit your documents easily from any part of the world. Registration is completely online. We offer ongoing support to ensure that your business stays compliant with all of the legal requirements in your jurisdiction.</p>
                                            </div>
                                            <div className="tab-pane fade" id="expert">
                                                <p className="mb-0">Whether you are incorporating a new business or restructuring an existing one, our experienced partners can guide you through the incorporation process, provide professional advice, and help you make informed decisions.</p>
                                            </div>
                                            <div className="tab-pane fade" id="competitive">
                                                <p className="mb-0">Our partners work lightening fast and guide you during the entire process to get your business up and running in just a few days. We offer competitive and economical pricing for all services with no hidden fees.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="mob-tab d-block d-lg-none" style={{ backgroundColor: "#1A211F" }}>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                Effortless and smooth
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">

                                                Submit your documents easily from any part of the world. Registration is completely online. We offer ongoing support to ensure that your business stays compliant with all of the legal requirements in your jurisdiction.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Expert guidance
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Whether you are incorporating a new business or restructuring an existing one, our experienced partners can guide you through the incorporation process, provide professional advice, and help you make informed decisions.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Competitive price
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                Our partners work lightening fast and guide you during the entire process to get your business up and running in just a few days. We offer competitive and economical pricing for all services with no hidden fees.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
              
                <section className="IP-incorporation-page" id="contact-form-section">
                    <div className="container mt-146">
                        <div className="row process-outer">
                            <div className="col-xl-12 text-center">
                                <h3 className="mx-auto text-white "><span className="text-green">Simplifying </span>company incorporation</h3>
                                <p>Empower your business dreams and let our experienced partners handle incorporation for you.<br></br> Our 100% online process ensures a seamless and hassle-free experience.</p>
                            </div>
                            <div className="row gx-2 d-none d-md-flex">
                                <div className="col-md-3">
                                    <img src={processA} alt="..." className="w-100" />
                                    <p className="text-center">1. Select your country
                                        <br className="d-none d-xl-block d-md-block" />  of incorporation</p>
                                </div>
                                <div className="col-md-3">
                                    <img src={processB} alt="..." className="w-100" />
                                    <p className="text-center">2. Get guided assistance
                                        <br className="d-none d-xl-block d-md-block" /> from an expert</p>
                                </div>
                                <div className="col-md-3">
                                    <img src={processC} alt="..." className="w-100" />
                                    <p className="text-center">3. Your company
                                        is  <br className="d-none d-xl-block d-md-block" />incorporated</p>
                                </div>
                                <div className="col-md-3">
                                    <img src={processD} alt="..." className="w-100" />
                                    <p className="text-center">4. Open a PayStreet
                                        <br className="d-none d-xl-block d-md-block" /> account*
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 d-block d-md-none text-center">
                                <img src={processMob} className="process-mob-img" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="IP-incorporation-page" id="contact-form-section">
                    <div className="container mt-146">
                        <div className="row gx-2">
                            <div className="col-xl-12">
                                <h3 className="text-center text-white">Take your business to the next <span className="text-green">level</span></h3>
                                <p className="text-center">From A to Z: Incorporate like a pro. We have got you covered in these countries
                                </p>
                                <div className="mt-5">
                                    <img src={worldGif} alt="..." className="img-fluid d-none d-md-block" />
                                    <img src={worldGifMob} alt="..." className="img-fluid d-block d-md-none" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="incorporation-business-section">
                    <div className="container mt-146">
                        <div className="row">
                            <div className="col-xl-12">
                                <h3 className="business-heading"><span className="text-green">Continuous </span> support even after launching your business</h3>
                                <p className="">From Incorporation and accounting services to audit and tax filings, our partners are there to help you at every stage of your corporate life cycle.
                                </p>
                            </div>
                            <div className="col-xl-12  mt-5">
                                <div className="row">
                                    <div className="col-lg-7 col-sm-12 business-left-section">
                                        <ul>
                                            <li>
                                                <div className="icon-box">
                                                    <img src={listA} alt="..." className="img-fluid" />
                                                </div>
                                                <div className="text-box">
                                                    <h2 className="text-white">Company formation documents</h2>
                                                    <p className="text-white mt-3">Get documents like business profile, bylaws, operating
                                                        agreement, stock certificates etc. for operating your business.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon-box">
                                                    <img src={listB} alt="..." className="img-fluid" />
                                                </div>
                                                <div className="text-box">
                                                    <h2 className="text-white">Accounting and tax filing services</h2>
                                                    <p className="text-white mt-3">Our partners provide 360 degree assistance from bookkeeping
                                                        and financial reporting to tax preparation and filing.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon-box">
                                                    <img src={listC} alt="..." className="img-fluid" />
                                                </div>
                                                <div className="text-box">
                                                    <h2 className="text-white">Expert consultation</h2>
                                                    <p className="text-white mt-3">From strategic advice to legal planning, we provide advice
                                                        tailored to your needs so that you make an informed decision</p>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="col-lg-5 col-sm-12">
                                        <div className="mt-2">
                                        <object type="image/svg+xml" data="../images/incorporation/inc.svg"></object>
                                            {/* <video width="100%" muted autoPlay loop >
                                                <source src="../images/incorporation/business-img.mov" type="video/mp4; codecs=&quot;hvc1&quot;" />
                                                <source src="../images/incorporation/business-img.webm" type="video/webm" />
                                                Sorry, your browser doesn't support embedded videos.
                                            </video> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="footer-bg">
                    <div className="scale_business_section">
                        <div className="container">
                            <div className="row">
                                <h2><span>Get ahead of the game.</span>
                                </h2>
                                <h2>Build the foundation of your success with us.</h2>
                                <p className="text-white text-start mtb">Choose us for your business needs. Incorporate with our help today.</p>
                                <div className="tab-center mob-center">
                                    <a href={CONTACT}><button type="button" className="btn button_white me-4">Talk to us
                                        <i className="fal fa-long-arrow-right"></i>
                                    </button></a>
                                    <p className="text-white text-start mt-5 mb-5 " style={{fontSize:"12px", opacity:".5"}}><em>*Upon approval from the compliance team ensuring that all the requirements have been met. </em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default Incorporation;