import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ReactGA from 'react-ga'
import Home from "./views/Pages/Home";
import Contact from "./views/Pages/Contact";
import Solution from "./views/Pages/Solution";
import Convert from "./views/Pages/Convert";
import B2BCrossBorder from "./views/Pages/B2BCrossBorder";
import Send from "./views/Pages/Send";
import SendMoney from "./views/Pages/SendMoney";
import TermsConditions from "./views/Pages/TermsConditions";
import FaqPage from "./views/Pages/FaqPage";
import AboutUs from "./views/Pages/AboutUs";
import Incorporation from "./views/Pages/Incorporation";
import CollectMoney from "./views/Pages/CollectMoney";
import HeaderMenu from "./views/partials/HeaderMenu";
import Termcondition2 from "./views/Pages/Termcondition2";
import StoreMoney from "./views/Pages/StoreMoney";
import Blog from "./views/Pages/Blog";
import BlogSingleIso from "./views/Pages/BlogSingle-iso";
import BlogSinglecbdc from "./views/Pages/BlogSingle-cbdc";
import BlogSinglebuildingPayments from "./views/Pages/BlogSingle-building-payments";
import BlogSingle from "./views/Pages/BlogSingle";
import SiteMap from "./views/Pages/SiteMap";
import Privacypolicy from "./views/Pages/Privacypolicy";
import HelmentComponents from "./views/components/HelmentComponents";
import TagManager from "react-gtm-module";

function App() {
    const location = useLocation()

    useEffect(() => {
        const pageURL = window.location.href
        const test = pageURL.includes('https://paystreet.io/')
        if (test){
            TagManager.initialize({
                gtmId: 'GTM-PNDBCXSN',
            });
            ReactGA.initialize('G-R6BZBTBHSS')
            // Track page view on route change
            TagManager.dataLayer({
                dataLayer: {
                    pagePath: location.pathname + location.search,
                },
            });
        }
    }, [location]);

    return (
        <>
            <HelmentComponents />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/solution" element={<Solution />} />
                <Route path="/b2b" element={<B2BCrossBorder />} />
                <Route path="/convert" element={<Convert />} />
                <Route path="/send" element={<Send />} />
                <Route path="/sendmoney" element={<SendMoney />} />
                <Route path="/term" element={<TermsConditions />} />
                <Route path="/faq" element={<FaqPage />} />
                <Route path="/other" element={<HeaderMenu />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/incorporation" element={<Incorporation />} />
                <Route path="/collect" element={<CollectMoney />} />
                <Route path="/termandconditions" element={<Termcondition2 />} />
                <Route path="/store" element={<StoreMoney />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog-single" element={<BlogSingle />} />
                <Route path="/efficient-system-for-payment" element={<BlogSinglebuildingPayments />} />
                <Route path="/iso-20022" element={<BlogSingleIso />} />
                <Route path="/cbdc" element={<BlogSinglecbdc />} />
                <Route path="/sitemap" element={<SiteMap />} />
                <Route path="/privacy-policy" element={<Privacypolicy />} />
            </Routes>
        </>
    );
}

export default App;
