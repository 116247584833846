import React, { useState } from "react";
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
import { useParams } from 'react-router-dom';

function SiteMap() {
    return (
        <>
            <div className="color-tnc-indigo sitemap"  data-color="indigo">
                <div className="wrapper-lite site-map-page">
                    <HeaderMenuLite />
                    <div className="container">
                        <div className="site-map-banner">
                            <p className=" text-center text-green mt-80 l-space-2">SITEMAP</p>
                        </div>
                        <div className="site-content-single ">
                        <div className="row pt-5 pb-3">
                                <div className="col-lg-3 col-12">
                                    <div className="site-map-menu-title">
                                        Products
                                    </div>
                                </div>
                                <div className="col-lg-9 col-12">
                                    <div className="site-map-submenu-title">
                                        <ul className="mb-0">
                                            <li><a href={"/b2b"}>Payments platform</a> <span>|</span>  <a href={"/incorporation"}>Corporate support</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-3">
                                <div className="col-lg-3 col-12">
                                    <div className="site-map-menu-title">
                                        Solutions
                                    </div>
                                </div>
                                <div className="col-lg-9 col-12">
                                    <div className="site-map-submenu-title">
                                        <ul className="mb-0">
                                            <li><a href={"/sendmoney"}>Send</a> <span>|</span><a href={"/collect"}>Collect</a><span>|</span><a href={"/convert"}>Convert</a><span>|</span>  <a href={"/store"}>Store</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-3">
                                <div className="col-lg-3 col-12">
                                    <div className="site-map-menu-title">
                                        Company
                                    </div>
                                </div>
                                <div className="col-lg-9 col-12">
                                    <div className="site-map-submenu-title">
                                        <ul className="mb-0">
                                            <li><a href={"/about-us"}>About PayStreet</a><span>|</span>
                                            <a href={"/about-us#mission"}>Mission</a><span>|</span>
                                                <a href={"/about-us#why-us-section"}>Why us?</a><span>|</span>
                                                <a href={"/about-us#ethos-scroll-section"}>Ethos</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row py-3">
                                <div className="col-lg-3 col-12">
                                    <div className="site-map-menu-title">
                                        Resources
                                    </div>
                                </div>
                                <div className="col-lg-9 col-12">
                                    <div className="site-map-submenu-title">
                                        <ul className="mb-0">
                                            <li><a href={"/blog"}>Blog and News</a> <span>|</span> <a href={"/faq"}>FAQs</a> <span>|</span> <a href={"/#"}>Guide</a> <span>|</span><a href={"/contact"}>Contact us</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row py-3">
                                <div className="col-lg-3 col-12">
                                    <div className="site-map-menu-title">
                                        Other links
                                    </div>
                                </div>
                                <div className="col-lg-9 col-12">
                                    <div className="site-map-submenu-title">
                                        <ul className="mb-0">
                                            <li><a href={"/privacy-policy"}>Privacy Policy</a> <span>|</span> <a href={"/termandconditions"}>Terms and Conditions</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bg">
                    
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default SiteMap;