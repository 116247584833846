import React, { useEffect, useState } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

export const Laptop = () => {
    const [width, setWidth] = useState();
    const [parallaxAttr1, setParallaxAttr1] = useState({});
    const [parallaxAttr2, setParallaxAttr2] = useState({});
    const [parallaxAttr3, setParallaxAttr3] = useState({});
    const [parallaxAttr4, setParallaxAttr4] = useState({});
    const [parallaxAttr5, setParallaxAttr5] = useState({});
    const [parallaxAttr6, setParallaxAttr6] = useState({});
    const [parallaxAttr7, setParallaxAttr7] = useState({});

    useEffect(() => {
        setWidth(window.innerWidth);
        if(window.innerWidth >= 1920) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-55, -10], y: [-40, -50, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1200, -700, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [80, 10], y: [-700, -250, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [150, 30], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-500, 80], scale: [2, 0.8, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        } else if(window.innerWidth >= 1600) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-300, -48], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-55, -10], y: [-40, -50, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1200, -700, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [80, 10], y: [-700, -250, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [150, 30], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-500, 100], scale: [1.5, 0.8, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        } else if(window.innerWidth >= 1440) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-300, -48], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-55, -10], y: [-40, -50, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1200, -700, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [80, 10], y: [-700, -250, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [150, 30], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-500, 100], scale: [1.5, 0.8, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        } else if(window.innerWidth >= 1360) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-800, -50], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-70, -25], y: [10, -10, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1200, -700, 'easeOutQuint'], scale: [0, 1, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [100, 10], y: [-700, -250, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [250, 30], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-500, 100], scale: [1.1, 0.8, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        }else if(window.innerWidth >= 1280) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-300, -48], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-55, -10], y: [-40, -50, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1200, -700, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [80, 10], y: [-700, -250, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [150, 30], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-500, 100], scale: [1, 0.7, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        }else if(window.innerWidth >= 1200) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-300, -48], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-55, -10], y: [-40, -50, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1200, -700, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [80, 10], y: [-700, -250, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [150, 30], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-500, 100], scale: [1, 0.7, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        }else if(window.innerWidth >= 992) {
            setParallaxAttr1({x: [-450, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-700, -48], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-80, -20], y: [-40, -20, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1200, -700, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [80, 10], y: [-600, -300, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [300, 30], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-500, 100], scale: [1, 0.6, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        }else if(window.innerWidth >= 768) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-600, -60], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-110, -10], y: [-40, -50, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1000, -750, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [120, 10], y: [-700, -250, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [200, 50], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-750, 100], scale: [1, 0.6, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        }else if(window.innerWidth >= 560) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-300, -48], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-35, -10], y: [-40, -50, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [80, 0], y: [-1200, -700, 'easeOutQuint'], scale: [0, 0.8, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [80, 10], y: [-700, -250, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [150, 30], y: [-1000, -100, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-500, 100], scale: [1, 0.6, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        }else if(window.innerWidth >= 375) {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-300, -60], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-65, -10], y: [-20, -40, 'easeOutQuint'], scale: [0, 0.5, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [200, 0], y: [-2000, -470, 'easeOutQuint'], scale: [0, 0.5, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [150, -20], y: [-780, -250, 'easeOutQuint'], scale: [0, 0.3, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [150, 30], y: [-1000, -250, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-950, 100], scale: [1, 0.6, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        } else {
            setParallaxAttr1({x: [-300, -45], y: [-1000, 80, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr2({x: [-300, -60], y: [-3500, 150, "easeOutQuint"], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr3({x: [-65, -10], y: [-20, -40, 'easeOutQuint'], scale: [0, 0.5, 'easeOutBack'], startScroll: '', endScroll: ''});
            setParallaxAttr4({x: [200, 0], y: [-2000, -470, 'easeOutQuint'], scale: [0, 0.5, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr5({x: [150, -20], y: [-780, -250, 'easeOutQuint'], scale: [0, 0.3, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr6({x: [150, 30], y: [-1000, -250, 'easeOutQuint'], scale: [0, 0.7, 'easeOutBack'], startScroll: '', endScroll: '', speed: -10});
            setParallaxAttr7({x: [0, 0], y: [-700, 100], scale: [1, 0.6, 'easeOutBack'], startScroll: '500', endScroll: '1000'});
        }

        window.addEventListener("resize", () => setWidth(window.innerWidth));
      }, []);
    return (
        <div style={{position: "relative"}}>
            <div className="cross-borden-animation">
                <ParallaxProvider >
                    <Parallax 
                        translateX={parallaxAttr1.x} 
                        translateY={parallaxAttr1.y}
                        scale={parallaxAttr1.scale}
                    >
                        <img src="/images/laptop/tab1.png" className="img-fluid w-40" alt="..."/>
                    </Parallax>
                    <Parallax 
                        translateX={parallaxAttr2.x} 
                        translateY={parallaxAttr2.y}
                        scale={parallaxAttr2.scale}
                    >
                        <img src="/images/laptop/tab1.png" className="img-fluid w-40" alt="..."/>
                    </Parallax>
                    <Parallax 
                        translateX={parallaxAttr3.x} 
                        translateY={parallaxAttr3.y} 
                        scale={parallaxAttr3.scale}
                    >
                        <img src="/images/laptop/wallet.png" className="img-fluid w-40" alt="..."/>
                    </Parallax>
                    <Parallax 
                        translateX={parallaxAttr4.x} 
                        translateY={parallaxAttr4.y} 
                        scale={parallaxAttr4.scale}
                        speed={parallaxAttr4.speed}
                    >
                        <img src="/images/laptop/wallet2.png" className="img-fluid w-40" alt="..."/>
                    </Parallax>
                    <Parallax 
                        translateX={parallaxAttr5.x} 
                        translateY={parallaxAttr5.y} 
                        scale={parallaxAttr5.scale}
                        speed={parallaxAttr5.speed}
                    >
                        <img src="/images/laptop/convert.png" className="img-fluid w-40" alt="..."/>
                    </Parallax>
                    <Parallax 
                        translateX={parallaxAttr6.x} 
                        translateY={parallaxAttr6.y} 
                        scale={parallaxAttr6.scale}
                        speed={parallaxAttr6.speed}
                    >
                        <img src="/images/laptop/tab4.png" className="img-fluid w-40" alt="..."/>
                    </Parallax>
                    <Parallax 
                        translateX={parallaxAttr7.x}
                        translateY={parallaxAttr7.y}
                        scale={parallaxAttr7.scale}
                        startScroll={parallaxAttr7.startScroll}
                        endScroll={parallaxAttr7.endScroll}
                    >
                        <img src="images/laptop/laptop.png" className="img-fluid w-60" alt="..."/>
                    </Parallax>
                </ParallaxProvider>
            </div>
        </div>
  )
}
