import React from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import { REGISTERURL } from "../../constant";
import collectMoney from "../../assets/images/solution/collect/collect_money.png";
import collectMobile from "../../assets/images/solution/collect/collect_mobile.png";
import collectA from "../../assets/images/solution/collect/collect_1.svg";
import collectB from "../../assets/images/solution/collect/collect_2.svg";
import collectC from "../../assets/images/solution/collect/collect_3.svg";
import collectD from "../../assets/images/solution/collect/collect_4.svg";

function CollectMoney() {
    return (
        <>
            <div className="wrapper mobile_responsive collect-money-page color_change" data-color="black" style={{ 'transition': "background-color 1s ease" }}>
                <div className="background_shadow  background_img ">
                    <HeaderMenu />
                    <section className="section_spacing">
                        <div className="subpage_banner_section banner_section ">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="subpage_banner_section">
                                            <p className="text-lightgeeen text-start banner_p l-space-2  mb-29">Collect Money</p>
                                            <h1 className="mx-auto text-start banner_h1 mmb-0">Business is <span className="text-green"> local.</span></h1>
                                            <h1 className="mx-auto text-start banner_h1 mb-34">Money is <span className="text-green"> global.</span></h1>
                                            <h3 className="text-start banner_h3 text-white">Collect money from across the border or locally into unique IBANs under your
                                                business name.</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 offset-lg-1 text-center">
                                        <div className="">
                                            <video class="force-autoplay" id="connect-tools-video" width="100%" playsinline=""
                                                muted="" autoplay="" loop disablepictureinpicture="">
                                                <source src="../images/collect/collectmoney.mov" type="video/mp4; codecs=&quot;hvc1&quot;"></source>
                                                <source src="../images/collect/collectmoney.webm" type="video/webm" ></source>
                                                Sorry, your browser doesn't support embedded videos.
                                            </video> 
                                            {/* <video class="force-autoplay" id="connect-tools-video" width="100%" playsinline=""
                                                muted="" autoplay="" loop disablepictureinpicture="">
                                                <source src="../images/send/worldmoney.mov" type="video/mp4; codecs=&quot;hvc1&quot;"></source>
                                                <source src="../images/send/worldmoney.webm" type="video/webm" ></source>
                                                Sorry, your browser doesn't support embedded videos.
                                            </video> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section className="better-acc">
                    <div className="left-sec"><img src="/images/collect/collect_2.jpg" alt="..." className="img-fluid" /></div>
                    <div className="better-acc-text"> <h2 className="text-white">A better account for a better
                        business.</h2>
                        <p className="text-white mb-0">
                            Get deposits in your customer's local currency.
                            Avoid expensive transaction fees and conversion
                            costs.
                        </p></div>
                    {/* <div className="container">
                  <div className="row ">
                        <div className="col-lg-6">
                            <img src={collectMobile} alt="..." className="img-fluid" />
                        </div>
                        <div className="col-lg-6">
                            <div className="collect-2-section">
                               
                            </div>
                        </div>

                    </div>
                  </div> */}
                </section>

                <section className="collect-3-money">
                    <div className="container mt-146 collect-money-timeline">
                        <div className="row">
                            <div className="col-xl-12 ">
                                <h2 className="text-white page_sub_title mb_35">
                                    Create a bank account in <span className="text-green">seconds.</span><br /> Collect anytime anywhere in 37 currencies.
                                </h2>
                            </div>
                            <div className="col-lg-12">
                                <ul className="timeline">
                                    <li className="mt-5">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="timeline-left-padding">
                                                    <h1 className="text-lightgeeen text-white">01</h1>
                                                    <h5 className="text-white">Apply for a business account
                                                        on PayStreet</h5>
                                                    <p>Get access to one or many global wallets in currencies like
                                                        USD, GBP, EUR, SGD and many more!
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 offset-md-1 timeline-image">
                                                <img src="/images/collect/collect-1.svg" alt="..." className="img-fluid" />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="mt-5">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="timeline-left-padding">
                                                    <h1 className="text-lightgeeen text-white">02</h1>
                                                    <h5 className="text-white"> Get IBANs under your business name.
                                                    </h5>
                                                    <p> Conveniently share your account details with corporate clients. Users within the PayStreet network would in some time get unique QR codes embedded with their account details.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 offset-md-1 timeline-image">
                                                <img src="/images/collect/collect-2.svg" alt="..." className="img-fluid" />
                                            </div>
                                        </div>
                                    </li>
                                    <li className="mt-5">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="timeline-left-padding">
                                                    <h1 className="text-lightgeeen text-white">03</h1>
                                                    <h5 className="text-white">Receive funds in virtual collection
                                                        accounts</h5>
                                                    <p>Collect money either locally or through the SWIFT network. Incoming
                                                        funds are pooled automatically into your currency wallet for easy
                                                        reconciliation and reporting.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 offset-md-1 timeline-image">
                                                <img src="/images/collect/collect-3.svg" alt="..." className="img-fluid" />
                                            </div>
                                        </div>

                                    </li>
                                    <li className="mt-5">
                                        <div className="row">
                                            <div className="col-md-5 ">
                                                <div className="timeline-left-padding">
                                                    <h1 className="text-lightgeeen text-white">04</h1>
                                                    <h5 className="text-white">Manage your finances conveniently</h5>
                                                    <p>Scale globally. Send, convert or hold money. Your funds remain safe with
                                                        PayStreet.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 offset-md-1 ">
                                                <img src="/images/collect/collect-4.svg" alt="..." className="img-fluid mb-" />
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="mobile_responsive color_change collect-money-white" id="smart-toolsets" data-color="indigo" style={{ 'transition': 'background-color 1s ease' }}>
                    <div className="py-245">
                        <div className="container">
                            <div className="container smart_toolsets_content">
                                <p className="green_small_text">TAKE YOUR BUSINESS WORLDWIDE</p>
                                <h2 className="smart_toolsets_content_title">Get IBANs under your business name
                                    within seconds without any complex paper
                                    work.</h2>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text_box">
                                            <h2><span>Banking</span>  without the bank</h2>
                                            <p>Get a US routing number, a Euro IBAN, a UK sort code all at the
                                                <span> tap of a button</span>. No regulatory hassle or stressful delays. No
                                                local address required.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img_box d-flex justify-content-end ps-2">
                                            <img src={collectA} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                                        <div className="img_box pe-2">
                                            <img src={collectB} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                                        <div className="text_box">
                                            <h2>Collect from your corporate
                                                clients in their <span> preferred </span>
                                                currency.</h2>
                                            <p>Share your account details and start accepting deposits just like
                                                a bank with <span> bank level security.</span> No minimum balance required.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb_150 align-items-center">
                                    <div className="col-lg-6">
                                        <div className="text_box">
                                            <h2>Employ business capital
                                                more <span>productively</span></h2>
                                            <p>Any proceeds collected could be kept in virtual accounts
                                                without any account opening charge or annual fee. Spend
                                                money in the same currency in which it was collected and
                                                <span> avoid </span> unnecessary conversion fees.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="img_box d-flex justify-content-end ps-2">
                                            <img src={collectC} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row  align-items-center">
                                    <div className="col-lg-6 order-md-2 order-2 order-lg-1">
                                        <div className="img_box pe-2">
                                            <img src={collectD} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-md-1 order-1 order-lg-2">
                                        <div className="text_box">
                                            <h2>No <span> misuse </span> of your funds,
                                                ever!</h2>
                                            <p>Your funds remain yours. We never invest or lend them
                                                anywhere. Access them whenever you want. <span> Client segregated </span>
                                                funds and <span> escrow accounts </span> ensure safety against counter-party risk.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="footer-bg mobile_responsive color_change" data-color="black">
                <section className="container ">
                    <div className="scale_business_section">
                        <div className="container-fluid">
                            <div className="row">
                                <h2><span>Business banking, made easy.</span>
                                </h2>
                                <h2>Increase financial savings and open new revenue
                                    streams for your company.</h2>
                                <p className="text-white text-start mtb">Open an account within minutes.</p>
                                <div className="tab-center mob-center">
                                    <a href="/contact"><button type="button" className="btn button_white me-4">Talk to us
                                        <i className="fal fa-long-arrow-right"></i>
                                    </button></a>
                                    <a href={REGISTERURL}><button type="button" className="btn button_white get_started">Get started
                                        <i className="fal fa-long-arrow-right"></i>
                                    </button> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </div>
        </>
    );
}

export default CollectMoney;