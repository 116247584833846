import React from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";

function Send() {
	return (
        <>
			<div className="wrapper contact_page color_change" data-color="black" style={{ transition: "background-color 1s ease" }}>				
				<HeaderMenu />
				<section className="subpage  section_spacing">
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="subpage_banner_section">
									<p className="text-lightgeeen text-start banner_p l-space-2 mb-29">Convert</p>
									<h1 className="mx-auto text-start banner_h1 mb-34">Convert money for<span className="text-green"> less.</span> Trade <span className="text-green">confidently.</span></h1>
									<h3 className="text-start banner_h3 text-white ">Meet all your FX needs under one roof. We bring you the flexibility to convert into XX currencies at ease at competitive exchange rates.</h3>
								</div>
							</div>
							<div className="col-lg-5">
								<div className="convert-box ms-auto w-100" style={{maxWidth:'468px'}}>

									<div className="currencies_box d-flex justify-content-between">
										<div className="currencies_text">
											<p className="text-green mb-0">Convert</p>
											<p className="mb-0">$ 1,000.00</p>
										</div>
										<div className="Currencies_select">
											<span className="d-flex align-items-center">
												<img src="/images/convert-image-8.png" className="me-2" alt="..." />
												<select className="form-select border-0" aria-label="Default select example">
													<option selected="">USD</option>
													<option value="1">EUR</option>
												</select>
											</span>											
										</div>
									</div>

									<div>
										<ul className="calculat mb-0">
											<li className="fl-calculat">
												<div className="d-flex justify-content-between">
													<span className="text-white">4.00 USD</span><span>PayStreet fee</span>
												</div>
												<hr className="text-white m-0" ></hr>
											</li>
											<li className="sl-calculat">
												<div className="d-flex justify-content-between">
													<span className="text-white">996.00 USD</span><span>Amount we will convert</span>
												</div>
											</li>
											<li className="tl-calculat">
												<div className="d-flex justify-content-between">
													<span className="text-white"><span style={{color:'#61CE65'}}>0.92 EUR</span> = 1.00 USD</span><span>Mid-market rate</span>
												</div>
											</li>
										</ul>
									</div>

									<div className="currencies_box d-flex justify-content-between">
										<div className="currencies_text">
											<p className="text-green mb-0">TO</p>
											<p className="mb-0">€ 923.55</p>
										</div>
										<div className="Currencies_select">
											<span className="d-flex align-items-center">
												<img src="/images/convert-image-9.png" className="me-2" alt="..." />
												<select className="form-select border-0" aria-label="Default select example">
													<option selected="">EUR</option>
													<option value="1">USD</option>
												</select>
											</span>
										</div>
									</div>
									<div>
										<p className="text-center convert-box-footer">You could save vs banks XX.XX USD</p>
									</div>

								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="mb-321 ECI-section">
					<div className="row gx-0">
						<div className="col-lg-4">
							<div className="cv-banner-info child-1 h-100 d-flex justify-content-end">
								<div style={{maxWidth:'490px'}}>
									<img src="/images/convert-image-6.png" alt=".." />
										<h3>Empower your business</h3>
										<p>Get rid of inflated exchange rate markups or hidden fees and convert near mid-market rates.</p></div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="cv-banner-info child-2 h-100 d-flex justify-content-start">
								<div style={{ maxWidth: '490px' }}>
									<img src="images/convert-image-7.png" alt="..." />
										<h3>Customer first</h3>
										<p>The fee we earn is used to move money, develop better products and provide continuous support to you round the clock.</p></div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="cv-banner-info child-3 h-100 d-flex justify-content-start">
								<div style={{ maxWidth: '490px' }}>
									<img src="images/convert-image-6.png" alt="..." />
										<h3>Integrity</h3>
										<p>End-to-end encryption and multiple layers of security keep your data safe. We never share your information with anyone.</p></div>
							</div>
						</div>
					</div>
				</section>
				<section className="IP-page ">

					<div className="container mb_50">
						<h2 className="text-white text-center page_sub_title mb_35">Enjoy <span className="text-green">hassle free</span> conversion</h2>
						<p style={{ maxWidth: '907px', width: '100%', margin: 'auto' }}>Exchange money at best possible rates with PayStreet's transparent pricing. Hedge against currency fluctuation with our competitive forex services.</p>
					</div>
					<div className="d-flex flex-wrap justify-content-center mb_75">
						<div className="currencies-fees border-end border-2 border-secondary">
							<h3 className="text-lightgeeen text-center">0.4% Fees</h3>
							<p>xx currencies</p>
						</div>
						<div className="currencies-fees ">
							<h3 className="text-white  text-center">0.6% Fees</h3>
							<p>xx currencies</p>
						</div>
					</div>
				</section>
				<section className="pb-203 color-black">
					<div className=" Currencies_section">
						<div className="Currencies mb-5">
							<div className="Currencies_group">
								<div className="Currencies-pill">aud</div>
								<div className="Currencies-pill">bhd</div>
								<div className="Currencies-pill">bgn</div>
								<div className="Currencies-pill">cad</div>
								<div className="Currencies-pill">huf</div>
								<div className="Currencies-pill">czk</div>
								<div className="Currencies-pill">dkk</div>
								<div className="Currencies-pill">eur</div>
								<div className="Currencies-pill">huf</div>
							</div>
							<div aria-hidden="true" className="Currencies_group">
								<div className="Currencies-pill">aud</div>
								<div className="Currencies-pill">bhd</div>
								<div className="Currencies-pill">bgn</div>
								<div className="Currencies-pill">cad</div>
								<div className="Currencies-pill">huf</div>
								<div className="Currencies-pill">czk</div>
								<div className="Currencies-pill">dkk</div>
								<div className="Currencies-pill">eur</div>
								<div className="Currencies-pill">huf</div>
							</div>
						</div>
						<div className="Currencies mb-5">
							<div className="Currencies_group1">
								<div className="Currencies-pill">ils</div>
								<div className="Currencies-pill">kes</div>
								<div className="Currencies-pill">kwd</div>
								<div className="Currencies-pill">mxn</div>
								<div className="Currencies-pill">nzd</div>
								<div className="Currencies-pill">nik</div>
								<div className="Currencies-pill">omr</div>
								<div className="Currencies-pill">pln</div>
								<div className="Currencies-pill">qar</div>
							</div>
							<div aria-hidden="true" className="Currencies_group1">
								<div className="Currencies-pill">ils</div>
								<div className="Currencies-pill">kes</div>
								<div className="Currencies-pill">kwd</div>
								<div className="Currencies-pill">mxn</div>
								<div className="Currencies-pill">nzd</div>
								<div className="Currencies-pill">nik</div>
								<div className="Currencies-pill">omr</div>
								<div className="Currencies-pill">pln</div>
								<div className="Currencies-pill">qar</div>
							</div>
						</div>
						<div className="Currencies mb-5">
							<div className="Currencies_group2">

								<div className="Currencies-pill">qar</div>
								<div className="Currencies-pill">ils</div>
								<div className="Currencies-pill">kes</div>
								<div className="Currencies-pill">kwd</div>
								<div className="Currencies-pill">mxn</div>
								<div className="Currencies-pill">nzd</div>
								<div className="Currencies-pill">nik</div>
								<div className="Currencies-pill">omr</div>
								<div className="Currencies-pill">pln</div>
							</div>
							<div aria-hidden="true" className="Currencies_group2">
								<div className="Currencies-pill">qar</div>
								<div className="Currencies-pill">ils</div>
								<div className="Currencies-pill">kes</div>
								<div className="Currencies-pill">kwd</div>
								<div className="Currencies-pill">mxn</div>
								<div className="Currencies-pill">nzd</div>
								<div className="Currencies-pill">nik</div>
								<div className="Currencies-pill">omr</div>
								<div className="Currencies-pill">pln</div>
							</div>
						</div>
					</div>
				</section>
				<section className="py-242 currency_exchange">
					<div className="container position-relative" style={{zIndex:'1'}}>
						<h2 className="w-100 text-white lh-normal text-start fw-700 page_sub_title mb-54" style={{maxWidth:'949px'}}>Your gateway to the global currency exchange with simple and competitive FX solutions.</h2>
						<button className="btn convert-start-btn">Get started</button>
					</div>
				</section>
				<section className=" smart_toolsets color_change" id="smart-toolsets" data-color="indigo" >
					<div className="py-245">
						<div className="container-fluid">
							<div className="container smart_toolsets_content solution_page">
								<p className="green_small_text">FX MADE EASY</p>
								<h2 className="smart_toolsets_content_title">Beat your bank and convert at 9x less the cost. Make every dollar count.</h2>
								<div className="row mb_150 align-items-center">
									<div className="col-lg-6">
										<div className="text_box">
											<h2><span>Easy</span> and <span>instantaneous</span> FX settlement</h2>
											<p>Collect local or international payments in <span>IBANs under your company name.</span> Share your business account details and start accepting funds in multiple currencies just like a bank.</p>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="img_box ps-2 justify-content-end d-flex">
											<img src="images/convert-image-1.png" alt="..." className="img-fluid" />
										</div>
									</div>
								</div>
								<div className="row mb_150 align-items-center">
									<div className="col-lg-6 order-md-2 order-2 order-lg-1">
										<div className="img_box pe-2">
											<img src="images/convert-image-2.png" alt="..." className="img-fluid" />
										</div>
									</div>
									<div className="col-lg-6 order-md-1 order-1 order-lg-2">
										<div className="text_box">
											<h2>The <span>more</span> you trade, the <span>less</span> you pay.</h2>
											<p><span>Low and transparent fees</span> made available even to small enterprises. Extremely <span>low conversion markups</span> that allow inexpensive access to exchange rates at the interbank level.</p>
										</div>
									</div>
								</div>
								<div className="row  align-items-center">
									<div className="col-lg-6">
										<div className="text_box">
											<h2><span>Hedge risk</span> with our multi-currency management.</h2>
											<p>Experience the all-in-one account at your <span>fingertips</span> so you never lose control of your business activities wherever you go. Now, one tap away with iOS and Android <span>mobile app</span></p>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="img_box pe-2 justify-content-end d-flex">
											<img src="images/convert-image-3.png" alt="..." className="img-fluid" />
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</section>
				<div className="footer-bg">
					<section className=" ">
						<div className="scale_business_section">
							<div className="container">
								<div className="row">
									<h2 className="mb-44"><span>Pay less, trade more.</span><br></br> We aspire to provide you with the best FX solutions near mid-market rates.</h2>
									<p className="text-white mb-46">Join today. Open an account within minutes.</p>
									<div className="tab-center mob-center">
										<button type="button" className="btn button_white me-4">Talk to us 
											<img src="images/next_black.png" alt=".." className="img-fluid" />
										</button>
										<button type="button" className="btn button_white get_started">Get started 
											<img src="images/next_white.png" alt=".." className="img-fluid" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</section>
					<Footer />
				</div>
			</div>
		</>
	);
}

export default Send;