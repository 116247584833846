import React, { useEffect, useState } from "react";
import Select from "react-select";

function CountrySelect(props) {
  const [selectedOption, setSelectedOption] = useState(props.defaultvalue);
  // let isSearchable = true;

  const { handleClickSymbol } = props;
  const getCurrencyHanlder = (data) => {
    handleClickSymbol(props.type, data);
    setSelectedOption(data.code);
  };
  return (
    <Select
      className="currency-select"
      // menuIsOpen={true}
      options={props.currencyConvert}
      value={props.currencyConvert.find(
        (option) => option.code === selectedOption
      )}
      onChange={getCurrencyHanlder}
      isSearchable={true}
      formatOptionLabel={(tc) => (
        <>
          {tc.code && (
            <>
              {tc.country.iso2 && (
                <span className="country-flag">
                  <img
                    class="crypto-icon"
                    src={`./images/flags/${tc.country.iso2.toLowerCase()}.svg `}
                    alt="btc"
                    className="currency-img"
                    width="20px"
                  />
                </span>
              )}
              <span className="country-label">{`${tc.code}`}</span>
            </>
          )}
        </>
      )}
      getOptionLabel={(option) => option.code + option.name}
      {...props}
    />
  );
}

export default CountrySelect;
