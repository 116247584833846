import React, { useState, useEffect } from "react";
// import { HashLink } from 'react-router-hash-link';
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
import { useParams } from "react-router-dom";
import blogSlider from "../../assets/images/blog/neo-bank.png";
import blogCart1 from "../../assets/images/blog/blog-2.png";
import blogCart2 from "../../assets/images/blog/blog-3.png";
import blogCart3 from "../../assets/images/blog/bulding-payment.png";
import {
  FacebookShareButton,
  FacebookShareCount,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  LineShareButton,
} from "react-share";

import shareIcon from "../../assets/images/blog/share.svg";
import fbImg from "../../assets/images/blog/fb.svg";
import inImg from "../../assets/images/blog/in.svg";
import twImg from "../../assets/images/blog/tw.svg";
import mailImg from "../../assets/images/blog/mail.svg";
import copyImg from "../../assets/images/blog/copy.svg";
import { Helmet } from "react-helmet";
import { blogArr } from "./Blog-data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubscribeComponent from "../components/Subscribe/SubscribeComponent";

function BlogSingle() {
  const [blogsingle, setBlogsingle] = useState([]);

  var blog = [];
  const params = useParams();

  blogArr.forEach((item) => {
    if (
      item.title.toLowerCase().replace(/ /g, "-") ===
      "neo-banks-vs-traditional-banks"
    ) {
      blog = item;
    }
  });
 
  async function copyToClip() {
    await navigator.clipboard.writeText(window.location.href);
    notify();
  }
  const notify = () => toast("Copied");

  return (
    <>
      <Helmet>
        <title>{`Paystreet: ${blog.title}`}</title>
        <meta name="description" content={`${blog.shortDescription.substring(0, 155)}...`}></meta>

        {/* Open Graph meta tags for Facebook */}
        <meta property="og:image" content={`${blog.image}`} />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta
          name="twitter:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />
        <meta name="twitter:image" content={`${blog.image}`} />

        {/* LinkedIn Article Rich Media meta tags */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />
        <meta property="og:image" content={`${blog.image}`} />
      </Helmet>
      <div
        className="mobile_responsive_blog color-tnc-indigo"
        style={{ transition: "background-color 1s ease" }}
        data-color="indigo">
        <div className="wrapper-lite blog-single">
          <HeaderMenuLite />
          <div className="container">
            <div className="blog-single-img-outer">
              <img src={blogSlider} alt="..." className="img-fluid" />
            </div>
            <div className="row">
              <div className="col-lg-11 col-md-10">
                <div className="blog-content-single">
                  <div className="blog-date-time">
                    PayStreet team <span>•</span>May 29, 2023<span>•</span>5
                    mins read
                  </div>
                  <h2 className="text-green page_sub_title mb-3 ">
                    Neo Banks vs Traditional Banks
                  </h2>

                  <h3 className="text-green blog-sub-title-h3">
                    What has changed recently?
                  </h3>
                  <p>
                    The Neo-banks are changing the canvas of fin-tech by rapidly
                    bridging the gap between the services that typically the
                    traditional banks offer and the evolving expectations of the
                    customers in the digital age. <br />
                  </p>
                  <h3 className="text-green blog-sub-title-h3">
                    How do we define Neo-banks?
                  </h3>
                  <p>
                    Neo-banks are online-only financial technology (fin-tech)
                    companies that operate solely digitally through Web and
                    Mobile App medium. <br /> <br />
                    Neo-banks are digital banks but operate without any physical
                    branches and offer largely the services that traditional
                    banks offer and at times exceed them in innovative ways.{" "}
                    <br />
                  </p>
                  <h3 className="text-green blog-sub-title-h3">
                    How are they different from other typical banks?
                  </h3>
                  <p>
                    Neo-banks leverage technology and artificial intelligence
                    (AI) to offer a range of personalised services to customers
                    while traditional banks follow an omni-channel approach
                    through both physical (branches and ATMs) and digital
                    banking presence. <br /> <br />
                    Neo-banks, on the other hand, do not have the funds or
                    customer base to overthrow traditional banks, yet they are
                    powered by innovation to launch features and develop
                    partnerships to serve their customers more quickly than
                    traditional banks. <br /> <br />
                    Neo-banks prominently cater to retail customers, and small
                    and medium businesses, which are generally underserved by
                    traditional banks. <br /> <br />
                    Since banking is a regulated activity, neo banks are covered
                    under the ambit of the licensing authorities. Some fintech
                    companies directly seek licenses from the regulatory
                    authorities, other work indirectly. <br />
                  </p>
                  <p>
                    <strong className="blog-para-title">
                      The distinct advantages or features offered by new banks:
                    </strong>{" "}
                    <br />
                    <br />
                    {/* <span className="blog-text-link">support@paystreet.io</span> */}
                    <strong>Lower costs -</strong> Fewer regulations and the
                    absence of credit risk enable neo-banks to keep their costs
                    low. Products are typically inexpensive, with no or little
                    monthly maintenance fees. <br /> <br />
                    <strong>Speed - </strong>Neo-banks allow customers to set up
                    accounts quickly and process requests speedily. <br />{" "}
                    <br />
                    <strong>Cross-border transactions-</strong> Being
                    internet-based banks, neo banks allow flexibility to perform
                    transactions outside the business banking hours. <br />{" "}
                    <br />
                    Those that offer loans may skip the usual time-consuming
                    application processes in favour of innovative strategies for
                    evaluating the credit. <br />
                  </p>
                  <p>
                    <strong className="blog-para-title">
                      Challenges for the new banks:
                    </strong>{" "}
                    <br />
                    <br />
                    <strong> Digital illiteracy - </strong>Since neo-banks are
                    highly digital-focused, they may not be able to cater to the
                    banking needs of non-tech-savvy consumers or business
                    houses. <br /> <br />
                    <strong>Mobile penetration – </strong>Businesses and
                    individuals are still wary of using mobile phones for
                    financial transactions. <br /> <br />
                    <strong>Services offered -</strong> Neo-banks offer a small
                    range of products and services as compared to a whole gamut
                    of services that traditional banks offer. <br /> <br />
                    <strong>Building trust -</strong> Unlike traditional banks,
                    as neo-banks do not have a physical presence, so customers
                    cannot literally bank upon them in case of any
                    issues/challenges. <br /> <br />
                    Hence, models such as free subscriptions and memberships
                    benefits are used to attract customers to experience the
                    service before paying for it. <br /> <br />
                  </p>
                  {/* {itemList.map((item, index) => (
                                <div>
                                    <h1>{item.heading}</h1>
                                    <p>{item.content}</p>
                                </div>
                            ))} */}
                </div>
              </div>
              <div className="col-lg-1 col-md-2">
                <div className="blog-social-fixed">
                  <ul>
                    <li>
                      <span className="social-media">
                        <img src={shareIcon} />
                      </span>
                    </li>
                    <li>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={blog.title}>
                        <img src={fbImg} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton url={window.location.href}>
                        <img src={inImg} />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={window.location.href}>
                        <img src={twImg} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <span className="social-media">
                        <img src={copyImg} onClick={copyToClip} />
                        <ToastContainer
                          position="bottom-right"
                          autoClose={5000}
                          hideProgressBar
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="blog-related">
              <div className="row">
                <h2>Related blogs</h2>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/iso-20022"}>
                    <img src={blogCart1} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 8, 2023<span>•</span>5 mins
                    read
                  </div>
                  <h4 className="related-title">
                    <a href={"/iso-20022"}>
                      ISO 20022 <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>ISO 20022 is a standards development methodology. </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Payments</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/cbdc"}>
                    <img src={blogCart2} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 15, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/cbdc"}>
                      Central Bank Digital Currency{" "}
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    Central bank digital currencies (CBDCs) are a form of
                    digital...{" "}
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/efficient-system-for-payment"}>
                    <img src={blogCart3} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 22, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/efficient-system-for-payment"}>
                      Building an efficient system...{" "}
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    We know that cross-border payments have already become quick
                    and...
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bg">
          {/* <section className="testimonial_section"> */}
          <div className="container scale_business_section contact-subtribe">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <SubscribeComponent 
                        heading={<><span>Start crossing borders with ease.</span>
                          <br></br> Unlock a world of possibilities for your business
                          with just a few clicks.</>}
                        subheading={<p className="text-white mtb">Join our community today.</p>}
                        showVerticle={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </section> */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default BlogSingle;
