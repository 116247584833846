import React from "react";
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
import { privacypolicy } from "./Term-data";
import "./term.css";
import { Link } from "react-scroll";

export default function Privacypolicy() {
    return (
        <>
            <div className="privacy-page color-tnc-indigo" style={{ 'transition': 'background-color 1s ease' }} data-color="indigo">
                <div className="wrapper-lite ">
                    <HeaderMenuLite />
                    <div className="smart_toolsets_trem" data-color="indigo" style={{ 'transition': 'background-color 1s ease' }}>
                        <div className="banner_section">
                        
                        </div>
                        <div className="py-5 term-color-padding-change">
                            <div className="" id="smart-toolsets" data-color="indigo" >
                                <div className="term-condition-page">
                                    <div className="container">
                                        <div className="row mt-1">
                                            <div className="col-md-12">
                                                <h1 className="faq-tnc-heading d-block d-lg-none mb-5">Privacy Policy</h1>
                                            </div>
                                            <div className="col-lg-4">
                                            <div className="mobile-policy-sticky d-block d-lg-none">
                                                 <div class="dropdown dropright mobile-menu-policy">
                                                     <button class="btn btn-tpt dropdown-toggle p-0" type="button" data-bs-toggle="dropdown">Category
                                                         <span class="caret"></span></button>
                                                     <div class="dropdown-menu" role="menu">
                                                         <ul className="list-group  term-condition-left animated-line ">
                                                             <li className="list-group-item ">
                                                                 <Link activeClassName="active" smooth spy to="section_0" offset={-200}>
                                                                     <span>1. Scope and consent</span>
                                                                 </Link>
                                                             </li>
                                                             <li className="list-group-item ">
                                                                 <Link activeClassName="active" smooth spy to="section_1" offset={-200}>
                                                                     <span>2. How we collect your Personal Information</span>
                                                                 </Link>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <Link activeClassName="active" smooth spy to="section_2" offset={-200}>
                                                                     <span>3.  How we use your Personal Information</span>
                                                                 </Link>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <Link activeClassName="active" smooth spy to="section_3" offset={-200}>
                                                                     <span>4.  How we share your Personal Information with other PayStreet users</span>
                                                                 </Link>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <Link activeClassName="active" smooth spy to="section_4" offset={-200}>
                                                                     <span>5. How we share your Personal Information</span>
                                                                 </Link>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <Link activeClassName="active" smooth spy to="section_5" offset={-200}>
                                                                     <span>6. You may access and correct your Personal Information</span>
                                                                 </Link>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <Link activeClassName="active" smooth spy to="section_6" offset={-200}>
                                                                     <span>7. Storage, security and retention of your Personal Information</span>
                                                                 </Link>
                                                             </li>
                                                             <li className="list-group-item">
                                                                 <Link activeClassName="active" smooth spy to="section_7" offset={-200}>
                                                                     <span>8. Contact us about Privacy Questions</span>
                                                                 </Link>
                                                             </li>
                                                         </ul>
                                                     </div>
                                                 </div>
                                                
                                            </div>
                                                <ol className="list-group  term-condition-left animated-line d-none d-lg-block">
                                                    <li className="list-group-item ">
                                                        <Link activeClassName="active" smooth spy to="section_0" offset={-200}>
                                                            <span>1. Scope and consent</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item ">
                                                        <Link activeClassName="active" smooth spy to="section_1" offset={-200}>
                                                            <span>2. How we collect your Personal Information</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_2" offset={-200}>
                                                            <span>3.  How we use your Personal Information</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_3" offset={-200}>
                                                            <span>4.  How we share your Personal Information with other PayStreet users</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_4" offset={-200}>
                                                            <span>5. How we share your Personal Information</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_5" offset={-200}>
                                                            <span>6. You may access and correct your Personal Information</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_6" offset={-200}>
                                                            <span>7. Storage, security and retention of your Personal Information</span>
                                                        </Link>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <Link activeClassName="active" smooth spy to="section_7" offset={-200}>
                                                            <span>8. Contact us about Privacy Questions</span>
                                                        </Link>
                                                    </li>
                                                </ol>
                                            </div>
                                            <div className="col-lg-7 offset-lg-1 term-content">
                                                <h1 className="faq-tnc-heading d-none d-lg-block">Privacy Policy</h1>
                                                <p><strong>Your privacy is very important and critical to us. Please take a moment to read this Privacy Policy so that you know and understand the purposes for which we collect, use and disclose your Personal Information.</strong></p>

                                                <p>The consent you give PayStreet Pte. Ltd. (“PayStreet”, “we”, “our”, “us”) under this Privacy Policy shall be in addition to any other consents you may have previously given to us in respect of your Personal Information.</p>

                                                <p> We may update this Privacy Policy from time to time to ensure its consistency with our business operations, future development, industry trends and/or changes in legal or regulatory requirements. Subject to your rights at law, you agree to be bound by the prevailing terms of this Privacy Policy as updated from time to time on this website. Please check back regularly for updated information on the handling of your Personal Information.</p>
                                                {privacypolicy.map((item, index) => (
                                                    <section id={'section_' + index} className="mt-4">
                                                        {/* <h3 className="scroll-heading">{item.heading}</h3> */}
                                                        <div className="nav__container">
                                                            <div className="section-content">
                                                                {/* {index +1+'.'}  */}
                                                                <p className="tnc_heading" >{index+1}. {item.heading}</p>
                                                                <div dangerouslySetInnerHTML={{ __html: item.content }} className="tnc-runing-text" ></div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bg" >
                    <Footer />
                </div>
            </div>
        </>
    );
}