// const URL="https://paystreet.netlify.app/login";
const URL = "http://app.paystreet.io/login";
// const REGISTERURL="https://paystreet.netlify.app/register";
const REGISTERURL = "https://app.paystreet.io/register";
const CONTACT = "contact";
const ADDRESS = 'Paystreet Pte. Ltd.  111 North Bridge Road #24-02 Peninsula Plaza Singapore (179098)';
const PHONE = '123-456-7890';
const EMAIL = 'help@paystreet.io';
const COMPANYTIME = 'Mon-Fri 8AM - 8PM';

const SHARE = "";
const FACEBOOK  = "https://facebook.com/login/";
const  TWITTER = "https://twitter.com/solidfiles";
const  LINKEDIN =  "https://in.linkedin.com/";
const  MAIL = "https://mail.google.com/";
const COPY = "";

export { URL, REGISTERURL, ADDRESS, PHONE, EMAIL, COMPANYTIME, CONTACT, SHARE, FACEBOOK, TWITTER, LINKEDIN, MAIL, COPY};

export const INDUSTRIES = [
    {
        "id": "0a17aa4e-bcc0-4804-b5e1-881cbe5a1c12",
        "createdAt": "2020-12-23T08:26:12.163Z",
        "updatedAt": "2020-12-23T08:26:12.163Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Accounting/Audit/Consulting",
        "description": null
    },
    {
        "id": "f9083cdb-c76e-41ae-914b-79adae0fd132",
        "createdAt": "2020-12-23T08:26:24.752Z",
        "updatedAt": "2020-12-23T08:26:24.752Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Agriculture",
        "description": null
    },
    {
        "id": "b49b2c99-55a7-4bda-b472-5a457f278d03",
        "createdAt": "2020-12-23T08:26:35.582Z",
        "updatedAt": "2022-02-09T04:09:56.723Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Antiques & Art piece",
        "description": null
    },
    {
        "id": "afd62943-9e82-4b0e-90a4-bbd49a71ad39",
        "createdAt": "2020-12-23T08:26:47.124Z",
        "updatedAt": "2020-12-23T08:26:47.124Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Banking/Financial/Insurance",
        "description": null
    },
    {
        "id": "572273af-0b10-4685-902b-2478da1fcd67",
        "createdAt": "2020-12-23T08:27:00.259Z",
        "updatedAt": "2020-12-23T08:27:00.259Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Construction & Development",
        "description": null
    },
    {
        "id": "f46faf3f-62c6-46ad-b5f4-44989858169b",
        "createdAt": "2020-12-23T08:27:24.826Z",
        "updatedAt": "2020-12-23T08:27:24.826Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Education",
        "description": null
    },
    {
        "id": "5656e20a-2ed6-42e5-bcfa-d0602b84e669",
        "createdAt": "2020-12-23T08:27:33.881Z",
        "updatedAt": "2020-12-23T08:27:33.881Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Engineering",
        "description": null
    },
    {
        "id": "a42aea4b-7ae6-4341-8d71-e0204a552820",
        "createdAt": "2020-12-23T08:27:42.266Z",
        "updatedAt": "2020-12-23T08:27:42.266Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Entertainment & Recreation",
        "description": null
    },
    {
        "id": "9d4a46cc-a9ae-4d3b-af9d-6b1c0fa20754",
        "createdAt": "2020-12-23T08:27:51.117Z",
        "updatedAt": "2020-12-23T08:27:51.117Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Food & Beverages",
        "description": null
    },
    {
        "id": "8df4e159-1908-4852-bf5b-e8bf7ad18906",
        "createdAt": "2020-12-23T08:28:01.429Z",
        "updatedAt": "2020-12-23T08:28:01.429Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Forestry & Logging",
        "description": null
    },
    {
        "id": "296da8a9-08e2-4e09-8b11-78427a2055ab",
        "createdAt": "2020-12-23T08:28:12.660Z",
        "updatedAt": "2020-12-23T08:28:12.660Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Government Bodies",
        "description": null
    },
    {
        "id": "8900a6d5-1ac3-408d-b761-54b85d12a1f9",
        "createdAt": "2020-12-23T08:29:06.070Z",
        "updatedAt": "2020-12-23T08:29:06.070Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Hotel & Lodging",
        "description": null
    },
    {
        "id": "4849ff72-eb35-49d8-8be8-f6b1e50d6855",
        "createdAt": "2020-12-23T08:29:18.210Z",
        "updatedAt": "2020-12-23T08:29:18.210Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Information Technology",
        "description": null
    },
    {
        "id": "0689144f-2b52-44bf-b249-28ac309c9422",
        "createdAt": "2020-12-23T08:29:28.357Z",
        "updatedAt": "2020-12-23T08:29:28.357Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Legal & Judiciary",
        "description": null
    },
    {
        "id": "a4a97deb-b57c-4805-adb3-454673f14391",
        "createdAt": "2020-12-23T08:29:37.215Z",
        "updatedAt": "2020-12-23T08:29:37.215Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Logistics",
        "description": null
    },
    {
        "id": "d1aab4b0-fd18-4ffc-8c5a-4c374f0461d9",
        "createdAt": "2020-12-23T08:29:44.795Z",
        "updatedAt": "2020-12-23T08:29:44.795Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Manufacturing",
        "description": null
    },
    {
        "id": "10c9aeba-b8a9-47ef-b73f-5ead3bae0cb5",
        "createdAt": "2020-12-23T08:29:51.719Z",
        "updatedAt": "2020-12-23T08:29:51.719Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Media & Communications",
        "description": null
    },
    {
        "id": "8f198b9e-c015-4347-aa7f-d92d1d24b02e",
        "createdAt": "2020-12-23T08:30:00.359Z",
        "updatedAt": "2020-12-23T08:30:00.359Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Medical & Healthcare",
        "description": null
    },
    {
        "id": "a0ad1cec-281d-4697-82a8-9985c798978b",
        "createdAt": "2020-12-23T08:30:10.779Z",
        "updatedAt": "2020-12-23T08:30:10.779Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Mining & Quarrying",
        "description": null
    },
    {
        "id": "172b03ff-2045-4c1c-af04-156548408049",
        "createdAt": "2020-12-23T08:30:19.690Z",
        "updatedAt": "2020-12-23T08:30:19.690Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Money Lending",
        "description": null
    },
    {
        "id": "03377116-9063-4e52-bec5-d3e2e2206dcb",
        "createdAt": "2020-12-23T08:30:29.241Z",
        "updatedAt": "2020-12-23T08:30:29.241Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Non-Profit Organisation",
        "description": null
    },
    {
        "id": "59a743e3-6a42-40c7-af17-ade2cb26e293",
        "createdAt": "2020-12-23T08:30:38.445Z",
        "updatedAt": "2020-12-23T08:30:38.445Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Oil & Gas",
        "description": null
    },
    {
        "id": "a5d2d6c1-0edd-4be2-b725-333c852ebc63",
        "createdAt": "2020-12-23T08:31:49.535Z",
        "updatedAt": "2020-12-23T08:31:49.535Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Others",
        "description": null
    },
    {
        "id": "470abd8b-5471-49af-b9c9-26e12fcb1dbc",
        "createdAt": "2020-12-23T08:30:46.117Z",
        "updatedAt": "2020-12-23T08:30:46.117Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Pawnshop",
        "description": null
    },
    {
        "id": "94b26387-1ac0-40ec-a4b4-69dadb41703d",
        "createdAt": "2020-12-23T08:30:54.115Z",
        "updatedAt": "2020-12-23T08:30:54.115Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Professional Services Firm",
        "description": null
    },
    {
        "id": "005acfb1-9849-42bd-b9b2-3a40ae81bad0",
        "createdAt": "2020-12-23T08:31:02.121Z",
        "updatedAt": "2020-12-23T08:31:02.121Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Real Estate",
        "description": null
    },
    {
        "id": "b2156ff2-0c9a-403d-8ca3-34e22c4bef3d",
        "createdAt": "2020-12-23T08:31:12.324Z",
        "updatedAt": "2020-12-23T08:31:12.324Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Trading/Retail/Wholesale",
        "description": null
    },
    {
        "id": "b20e57fd-aecd-4c58-9e57-ee5b80dc6c18",
        "createdAt": "2020-12-23T08:31:20.953Z",
        "updatedAt": "2020-12-23T08:31:20.953Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Transportation",
        "description": null
    },
    {
        "id": "34d4439b-d5da-4aa7-bc4e-6d794121b2ac",
        "createdAt": "2020-12-23T08:31:34.860Z",
        "updatedAt": "2020-12-23T08:31:34.860Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Travel/Tourism",
        "description": null
    },
    {
        "id": "8cf57138-4c9b-4111-85bd-877901b70bc3",
        "createdAt": "2020-12-23T08:31:43.992Z",
        "updatedAt": "2020-12-23T08:31:43.992Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Unknown - Unknown",
        "description": null
    },
    {
        "id": "5ff61de7-cb2e-49cd-9e9e-42cef2d87a25",
        "createdAt": "2020-12-23T08:31:26.812Z",
        "updatedAt": "2020-12-23T08:31:26.812Z",
        "disabledAt": null,
        "deletedAt": null,
        "name": "Utilities",
        "description": null
    }
]

export const COUNTRIES = [
    {
        'name': 'Afghanistan',
        'dial_code': '+93',
        'iso2': 'AF'
    },
    {
        'name': 'Aland Islands',
        'dial_code': '+358',
        'iso2': 'AX'
    },
    {
        'name': 'Albania',
        'dial_code': '+355',
        'iso2': 'AL'
    },
    {
        'name': 'Algeria',
        'dial_code': '+213',
        'iso2': 'DZ'
    },
    {
        'name': 'AmericanSamoa',
        'dial_code': '+1684',
        'iso2': 'AS'
    },
    {
        'name': 'Andorra',
        'dial_code': '+376',
        'iso2': 'AD'
    },
    {
        'name': 'Angola',
        'dial_code': '+244',
        'iso2': 'AO'
    },
    {
        'name': 'Anguilla',
        'dial_code': '+1264',
        'iso2': 'AI'
    },
    {
        'name': 'Antarctica',
        'dial_code': '+672',
        'iso2': 'AQ'
    },
    {
        'name': 'Antigua and Barbuda',
        'dial_code': '+1268',
        'iso2': 'AG'
    },
    {
        'name': 'Argentina',
        'dial_code': '+54',
        'iso2': 'AR'
    },
    {
        'name': 'Armenia',
        'dial_code': '+374',
        'iso2': 'AM'
    },
    {
        'name': 'Aruba',
        'dial_code': '+297',
        'iso2': 'AW'
    },
    {
        'name': 'Australia',
        'dial_code': '+61',
        'iso2': 'AU'
    },
    {
        'name': 'Austria',
        'dial_code': '+43',
        'iso2': 'AT'
    },
    {
        'name': 'Azerbaijan',
        'dial_code': '+994',
        'iso2': 'AZ'
    },
    {
        'name': 'Bahamas',
        'dial_code': '+1242',
        'iso2': 'BS'
    },
    {
        'name': 'Bahrain',
        'dial_code': '+973',
        'iso2': 'BH'
    },
    {
        'name': 'Bangladesh',
        'dial_code': '+880',
        'iso2': 'BD'
    },
    {
        'name': 'Barbados',
        'dial_code': '+1246',
        'iso2': 'BB'
    },
    {
        'name': 'Belarus',
        'dial_code': '+375',
        'iso2': 'BY'
    },
    {
        'name': 'Belgium',
        'dial_code': '+32',
        'iso2': 'BE'
    },
    {
        'name': 'Belize',
        'dial_code': '+501',
        'iso2': 'BZ'
    },
    {
        'name': 'Benin',
        'dial_code': '+229',
        'iso2': 'BJ'
    },
    {
        'name': 'Bermuda',
        'dial_code': '+1441',
        'iso2': 'BM'
    },
    {
        'name': 'Bhutan',
        'dial_code': '+975',
        'iso2': 'BT'
    },
    {
        'name': 'Bolivia, Plurinational State of',
        'dial_code': '+591',
        'iso2': 'BO'
    },
    {
        'name': 'Bosnia and Herzegovina',
        'dial_code': '+387',
        'iso2': 'BA'
    },
    {
        'name': 'Botswana',
        'dial_code': '+267',
        'iso2': 'BW'
    },
    {
        'name': 'Brazil',
        'dial_code': '+55',
        'iso2': 'BR'
    },
    {
        'name': 'British Indian Ocean Territory',
        'dial_code': '+246',
        'iso2': 'IO'
    },
    {
        'name': 'Brunei Darussalam',
        'dial_code': '+673',
        'iso2': 'BN'
    },
    {
        'name': 'Bulgaria',
        'dial_code': '+359',
        'iso2': 'BG'
    },
    {
        'name': 'Burkina Faso',
        'dial_code': '+226',
        'iso2': 'BF'
    },
    {
        'name': 'Burundi',
        'dial_code': '+257',
        'iso2': 'BI'
    },
    {
        'name': 'Cambodia',
        'dial_code': '+855',
        'iso2': 'KH'
    },
    {
        'name': 'Cameroon',
        'dial_code': '+237',
        'iso2': 'CM'
    },
    {
        'name': 'Canada',
        'dial_code': '+1',
        'iso2': 'CA'
    },
    {
        'name': 'Cape Verde',
        'dial_code': '+238',
        'iso2': 'CV'
    },
    {
        'name': 'Cayman Islands',
        'dial_code': '+345',
        'iso2': 'KY'
    },
    {
        'name': 'Central African Republic',
        'dial_code': '+236',
        'iso2': 'CF'
    },
    {
        'name': 'Chad',
        'dial_code': '+235',
        'iso2': 'TD'
    },
    {
        'name': 'Chile',
        'dial_code': '+56',
        'iso2': 'CL'
    },
    {
        'name': 'China',
        'dial_code': '+86',
        'iso2': 'CN'
    },
    {
        'name': 'Christmas Island',
        'dial_code': '+61',
        'iso2': 'CX'
    },
    {
        'name': 'Cocos (Keeling) Islands',
        'dial_code': '+61',
        'iso2': 'CC'
    },
    {
        'name': 'Colombia',
        'dial_code': '+57',
        'iso2': 'CO'
    },
    {
        'name': 'Comoros',
        'dial_code': '+269',
        'iso2': 'KM'
    },
    {
        'name': 'Congo',
        'dial_code': '+242',
        'iso2': 'CG'
    },
    {
        'name': 'Congo, The Democratic Republic of the Congo',
        'dial_code': '+243',
        'iso2': 'CD'
    },
    {
        'name': 'Cook Islands',
        'dial_code': '+682',
        'iso2': 'CK'
    },
    {
        'name': 'Costa Rica',
        'dial_code': '+506',
        'iso2': 'CR'
    },
    {
        'name': "Cote d'Ivoire",
        'dial_code': '+225',
        'iso2': 'CI'
    },
    {
        'name': 'Croatia',
        'dial_code': '+385',
        'iso2': 'HR'
    },
    {
        'name': 'Cuba',
        'dial_code': '+53',
        'iso2': 'CU'
    },
    {
        'name': 'Cyprus',
        'dial_code': '+357',
        'iso2': 'CY'
    },
    {
        'name': 'Czech Republic',
        'dial_code': '+420',
        'iso2': 'CZ'
    },
    {
        'name': 'Denmark',
        'dial_code': '+45',
        'iso2': 'DK'
    },
    {
        'name': 'Djibouti',
        'dial_code': '+253',
        'iso2': 'DJ'
    },
    {
        'name': 'Dominica',
        'dial_code': '+1767',
        'iso2': 'DM'
    },
    {
        'name': 'Dominican Republic',
        'dial_code': '+1849',
        'iso2': 'DO'
    },
    {
        'name': 'Ecuador',
        'dial_code': '+593',
        'iso2': 'EC'
    },
    {
        'name': 'Egypt',
        'dial_code': '+20',
        'iso2': 'EG'
    },
    {
        'name': 'El Salvador',
        'dial_code': '+503',
        'iso2': 'SV'
    },
    {
        'name': 'Equatorial Guinea',
        'dial_code': '+240',
        'iso2': 'GQ'
    },
    {
        'name': 'Eritrea',
        'dial_code': '+291',
        'iso2': 'ER'
    },
    {
        'name': 'Estonia',
        'dial_code': '+372',
        'iso2': 'EE'
    },
    {
        'name': 'Ethiopia',
        'dial_code': '+251',
        'iso2': 'ET'
    },
    {
        'name': 'Falkland Islands (Malvinas)',
        'dial_code': '+500',
        'iso2': 'FK'
    },
    {
        'name': 'Faroe Islands',
        'dial_code': '+298',
        'iso2': 'FO'
    },
    {
        'name': 'Fiji',
        'dial_code': '+679',
        'iso2': 'FJ'
    },
    {
        'name': 'Finland',
        'dial_code': '+358',
        'iso2': 'FI'
    },
    {
        'name': 'France',
        'dial_code': '+33',
        'iso2': 'FR'
    },
    {
        'name': 'French Guiana',
        'dial_code': '+594',
        'iso2': 'GF'
    },
    {
        'name': 'French Polynesia',
        'dial_code': '+689',
        'iso2': 'PF'
    },
    {
        'name': 'Gabon',
        'dial_code': '+241',
        'iso2': 'GA'
    },
    {
        'name': 'Gambia',
        'dial_code': '+220',
        'iso2': 'GM'
    },
    {
        'name': 'Georgia',
        'dial_code': '+995',
        'iso2': 'GE'
    },
    {
        'name': 'Germany',
        'dial_code': '+49',
        'iso2': 'DE'
    },
    {
        'name': 'Ghana',
        'dial_code': '+233',
        'iso2': 'GH'
    },
    {
        'name': 'Gibraltar',
        'dial_code': '+350',
        'iso2': 'GI'
    },
    {
        'name': 'Greece',
        'dial_code': '+30',
        'iso2': 'GR'
    },
    {
        'name': 'Greenland',
        'dial_code': '+299',
        'iso2': 'GL'
    },
    {
        'name': 'Grenada',
        'dial_code': '+1473',
        'iso2': 'GD'
    },
    {
        'name': 'Guadeloupe',
        'dial_code': '+590',
        'iso2': 'GP'
    },
    {
        'name': 'Guam',
        'dial_code': '+1671',
        'iso2': 'GU'
    },
    {
        'name': 'Guatemala',
        'dial_code': '+502',
        'iso2': 'GT'
    },
    {
        'name': 'Guernsey',
        'dial_code': '+44',
        'iso2': 'GG'
    },
    {
        'name': 'Guinea',
        'dial_code': '+224',
        'iso2': 'GN'
    },
    {
        'name': 'Guinea-Bissau',
        'dial_code': '+245',
        'iso2': 'GW'
    },
    {
        'name': 'Guyana',
        'dial_code': '+595',
        'iso2': 'GY'
    },
    {
        'name': 'Haiti',
        'dial_code': '+509',
        'iso2': 'HT'
    },
    {
        'name': 'Holy See (Vatican City State)',
        'dial_code': '+379',
        'iso2': 'VA'
    },
    {
        'name': 'Honduras',
        'dial_code': '+504',
        'iso2': 'HN'
    },
    {
        'name': 'Hong Kong',
        'dial_code': '+852',
        'iso2': 'HK'
    },
    {
        'name': 'Hungary',
        'dial_code': '+36',
        'iso2': 'HU'
    },
    {
        'name': 'Iceland',
        'dial_code': '+354',
        'iso2': 'IS'
    },
    {
        'name': 'India',
        'dial_code': '+91',
        'iso2': 'IN'
    },
    {
        'name': 'Indonesia',
        'dial_code': '+62',
        'iso2': 'ID'
    },
    {
        'name': 'Iran, Islamic Republic of Persian Gulf',
        'dial_code': '+98',
        'iso2': 'IR'
    },
    {
        'name': 'Iraq',
        'dial_code': '+964',
        'iso2': 'IQ'
    },
    {
        'name': 'Ireland',
        'dial_code': '+353',
        'iso2': 'IE'
    },
    {
        'name': 'Isle of Man',
        'dial_code': '+44',
        'iso2': 'IM'
    },
    {
        'name': 'Israel',
        'dial_code': '+972',
        'iso2': 'IL'
    },
    {
        'name': 'Italy',
        'dial_code': '+39',
        'iso2': 'IT'
    },
    {
        'name': 'Jamaica',
        'dial_code': '+1876',
        'iso2': 'JM'
    },
    {
        'name': 'Japan',
        'dial_code': '+81',
        'iso2': 'JP'
    },
    {
        'name': 'Jersey',
        'dial_code': '+44',
        'iso2': 'JE'
    },
    {
        'name': 'Jordan',
        'dial_code': '+962',
        'iso2': 'JO'
    },
    {
        'name': 'Kazakhstan',
        'dial_code': '+77',
        'iso2': 'KZ'
    },
    {
        'name': 'Kenya',
        'dial_code': '+254',
        'iso2': 'KE'
    },
    {
        'name': 'Kiribati',
        'dial_code': '+686',
        'iso2': 'KI'
    },
    {
        'name': "Korea, Democratic People's Republic of Korea",
        'dial_code': '+850',
        'iso2': 'KP'
    },
    {
        'name': 'Korea, Republic of South Korea',
        'dial_code': '+82',
        'iso2': 'KR'
    },
    {
        'name': 'Kuwait',
        'dial_code': '+965',
        'iso2': 'KW'
    },
    {
        'name': 'Kyrgyzstan',
        'dial_code': '+996',
        'iso2': 'KG'
    },
    {
        'name': 'Laos',
        'dial_code': '+856',
        'iso2': 'LA'
    },
    {
        'name': 'Latvia',
        'dial_code': '+371',
        'iso2': 'LV'
    },
    {
        'name': 'Lebanon',
        'dial_code': '+961',
        'iso2': 'LB'
    },
    {
        'name': 'Lesotho',
        'dial_code': '+266',
        'iso2': 'LS'
    },
    {
        'name': 'Liberia',
        'dial_code': '+231',
        'iso2': 'LR'
    },
    {
        'name': 'Libyan Arab Jamahiriya',
        'dial_code': '+218',
        'iso2': 'LY'
    },
    {
        'name': 'Liechtenstein',
        'dial_code': '+423',
        'iso2': 'LI'
    },
    {
        'name': 'Lithuania',
        'dial_code': '+370',
        'iso2': 'LT'
    },
    {
        'name': 'Luxembourg',
        'dial_code': '+352',
        'iso2': 'LU'
    },
    {
        'name': 'Macao',
        'dial_code': '+853',
        'iso2': 'MO'
    },
    {
        'name': 'Macedonia',
        'dial_code': '+389',
        'iso2': 'MK'
    },
    {
        'name': 'Madagascar',
        'dial_code': '+261',
        'iso2': 'MG'
    },
    {
        'name': 'Malawi',
        'dial_code': '+265',
        'iso2': 'MW'
    },
    {
        'name': 'Malaysia',
        'dial_code': '+60',
        'iso2': 'MY'
    },
    {
        'name': 'Maldives',
        'dial_code': '+960',
        'iso2': 'MV'
    },
    {
        'name': 'Mali',
        'dial_code': '+223',
        'iso2': 'ML'
    },
    {
        'name': 'Malta',
        'dial_code': '+356',
        'iso2': 'MT'
    },
    {
        'name': 'Marshall Islands',
        'dial_code': '+692',
        'iso2': 'MH'
    },
    {
        'name': 'Martinique',
        'dial_code': '+596',
        'iso2': 'MQ'
    },
    {
        'name': 'Mauritania',
        'dial_code': '+222',
        'iso2': 'MR'
    },
    {
        'name': 'Mauritius',
        'dial_code': '+230',
        'iso2': 'MU'
    },
    {
        'name': 'Mayotte',
        'dial_code': '+262',
        'iso2': 'YT'
    },
    {
        'name': 'Mexico',
        'dial_code': '+52',
        'iso2': 'MX'
    },
    {
        'name': 'Micronesia, Federated States of Micronesia',
        'dial_code': '+691',
        'iso2': 'FM'
    },
    {
        'name': 'Moldova',
        'dial_code': '+373',
        'iso2': 'MD'
    },
    {
        'name': 'Monaco',
        'dial_code': '+377',
        'iso2': 'MC'
    },
    {
        'name': 'Mongolia',
        'dial_code': '+976',
        'iso2': 'MN'
    },
    {
        'name': 'Montenegro',
        'dial_code': '+382',
        'iso2': 'ME'
    },
    {
        'name': 'Montserrat',
        'dial_code': '+1664',
        'iso2': 'MS'
    },
    {
        'name': 'Morocco',
        'dial_code': '+212',
        'iso2': 'MA'
    },
    {
        'name': 'Mozambique',
        'dial_code': '+258',
        'iso2': 'MZ'
    },
    {
        'name': 'Myanmar',
        'dial_code': '+95',
        'iso2': 'MM'
    },
    {
        'name': 'Namibia',
        'dial_code': '+264',
        'iso2': 'NA'
    },
    {
        'name': 'Nauru',
        'dial_code': '+674',
        'iso2': 'NR'
    },
    {
        'name': 'Nepal',
        'dial_code': '+977',
        'iso2': 'NP'
    },
    {
        'name': 'Netherlands',
        'dial_code': '+31',
        'iso2': 'NL'
    },
    {
        'name': 'Netherlands Antilles',
        'dial_code': '+599',
        'iso2': 'AN'
    },
    {
        'name': 'New Caledonia',
        'dial_code': '+687',
        'iso2': 'NC'
    },
    {
        'name': 'New Zealand',
        'dial_code': '+64',
        'iso2': 'NZ'
    },
    {
        'name': 'Nicaragua',
        'dial_code': '+505',
        'iso2': 'NI'
    },
    {
        'name': 'Niger',
        'dial_code': '+227',
        'iso2': 'NE'
    },
    {
        'name': 'Nigeria',
        'dial_code': '+234',
        'iso2': 'NG'
    },
    {
        'name': 'Niue',
        'dial_code': '+683',
        'iso2': 'NU'
    },
    {
        'name': 'Norfolk Island',
        'dial_code': '+672',
        'iso2': 'NF'
    },
    {
        'name': 'Northern Mariana Islands',
        'dial_code': '+1670',
        'iso2': 'MP'
    },
    {
        'name': 'Norway',
        'dial_code': '+47',
        'iso2': 'NO'
    },
    {
        'name': 'Oman',
        'dial_code': '+968',
        'iso2': 'OM'
    },
    {
        'name': 'Pakistan',
        'dial_code': '+92',
        'iso2': 'PK'
    },
    {
        'name': 'Palau',
        'dial_code': '+680',
        'iso2': 'PW'
    },
    {
        'name': 'Palestinian Territory, Occupied',
        'dial_code': '+970',
        'iso2': 'PS'
    },
    {
        'name': 'Panama',
        'dial_code': '+507',
        'iso2': 'PA'
    },
    {
        'name': 'Papua New Guinea',
        'dial_code': '+675',
        'iso2': 'PG'
    },
    {
        'name': 'Paraguay',
        'dial_code': '+595',
        'iso2': 'PY'
    },
    {
        'name': 'Peru',
        'dial_code': '+51',
        'iso2': 'PE'
    },
    {
        'name': 'Philippines',
        'dial_code': '+63',
        'iso2': 'PH'
    },
    {
        'name': 'Pitcairn',
        'dial_code': '+872',
        'iso2': 'PN'
    },
    {
        'name': 'Poland',
        'dial_code': '+48',
        'iso2': 'PL'
    },
    {
        'name': 'Portugal',
        'dial_code': '+351',
        'iso2': 'PT'
    },
    {
        'name': 'Puerto Rico',
        'dial_code': '+1939',
        'iso2': 'PR'
    },
    {
        'name': 'Qatar',
        'dial_code': '+974',
        'iso2': 'QA'
    },
    {
        'name': 'Romania',
        'dial_code': '+40',
        'iso2': 'RO'
    },
    {
        'name': 'Russia',
        'dial_code': '+7',
        'iso2': 'RU'
    },
    {
        'name': 'Rwanda',
        'dial_code': '+250',
        'iso2': 'RW'
    },
    {
        'name': 'Reunion',
        'dial_code': '+262',
        'iso2': 'RE'
    },
    {
        'name': 'Saint Barthelemy',
        'dial_code': '+590',
        'iso2': 'BL'
    },
    {
        'name': 'Saint Helena, Ascension and Tristan Da Cunha',
        'dial_code': '+290',
        'iso2': 'SH'
    },
    {
        'name': 'Saint Kitts and Nevis',
        'dial_code': '+1869',
        'iso2': 'KN'
    },
    {
        'name': 'Saint Lucia',
        'dial_code': '+1758',
        'iso2': 'LC'
    },
    {
        'name': 'Saint Martin',
        'dial_code': '+590',
        'iso2': 'MF'
    },
    {
        'name': 'Saint Pierre and Miquelon',
        'dial_code': '+508',
        'iso2': 'PM'
    },
    {
        'name': 'Saint Vincent and the Grenadines',
        'dial_code': '+1784',
        'iso2': 'VC'
    },
    {
        'name': 'Samoa',
        'dial_code': '+685',
        'iso2': 'WS'
    },
    {
        'name': 'San Marino',
        'dial_code': '+378',
        'iso2': 'SM'
    },
    {
        'name': 'Sao Tome and Principe',
        'dial_code': '+239',
        'iso2': 'ST'
    },
    {
        'name': 'Saudi Arabia',
        'dial_code': '+966',
        'iso2': 'SA'
    },
    {
        'name': 'Senegal',
        'dial_code': '+221',
        'iso2': 'SN'
    },
    {
        'name': 'Serbia',
        'dial_code': '+381',
        'iso2': 'RS'
    },
    {
        'name': 'Seychelles',
        'dial_code': '+248',
        'iso2': 'SC'
    },
    {
        'name': 'Sierra Leone',
        'dial_code': '+232',
        'iso2': 'SL'
    },
    {
        'name': 'Singapore',
        'dial_code': '+65',
        'iso2': 'SG'
    },
    {
        'name': 'Slovakia',
        'dial_code': '+421',
        'iso2': 'SK'
    },
    {
        'name': 'Slovenia',
        'dial_code': '+386',
        'iso2': 'SI'
    },
    {
        'name': 'Solomon Islands',
        'dial_code': '+677',
        'iso2': 'SB'
    },
    {
        'name': 'Somalia',
        'dial_code': '+252',
        'iso2': 'SO'
    },
    {
        'name': 'South Africa',
        'dial_code': '+27',
        'iso2': 'ZA'
    },
    {
        'name': 'South Sudan',
        'dial_code': '+211',
        'iso2': 'SS'
    },
    {
        'name': 'South Georgia and the South Sandwich Islands',
        'dial_code': '+500',
        'iso2': 'GS'
    },
    {
        'name': 'Spain',
        'dial_code': '+34',
        'iso2': 'ES'
    },
    {
        'name': 'Sri Lanka',
        'dial_code': '+94',
        'iso2': 'LK'
    },
    {
        'name': 'Sudan',
        'dial_code': '+249',
        'iso2': 'SD'
    },
    {
        'name': 'Suriname',
        'dial_code': '+597',
        'iso2': 'SR'
    },
    {
        'name': 'Svalbard and Jan Mayen',
        'dial_code': '+47',
        'iso2': 'SJ'
    },
    {
        'name': 'Swaziland',
        'dial_code': '+268',
        'iso2': 'SZ'
    },
    {
        'name': 'Sweden',
        'dial_code': '+46',
        'iso2': 'SE'
    },
    {
        'name': 'Switzerland',
        'dial_code': '+41',
        'iso2': 'CH'
    },
    {
        'name': 'Syrian Arab Republic',
        'dial_code': '+963',
        'iso2': 'SY'
    },
    {
        'name': 'Taiwan',
        'dial_code': '+886',
        'iso2': 'TW'
    },
    {
        'name': 'Tajikistan',
        'dial_code': '+992',
        'iso2': 'TJ'
    },
    {
        'name': 'Tanzania, United Republic of Tanzania',
        'dial_code': '+255',
        'iso2': 'TZ'
    },
    {
        'name': 'Thailand',
        'dial_code': '+66',
        'iso2': 'TH'
    },
    {
        'name': 'Timor-Leste',
        'dial_code': '+670',
        'iso2': 'TL'
    },
    {
        'name': 'Togo',
        'dial_code': '+228',
        'iso2': 'TG'
    },
    {
        'name': 'Tokelau',
        'dial_code': '+690',
        'iso2': 'TK'
    },
    {
        'name': 'Tonga',
        'dial_code': '+676',
        'iso2': 'TO'
    },
    {
        'name': 'Trinidad and Tobago',
        'dial_code': '+1868',
        'iso2': 'TT'
    },
    {
        'name': 'Tunisia',
        'dial_code': '+216',
        'iso2': 'TN'
    },
    {
        'name': 'Turkey',
        'dial_code': '+90',
        'iso2': 'TR'
    },
    {
        'name': 'Turkmenistan',
        'dial_code': '+993',
        'iso2': 'TM'
    },
    {
        'name': 'Turks and Caicos Islands',
        'dial_code': '+1649',
        'iso2': 'TC'
    },
    {
        'name': 'Tuvalu',
        'dial_code': '+688',
        'iso2': 'TV'
    },
    {
        'name': 'Uganda',
        'dial_code': '+256',
        'iso2': 'UG'
    },
    {
        'name': 'Ukraine',
        'dial_code': '+380',
        'iso2': 'UA'
    },
    {
        'name': 'United Arab Emirates',
        'dial_code': '+971',
        'iso2': 'AE'
    },
    {
        'name': 'United Kingdom',
        'dial_code': '+44',
        'iso2': 'GB',
        '_iso2': 'UK'
    },
    {
        'name': 'United States of America',
        'dial_code': '+1',
        'iso2': 'US'
    },
    {
        'name': 'Uruguay',
        'dial_code': '+598',
        'iso2': 'UY'
    },
    {
        'name': 'Uzbekistan',
        'dial_code': '+998',
        'iso2': 'UZ'
    },
    {
        'name': 'Vanuatu',
        'dial_code': '+678',
        'iso2': 'VU'
    },
    {
        'name': 'Venezuela, Bolivarian Republic of Venezuela',
        'dial_code': '+58',
        'iso2': 'VE'
    },
    {
        'name': 'Vietnam',
        'dial_code': '+84',
        'iso2': 'VN'
    },
    {
        'name': 'Virgin Islands, British',
        'dial_code': '+1284',
        'iso2': 'VG'
    },
    {
        'name': 'Virgin Islands, U.S.',
        'dial_code': '+1340',
        'iso2': 'VI'
    },
    {
        'name': 'Wallis and Futuna',
        'dial_code': '+681',
        'iso2': 'WF'
    },
    {
        'name': 'Yemen',
        'dial_code': '+967',
        'iso2': 'YE'
    },
    {
        'name': 'Zambia',
        'dial_code': '+260',
        'iso2': 'ZM'
    },
    {
        'name': 'Zimbabwe',
        'dial_code': '+263',
        'iso2': 'ZW'
    }
]

export const COMPANYTYPE = [
    {
        label: 'Sole Proprietorship',
        value: 'Sole Proprietorship'
    },
    {
        label: 'Partnership',
        value: 'Partnership'
    },
    {
        label: 'Corporation',
        value: 'Corporation'
    },
    {
        label: 'Limited Liability Company',
        value: 'Limited Liability Company'
    },
    {
        label: 'Cooperative',
        value: 'Cooperative'
    },
    {
        label: 'Solo trader',
        value: 'Solo trader'
    },
    {
        label: 'Freelancer',
        value: 'Freelancer'
    },
    {
        label: 'Public Limited Company',
        value: 'Public Limited Company'
    },
    {
        label: 'Private Limited Company',
        value: 'Private Limited Company'
    },
    {
        label: 'Unlimited Company',
        value: 'Unlimited Company'
    },
    {
        label: 'Closed Corporation',
        value: 'Closed Corporation'
    },
    {
        label: 'PTY Limited',
        value: 'PTY Limited'
    },
    {
        label: 'Estate',
        value: 'Estate'
    },
    {
        label: 'Association',
        value: 'Association'
    },
    {
        label: 'Club',
        value: 'Club'
    },
    {
        label: 'Trust',
        value: 'Trust'
    },
]
export const  MAIL_URL =  "https://api.paystreet.io/contactRequestMail";
export const  CURRENCY_URL =  "https://api.paystreet.io/conversion/currency-list";
export const  CURRENCY_CONVERT_URL =  "https://api.paystreet.io/conversions/web-quote";
export const  SubscribtionMailurl = "https://api.paystreet.io/subscriptionMail"
export const AccountOpeningMail = "https://api.paystreet.io/accountOpeningMail"

// export const  FROM_CURRENCY = ['USD', 'SGD', 'EUR', 'GBP', 'HKD', 'IDR', 'JPY', 'PHP', 'THB', 'AED', 'AUD', 'BDT', 'BND', 'CAD', 'CHF', 'CNH', 'CZK', 'DKK', 'HRK', 'ILS', 'INR', 'KES', 'KRW', 'KWD', 'LKR', 'MXN', 'MYR', 'NOK', 'NPR', 'NZD', 'OMR', 'PKR', 'PLN', 'SAR', 'SEK', 'TRY', 'VND', 'ZAR'];

// export const  TO_CURRENCY = ['USD', 'SGD', 'EUR', 'GBP', 'HKD', 'JPY', 'AUD', 'CAD', 'CHF', 'CNH', 'NOK', 'NZD', 'SEK'];

export const  CURRENCIES = [

    {
        code: 'AED',

        symbol: 'إ.د',

        name: 'UAE Dirham',

        country: {
            name: 'United Arab Emirates',

            iso3: 'ARE',

            iso2: 'AE',
        }
    },

    {
        code: 'AUD',

        symbol: '$',

        name: 'Australian Dollar',

        country: {
            name: 'Australia',

            iso3: 'AUS',

            iso2: 'AU',
        }
    },

    {
        code: 'BDT',

        symbol: 'B$',

        name: 'Bangladeshi Taka',

        country: {
            name: 'Brunei',

            iso3: 'BRN',

            iso2: 'BD',
        }
    },

    {
        code: 'BND',

        symbol: 'B$',

        name: 'Brunei Dollar',

        country: {
            name: 'Brunei',

            iso3: 'BRN',

            iso2: 'BN',
        }
    },

    {
        code: 'CAD',

        symbol: '$',

        name: 'Canadian Dollar',

        country: {
            name: 'Canada',

            iso3: 'CAN',

            iso2: 'CA',
        }
    },

    {
        code: 'CHF',

        symbol: 'CHf',

        name: 'Swiss Franc',

        country: {
            name: 'Switzerland',

            iso3: 'CHE',

            iso2: 'CH',
        }
    },

    {
        code: 'CNH',

        symbol: 'CN¥',

        name: 'Chinese Yuan Renminbi',

        country: {
            name: 'China',

            iso3: 'CHN',

            iso2: 'CN',
        }
    },

    {
        code: 'CNY',

        name: 'Chinese Yuan Renminbi',

        symbol: '¥',

        country: {
            name: 'China',

            iso3: 'CHN',

            iso2: 'CN',
        }
    },

    {
        code: 'EUR',

        symbol: '€',

        name: 'Euro',

        country: {
            name: 'European Union',

            iso2: 'EU',
        }
    },

    {
        code: 'GBP',

        symbol: '£',

        name: 'Pound Sterling',

        country: {
            name: 'Guernsey and Alderney',

            iso3: 'GGY',

            iso2: 'GB',
        }
    },

    {
        code: 'HKD',

        symbol: '$',

        name: 'Hong Kong Dollar',

        country: {
            name: 'Hong Kong S.A.R.',

            iso3: 'HKG',

            iso2: 'HK',
        }
    },

    {
        code: 'IDR',

        symbol: 'Rp',

        name: 'Indonesian Rupiah',

        country: {
            name: 'Indonesia',

            iso3: 'IDN',

            iso2: 'ID',
        }
    },

    {
        code: 'INR',

        symbol: '₹',

        name: 'Indian Rupee',

        country: {
            name: 'India',

            iso3: 'IND',

            iso2: 'IN',
        }
    },

    {
        code: 'JPY',

        symbol: '¥',

        name: 'Japanese Yen',

        country: {
            name: 'Japan',

            iso3: 'JPN',

            iso2: 'JP',
        }
    },

    {
        code: 'KHR',

        symbol: 'KHR',

        name: 'Cambodian Riel',

        country: {
            name: 'Cambodia',

            iso3: 'KHM',

            iso2: 'KH',
        }
    },

    {
        code: 'KPW',

        symbol: '₩',

        name: 'North Korean Won',

        country: {
            name: 'North Korea',

            iso3: 'PRK',

            iso2: 'KP',
        }
    },

    {
        code: 'KRW',

        symbol: '₩',

        name: 'Korean Won',

        country: {
            name: 'South Korea',

            iso3: 'KOR',

            iso2: 'KR',
        }
    },

    {
        code: 'LKR',

        symbol: 'Rs',

        name: 'Sri Lankan Rupee',

        country: {
            name: 'Sri Lanka',

            iso3: 'LKA',

            iso2: 'LK',
        }
    },

    {
        code: 'MXN',

        symbol: '$',

        name: 'Mexican Peso',

        country: {
            name: 'Mexico',

            iso3: 'MEX',

            iso2: 'MX',
        }
    },

    {
        code: 'MYR',

        name: 'Malaysian Ringgit',

        symbol: 'RM',

        country: {
            name: 'Malaysia',

            iso3: 'MYS',

            iso2: 'MY',
        }
    },

    {
        code: 'NOK',

        symbol: 'kr',

        name: 'Norwegian Krone',

        country: {
            name: 'Bouvet Island',

            iso3: 'BVT',

            iso2: 'BV',
        }
    },

    {
        code: 'NPR',

        symbol: '₨',

        name: 'Nepalese Rupee',

        country: {
            name: 'Nepal',

            iso3: 'NPL',

            iso2: 'NP',
        }
    },

    {
        code: 'NZD',

        symbol: '$',

        name: 'New Zealand Dollar',

        country: {
            name: 'Niue',

            iso3: 'NIU',

            iso2: 'NZ',
        }
    },

    {
        code: 'PHP',

        symbol: '₱',

        name: 'Philippine Peso',

        country: {
            name: 'Philippines',

            iso3: 'PHL',

            iso2: 'PH',
        }
    },

    {
        code: 'PKR',

        symbol: '₨',

        name: 'Pakistani Rupee',

        country: {
            name: 'Pakistan',

            iso3: 'PAK',

            iso2: 'PK',
        }
    },

    {
        code: 'SAR',

        symbol: '﷼',

        name: 'Saudi Riyal',

        country: {
            name: 'Saudi Arabia',

            iso3: 'SAU',

            iso2: 'SA',
        }
    },

    {
        code: 'SEK',

        symbol: 'kr',

        name: 'Swedish Krona',

        country: {
            name: 'Sweden',

            iso3: 'SWE',

            iso2: 'SE',
        }
    },

    {
        code: 'SGD',

        symbol: '$',

        name: 'Singapore Dollar',

        country: {
            name: 'Singapore',

            iso3: 'SGP',

            iso2: 'SG',
        }
    },

    {
        code: 'THB',

        symbol: '฿',

        name: 'Thai Baht',

        country: {
            name: 'Thailand',

            iso3: 'THA',

            iso2: 'TH',
        }
    },

    {
        code: 'TRY',

        symbol: '₺',

        name: 'Turkish Lira',

        country: {
            name: 'Turkey',

            iso3: 'TUR',

            iso2: 'TR',
        }
    },

    {
        code: 'USD',

        symbol: '$',

        name: 'United States Dollar',

        country: {
            name: 'American Samoa',

            iso3: 'USA',

            iso2: 'US',
        }
    },

    {
        code: 'VND',

        symbol: '₫',

        name: 'Vietnamese Dong',

        country: {
            name: 'Vietnam',

            iso3: 'VNM',

            iso2: 'VN',
        }
    },

    {
        code: 'CZK',

        symbol: 'Kč',

        name: 'Czech Koruna',

        country: {
            name: 'Czech Republic',

            iso3: 'CZE',

            iso2: 'CZ',
        }
    },

    {
        code: 'DKK',

        symbol: 'kr',

        name: 'Danish Krone',

        country: {
            name: 'Denmark',

            iso3: 'DNK',

            iso2: 'DK',
        }
    },

    {
        code: 'HRK',

        symbol: 'kn',

        name: 'Croatian Kuna',

        country: {
            name: 'Croatia',

            iso3: 'HRV',

            iso2: 'HR',
        }
    },

    {
        code: 'ILS',

        symbol: '₪',

        name: 'Israeli New Shekel',

        country: {
            name: 'Israel',

            iso3: 'ISR',

            iso2: 'IL',
        }
    },

    {
        code: 'KES',

        symbol: 'Ksh',

        name: 'Kenyan Shilling',

        country: {
            name: 'Kenya',

            iso3: 'KEN',

            iso2: 'KE',
        }
    },

    {
        code: 'KWD',

        symbol: 'د.ك',

        name: 'Kuwaiti Dinar',

        country: {
            name: 'Kuwait',

            iso3: 'KWT',

            iso2: 'KW',
        }
    },

    {
        code: 'OMR',

        symbol: 'ر.ع.',

        name: 'Omani Rial',

        country: {
            name: 'Oman',

            iso3: 'OMN',

            iso2: 'OM',
        }
    },

    {
        code: 'PLN',

        symbol: 'zł',

        name: 'Polish Złoty',

        country: {
            name: 'Poland',

            iso3: 'POL',

            iso2: 'PL',
        }
    },

    {
        code: 'ZAR',

        symbol: 'R',

        name: 'South African Rand',

        country: {
            name: 'South Africa',

            iso3: 'ZAF',

            iso2: 'ZA',
        }
    }
]
export const currency25percent = ["USD", "SGD", "GBP", "EUR"];

export const numericecurrency = [
    { price: 1000 },
    { price: 2000 },
    { price: 5000 },
    { price: 10000 },
    { price: 50000 },
    { price: 100000 },
];

export const bankingFixedCharges = 3/100;
// arr.indexOf("bob") > -1

// const currencyFlag = [];

// // 👇️ can use forEach outside of your JSX code
// // if you need to call a function once for each array element
// currencies.forEach((currency, index) => {
//     if(arr.indexOf("bob") > -1){
//     currencyFlag.push();
//     }
// });