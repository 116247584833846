import React, { useState, useEffect } from "react";
// import { HashLink } from 'react-router-hash-link';
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookShareCount,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  LineShareButton,
} from "react-share";
import blogSlider from "../../assets/images/blog/blog-silder.png";
import blogCart1 from "../../assets/images/blog/blog-1.png";
import blogCart2 from "../../assets/images/blog/blog-2.png";
import blogCart3 from "../../assets/images/blog/blog-3.png";

import shareIcon from "../../assets/images/blog/share.svg";
import fbImg from "../../assets/images/blog/fb.svg";
import inImg from "../../assets/images/blog/in.svg";
import twImg from "../../assets/images/blog/tw.svg";
import mailImg from "../../assets/images/blog/mail.svg";
import copyImg from "../../assets/images/blog/copy.svg";
import { Helmet } from "react-helmet";
import { blogArr } from "./Blog-data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubscribeComponent from "../components/Subscribe/SubscribeComponent";

function BlogSinglebuildingPayments() {
  const [blogsingle, setBlogsingle] = useState([]);

  var blog = [];
  const params = useParams();

  blogArr.forEach((item) => {
    if (
      item.title.toLowerCase().replace(/ /g, "-") ===
      "building-an-efficient-system-for-payments-of-tomorrow"
    ) {
      blog = item;
    }
  });

  async function copyToClip() {
    await navigator.clipboard.writeText(window.location.href);
    notify();
  }
  const notify = () => toast("Copied");
  return (
    <>
      <Helmet>
        <title>{`Paystreet: ${blog.title}`}</title>
        <meta name="description" content={`${blog.shortDescription.substring(0, 155)}...`}></meta>

        {/* Open Graph meta tags for Facebook */}
        <meta property="og:image" content={`${blog.image}`} />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta
          name="twitter:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />
        <meta name="twitter:image" content={`${blog.image}`} />

        {/* LinkedIn Article Rich Media meta tags */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />
        <meta property="og:image" content={`${blog.image}`} />
      </Helmet>
      <div
        className="mobile_responsive_blog color-tnc-indigo"
        style={{ transition: "background-color 1s ease" }}
        data-color="indigo">
        <div className="wrapper-lite blog-single">
          <HeaderMenuLite />
          <div className="container">
            <div className="blog-single-img-outer">
              <img src={blogSlider} alt="..." className="img-fluid" />
            </div>
            <div className="row">
              <div className="col-lg-11 col-md-10">
                <div className="blog-content-single">
                  <div className="blog-date-time">
                    PayStreet team <span>•</span>May 22, 2023<span>•</span>5
                    mins read
                  </div>
                  <h2 className="text-green page_sub_title mb-3 ">
                    Building an efficient system for Payments of Tomorrow{" "}
                  </h2>
                  <p>
                    We know that cross-border payments have already become quick
                    and transparent, with far less friction preventing them from
                    arriving at their destination on time – but there’s still
                    room for improvement. A study conducted by SWIFT (Society of
                    Worldwide Interbank Financial Telecommunication)
                    contemplated what brighter payments future could look like,
                    and how the financial community can build it together.
                    Excerpts from the study are being shared with the users for
                    their benefit. <br />
                  </p>

                  <p>
                    Cross-border payments have come a long way. Thanks to recent
                    improvements, they’re now faster than ever, with more
                    transparency and less friction. But there’s still more
                    progress to make – so what about the future? Could there be
                    a world in which instant payments flow seamlessly through
                    interconnected systems, and where payments are embedded
                    almost invisibly into the customer experience? <br />
                  </p>
                  <p>
                    From instant payments to central bank digital currencies
                    (CBDCs), a range of new and emerging digital developments
                    have the potential to transform the face of the cross-border
                    landscape, alongside the industry-wide transition to ISO
                    20022. <br />
                  </p>

                  <p>
                    Swift official Thierry Chilosi notes that in recent years,
                    major improvements have been brought to cross-border
                    payments made across SWIFT network, with end-to-end
                    tracking, transparency on fees and FX rates, and the ability
                    for transactions to complete in minutes or seconds. However,
                    there is still scope for improvements. <br />
                  </p>
                  <p>
                    The G20 has outlined a roadmap to improve the speed, cost,
                    transparency, choice and accessibility of cross-border
                    payments, and new developments are coming thick and fast to
                    help realise these goals. Following factors are driving
                    these changes: <br />
                  </p>
                  <p>
                    <strong>Heightened competition:</strong> The core payments
                    space is seeing greater competition at all levels, from
                    alternative providers and fintechs to large, established
                    players. <br />
                  </p>
                  <p>
                    <strong>Building connections:</strong> Domestic market
                    infrastructures are increasingly looking to connect and
                    interoperate with each other. This is leading to
                    explorations of new ways to settle payments, from CBDCs to
                    instant payments. <br />
                  </p>
                  <p>
                    <strong>Front-end innovation:</strong> Significant
                    development is also taking place on the front end, including
                    the rise of new apps and ecommerce platforms. <br />
                  </p>
                  <p>
                    In order to achieve better efficiencies, the financial
                    institutions should constantly improve their operations by
                    working towards a world in which every payment is
                    pre-validated, frictionless and instant. A Payment
                    Pre-validation service, for example, reduces the risk that
                    transactions will need to be returned or repaired by
                    allowing banks to check key information – such as whether a
                    specific account exists, or whether a purpose code is needed
                    in a particular country – before a payment is sent. And
                    Swift Go is improving the cross-border payment experience
                    for consumers and small and medium-sized enterprises (SMEs),
                    by facilitating quick, easy and predictable transfers that
                    can be sent directly from an end-customer’s bank account.
                    <br />
                  </p>
                  <p>
                    The global move to ISO 20022, meanwhile, is more than just a
                    mandatory exercise. Instead, it's a key factor in driving
                    innovation and building a frictionless future – offering new
                    opportunities and the chance to enhance services all along a
                    transaction's lifecycle.
                    <br />
                  </p>
                  <p>
                    Where interoperability is concerned, we have a major role to
                    play in connecting market infrastructures, enabling CBDCs to
                    work alongside existing infrastructure, and avoiding the
                    risk of fragmentation. Recent experiments have made major
                    progress on this front, successfully demonstrating how CBDCs
                    can be linked with existing RTGS payment systems.
                    <br />
                  </p>
                  <p>
                    Further, the banks should provide innovative services in the
                    front-end and embed their services into the ecommerce
                    transaction experience. One notable example is SWIFT gpi.
                    “We’ve created ways to embed the data from gpi in our
                    front-end systems,” explained John Hunter, Executive Vice
                    President, Payments and Transaction Service at Wells Fargo
                    Global Treasury Management. “We’ve created services where
                    clients can get that data and feed it into their ERPs and do
                    things like auto reconciliation. What a huge difference that
                    service has made.”
                    <br />
                  </p>
                  <p>
                    <strong class="blog-para-title">
                      Benefits across the community
                    </strong>
                    <br />
                    <br />
                    With seamless, transparent payments fully embedded into the
                    customer journey, the payments experience itself will
                    essentially become invisible – creating a brighter financial
                    future for all. This landscape will also include diverse
                    providers, competitive services and a high degree of
                    interoperability. But how will this benefit consumers,
                    corporates and banks?
                    <br />
                  </p>
                  <p>
                    For consumers and SMEs, the most significant improvements
                    will be found in achieving greater transparency,
                    cost-efficiency and ease of use. For SMEs, better structured
                    information will mean that incoming payments can be
                    reconciled more easily. Businesses will therefore be able to
                    use the funds they receive to pay their suppliers faster,
                    instead of having to wait for payments to clear before
                    moving onto their next project.
                    <br />
                  </p>
                  <p>
                    For larger corporates and their treasury teams, cash and
                    liquidity continues to be the overriding consideration.
                    Wholesale payments have already been sped up, with more
                    transparency over their status and progress through the
                    transaction lifecycle – so the next big opportunity lies in
                    improving liquidity management.
                    <br />
                  </p>
                  <p>
                    With interest rates rising and economic conditions
                    presenting a significant challenge, companies need to
                    pinpoint how much cash is available for making investments.
                    Here, SWIFT and banks are working with the community to
                    bring corporate customers more visibility over their account
                    balances and statements in real time using APIs.
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-1 col-md-2">
                <div className="blog-social-fixed">
                  <ul>
                    <li>
                      <span className="social-media">
                        <img src={shareIcon} />
                      </span>
                    </li>
                    <li>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={blog.title}>
                        <img src={fbImg} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton url={window.location.href}>
                        <img src={inImg} />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={window.location.href}>
                        <img src={twImg} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <span className="social-media">
                        <img src={copyImg} onClick={copyToClip} />
                        <ToastContainer
                          position="bottom-right"
                          autoClose={5000}
                          hideProgressBar
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="blog-related">
              <div className="row">
                <h2>Related blogs</h2>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/iso-20022"}>
                    <img src={blogCart2} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 8, 2023<span>•</span>5 mins
                    read
                  </div>
                  <h4 className="related-title">
                    <a href={"/iso-20022"}>
                      ISO 20022 <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>ISO 20022 is a standards development methodology. </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Payments</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/cbdc"}>
                    <img src={blogCart3} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 15, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/cbdc"}>
                      Central Bank Digital Currency{" "}
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    Central bank digital currencies (CBDCs) are a form of
                    digital...{" "}
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/blog-single"}>
                    <img src={blogCart1} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 29, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/blog-single"}>
                      Neo Banks vs Traditional Banks{" "}
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    The Neo-banks are changing the canvas of fin-tech by rapidly
                    bridging...{" "}
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bg">
          {/* <section className="testimonial_section"> */}
          <div className="container scale_business_section contact-subtribe">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <SubscribeComponent 
                        heading={<><span>Start crossing borders with ease.</span>
                          <br></br> Unlock a world of possibilities for your business
                          with just a few clicks.</>}
                        subheading={<p className="text-white mtb">Join our community today.</p>}
                        showVerticle={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </section> */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default BlogSinglebuildingPayments;
