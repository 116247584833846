import React from "react";
import HeaderMenu from "../partials/HeaderMenu";
import Footer from "../partials/Footer";
import { REGISTERURL } from "../../constant";

import globe from "../../assets/images/about/globe-latam.png";
import groupImage from "../../assets/images/about/goup-image.jpg";
import box5_1 from "../../assets/images/about/5-box-icon-1.svg";
import box5flip_1 from "../../assets/images/about/5-box-icon-h-1.png";
import box5_2 from "../../assets/images/about/5-box-icon-2.png";
import box5flip_2 from "../../assets/images/about/5-box-icon-h-2.png";
import box5_3 from "../../assets/images/about/5-box-icon-3.png";
import box5flip_3 from "../../assets/images/about/5-box-icon-h-3.png";
import box5_4 from "../../assets/images/about/5-box-icon-4.png";
import box5flip_4 from "../../assets/images/about/5-box-icon-h-4.png";
import box5_5 from "../../assets/images/about/5-box-icon-5.png";
import box5flip_5 from "../../assets/images/about/5-box-icon-h-5.png";

function AboutUs() {
    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    setTimeout(() => {
        const urlParams = window.location.hash;
        if (urlParams === '#why-us-section') {
            scrollToElement('why-us-section'); 
        } else if (urlParams === '#ethos-scroll-section') {
            scrollToElement('ethos-scroll-section');
        } else if (urlParams === '#mission') {
            scrollToElement('mission');
        }
    }, 1500);

    return (
        <>
            <div className="wrapper color_change about-page" data-color="black" style={{ transition: "background-color 1s ease" }}>
                <div className="background_shadow">
                    <HeaderMenu />
                    <section className="about-mb background_img">
                        <div className="banner_section ">
                            <div className="container ">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="subpage_banner_section">
                                            <p className="text-lightgeeen text-start banner_p l-space-2  mb-29">About us</p>
                                            <h1 className="mx-auto text-start banner_h1 "><span className="text-green"> Empowering</span></h1>
                                            <h1 className="mx-auto text-start banner_h1 mb-34">entrepreneurs to transact globally with confidence.</h1>
                                            <span id="why-us-section"></span>
                                            <h4 className="text-start banner_h3 text-white mb-100">Cross border payment and FX solutions for fast growing businesses.</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="about-bnr-img">
                                            <img src={globe} alt="..." className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="about-banner-box d-xl-none d-block">
                                            <h1 className="text-white mb-3 mt-2">Why choose us?</h1>
                                            <p className="text-about-lowerlap">Opening a bank account abroad under your business name comes with a lot of difficulties. It is more challenging than opening one domestically. Be it taking an appointment with the bank representative, navigating through the regulatory hassles or handling complex paperwork, the stressful delays that come along with it can severely impact the performance of your business and constrain new revenue streams.
                                            </p>
                                            <p className="text-about-lowerlap">
                                                The challenge, however, does not end there. Sending and receiving
                                                money can still be an intricate and time-consuming process even for
                                                companies that possess a global bank account under their name.
                                            </p>
                                            <p className="text-about-lowerlap">
                                                At PayStreet, we appreciate the pain points associated with moving
                                                money internationally. That’s why we are here to help.
                                            </p>
                                            <p className="text-about-lowerlap">
                                                Whether you need to pay suppliers overseas, receive payments from
                                                customers in different countries in their local currency, convert
                                                money to a different currency or manage your foreign currency
                                                transactions, we have got you covered!</p>
                                        </div>
                    <div className="relative">
                        <div className="container d-none d-xl-block" >
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="relative">
                                        <div className="about-banner-box">
                                            <h1 className="text-white mb-3 mt-2">Why choose us?</h1>
                                            <p className="text-about-lowerlap">Opening a bank account abroad under your business name comes with a lot of difficulties. It is more challenging than opening one domestically. Be it taking an appointment with the bank representative, navigating through the regulatory hassles or handling complex paperwork, the stressful delays that come along with it can severely impact the performance of your business and constrain new revenue streams.
                                            </p>
                                            <p className="text-about-lowerlap">
                                                The challenge, however, does not end there. Sending and receiving
                                                money can still be an intricate and time-consuming process even for
                                                companies that possess a global bank account under their name.
                                            </p>
                                            <p className="text-about-lowerlap">
                                                At PayStreet, we appreciate the pain points associated with moving
                                                money internationally. That’s why we are here to help.
                                            </p>
                                            
                                            <p className="text-about-lowerlap">
                                                Whether you need to pay suppliers overseas, receive payments from
                                                customers in different countries in their local currency, convert
                                                money to a different currency or manage your foreign currency
                                                transactions, we have got you covered!</p><span id="mission"></span>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <img src={groupImage} alt="..." className="img-fluid" />
                        </div>
                    </div>
                    
                    <section className="IP-about-page-our">
                        <div className="container mt-146">
                            <div className="row">
                                <div className="col-xl-5">
                                    <h1 className="mx-auto text-white">Our <span className="text-green"> mission</span></h1>
                                </div>
                                <div className="col-xl-7">
                                    <p className="text-lightgeeen text-start">Our mission is to make international payments fast, secure and
                                        seamless for businesses of all sizes</p>
                                    <p className="text-start about-us-para text-white mb-0">At PayStreet, we believe in transparency, integrity and customer
                                        satisfaction. We understand that cross-border payments can be
                                        challenging, expensive, and time-consuming. Our platform is
                                        designed to simplify the process and help you collect funds and
                                        make payments with ease, no matter where you are in the world.</p>
                                </div>
                            </div>
                            <hr className="about-us-hr-line" />
                            <div className="row">
                                <div className="col-xl-5">
                                    <h1 className="mx-auto text-white">Our <span className="text-green"> team</span></h1>
                                </div>
                                <div className="col-xl-7">
                                    <p className="text-lightgeeen text-start">Our seasoned pros have over 20 years of expertise in handling
                                        international transfer!
                                    </p>
                                    <p className="text-start about-us-para text-white mb-0">We are dedicated to delivering exceptional service and support, so
                                        you can focus on growing your business. Ours is a one-stop shop
                                        that offers complete solution for your company formation,
                                        international payment and financing needs. Whether it'
                                        s a technical
                                        issue, a payment-related question, or anything else, we are always
                                        available to help.
                                    </p>
                                </div>
                            </div>
                            <hr className="about-us-hr-line" />
                            <div className="row">
                                <div className="col-xl-5">
                                    <h1 className="mx-auto text-white">Our <span className="text-green"> technology</span></h1>
                                </div>
                                <div className="col-xl-7">
                                    <p className="text-lightgeeen text-start ">Our cutting-edge technology ensures that your payments are
                                        always processed quickly and securely</p>
                                    <p className="text-start about-us-para text-white mb-0">For all exchanges with partner banks, we use advanced encryption and security protocols to protect your transactions. Two-factor authentication ensures that no unwanted entity has access to your sensitive data such as payment or recipient details. In addition to complying with the relevant regulations and industry standards, we conduct regular digital audits to test our platform for vulnerabilities.</p>
                                    <span id="ethos-scroll-section"></span>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="IP-about-page-our" id="contact-form-section">
                        <div className="container mt-146">
                            <div className="row gx-2">
                                <div className="col-xl-12">
                                    <h1 className="text-center text-white">Ethos - 5 things we value</h1>
                                    <p className="text-center" style={{ fontFamily: "'Poppins'" }}>Strong values that ensure convenient, secure and affordable services to our users
                                    </p>
                                </div>
                            </div>
                            <div className="row gx-2 mt-5">
                                <div className="col ">
                                    <div className="flip-card">
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front ethos1-front">
                                                <h4 className="mx-auto text-white">Putting
                                                    customers
                                                    first
                                                </h4>
                                                <div className="about-image-icon">
                                                    <img src={box5_1} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                            <div className="flip-card-back ethos1-back">
                                                We have built the platform keeping user experience in mind. A user-friendly interface, quick and credible support along with reliable uptime ensure a seamless experience for the user.
                                                <div className="about-image-icon">
                                                    <img src={box5flip_1} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col  ">
                                    <div className="flip-card">
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front ethos2-front">
                                                <h4 className="mx-auto text-white">Global access, local rates
                                                </h4>
                                                <div className="about-image-icon">
                                                    <img src={box5_2} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                            <div className="flip-card-back ethos2-back">
                                                We are committed to offer competitive exchange rates and low fees for cross border transactions. We want you to save money on transaction costs and grow faster.
                                                <div className="about-image-icon">
                                                    <img src={box5flip_2} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="flip-card">
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front ethos3-front">
                                                <h4 className="mx-auto text-white">Pay only what you see, never more
                                                </h4>
                                                <div className="about-image-icon">
                                                    <img src={box5_3} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                            <div className="flip-card-back ethos3-back">
                                                We are transparent in pricing and policies. We put our markup and fees upfront without any hidden charges, thus ensuring clear, visible and reliable transactions.
                                                <div className="about-image-icon">
                                                    <img src={box5flip_3} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col ">
                                    <div className="flip-card">
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front ethos4-front">
                                                <h4 className="mx-auto text-white">Global reach, seamless access
                                                </h4>
                                                <div className="about-image-icon">
                                                    <img src={box5_4} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                            <div className="flip-card-back ethos4-back">
                                                Our hardworking team ensures that the platform loads fast and is accessible to users from all parts of the world with support available whenever required.
                                                <div className="about-image-icon">
                                                    <img src={box5flip_4} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="flip-card">
                                        <div className="flip-card-inner">
                                            <div className="flip-card-front ethos5-front">
                                                <h4 className="mx-auto text-white">Unmatched security you deserve
                                                </h4>
                                                <div className="about-image-icon">
                                                    <img src={box5_5} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                            <div className="flip-card-back ethos5-back">
                                                Our latest technology and security features like encryption and
                                                two-factor authentication ensure that your information always remains safe.
                                                <div className="about-image-icon">
                                                    <img src={box5flip_5} alt="..." className="img-fluid-icon" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </section>
                </div>

                <div className="footer-bg">
                    <div className="scale_business_section">
                        <div className="container">
                            <div className="row">
                                <h2><span>Connect the world, transact with ease.</span>
                                </h2>
                                <h2>Seize the opportunity now! Make an appointment with yourself to open a PayStreet account.  </h2>
                                <p className="text-white text-start mtb">Open an account within minutes.</p>
                                <div className="tab-center mob-center">
                                    <a href="/contact"><button type="button" className="btn button_white me-4">Talk to us
                                        <i className="fal fa-long-arrow-right"></i>
                                    </button></a>
                                    <a href={REGISTERURL}><button type="button" className="btn button_white get_started">Get started
                                        <i className="fal fa-long-arrow-right"></i>
                                    </button> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default AboutUs;