import React, { useState, useEffect } from "react";
// import { HashLink } from 'react-router-hash-link';
import HeaderMenuLite from "../partials/HeaderMenuLite";
import Footer from "../partials/Footer";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookShareCount,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  LineShareButton,
} from "react-share";

import blogSlider from "../../assets/images/blog/central-bank.png";
import blogCart1 from "../../assets/images/blog/blog-1.png";
import blogCart2 from "../../assets/images/blog/blog-2.png";
import blogCart3 from "../../assets/images/blog/bulding-payment.png";

import shareIcon from "../../assets/images/blog/share.svg";
import fbImg from "../../assets/images/blog/fb.svg";
import inImg from "../../assets/images/blog/in.svg";
import twImg from "../../assets/images/blog/tw.svg";
import mailImg from "../../assets/images/blog/mail.svg";
import copyImg from "../../assets/images/blog/copy.svg";
import { Helmet } from "react-helmet";
import { blogArr } from "./Blog-data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubscribeComponent from "../components/Subscribe/SubscribeComponent";

function BlogSinglecbdc() {
  const [blogsingle, setBlogsingle] = useState([]);

  var blog = [];
  const params = useParams();

  blogArr.forEach((item) => {
    if (
      item.title.toLowerCase().replace(/ /g, "-") ===
      "central-bank-digital-currency-(cbdc)"
    ) {
      blog = item;
    }
  });
  

  async function copyToClip() {
    await navigator.clipboard.writeText(window.location.href);
    notify();
  }
  const notify = () => toast("Copied");

  return (
    <>
      <Helmet>
        <title>{`Paystreet: ${blog.title}`}</title>
        <meta name="description" content={`${blog.shortDescription.substring(0, 155)}...`}></meta>

        {/* Open Graph meta tags for Facebook */}
        <meta property="og:image" content={`${blog.image}`} />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta
          name="twitter:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />
        <meta name="twitter:image" content={`${blog.image}`} />

        {/* LinkedIn Article Rich Media meta tags */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog.title} />
        <meta
          property="og:description"
          content={`${blog.shortDescription.substring(0, 155)}...`}
        />
        <meta property="og:image" content={`${blog.image}`} />
      </Helmet>
      <div
        className="mobile_responsive_blog color-tnc-indigo"
        style={{ transition: "background-color 1s ease" }}
        data-color="indigo">
        <div className="wrapper-lite blog-single">
          <HeaderMenuLite />
          <div className="container">
            <div className="blog-single-img-outer">
              <img src={blogSlider} alt="..." className="img-fluid" />
            </div>
            <div className="row">
              <div className="col-lg-11 col-md-10">
                <div className="blog-content-single">
                  <div className="blog-date-time">
                    PayStreet team <span>•</span>May 15, 2023<span>•</span>5
                    mins read
                  </div>
                  <h2 className="text-green page_sub_title mb-3 ">
                    Central Bank Digital Currency (CBDC){" "}
                  </h2>
                  <p>
                    Central bank digital currencies (CBDCs) are a form of
                    digital currency issued by a country’s central bank. They
                    are similar to cryptocurrencies, except that their value is
                    fixed by the central bank. <br />
                  </p>

                  <p>
                    What we regularly use is Fiat Money. Fiat money is a
                    government-issued currency that has no backing from a
                    physical commodity like gold or silver. It is considered a
                    form of legal tender that can be used to exchange for goods
                    and services. Traditionally, fiat money came as banknotes
                    and coins, but technology has allowed governments and
                    financial institutions to supplement physical fiat money
                    with a credit-based model that records balances and
                    transactions digitally. <br />
                  </p>
                  <p>
                    Physical currency is still widely exchanged and accepted;
                    however, some developed countries have experienced a drop in
                    its use, and that trend accelerated during the pandemic. The
                    Bank for International Settlements (BIS) is a recognised
                    international financial institution offering banking
                    services for national central banks and a forum for
                    discussing monetary and regulatory policies. BIS has noted
                    that the interest in CBDC has grown in response to changes
                    in payments, finance and technology, as well as the
                    disruption caused by Covid-19. A 2021 BIS survey of central
                    banks found that 86% are actively researching the potential
                    for CBDCs, 60% were experimenting with the technology and
                    14% were deploying pilot projects.
                    <br />
                  </p>

                  <p>
                    In simple terms, a central bank digital currency (CBDC)
                    would be a digital banknote. It could be used by individuals
                    to pay businesses, shops or each other (a “retail CBDC”), or
                    between financial institutions to settle trades in financial
                    markets (a “wholesale CBDC”). <br />
                  </p>
                  <p>
                    Central banks are exploring whether CBDC could help them to
                    achieve their public good objectives, such as safeguarding
                    public trust in money, maintaining price stability and
                    ensuring safe and resilient payment systems and
                    infrastructure. BIS further notes that if successful, CBDCs
                    could ensure that, as economies go digital, the general
                    public would retain access to the safest form of money - a
                    claim on a central bank. This could promote diversity in
                    payment options, make cross-border payments faster and
                    cheaper, increase financial inclusion and possibly
                    facilitate fiscal transfers in times of economic crisis
                    (such as a pandemic). <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-1 col-md-2">
                <div className="blog-social-fixed">
                  <ul>
                    <li>
                      <span className="social-media">
                        <img src={shareIcon} />
                      </span>
                    </li>
                    <li>
                      <FacebookShareButton
                        url={window.location.href}
                        quote={blog.title}>
                        <img src={fbImg} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton url={window.location.href}>
                        <img src={inImg} />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url={window.location.href}>
                        <img src={twImg} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <span className="social-media">
                        <img src={copyImg} onClick={copyToClip} />
                        <ToastContainer
                          position="bottom-right"
                          autoClose={5000}
                          hideProgressBar
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="blog-related">
              <div className="row">
                <h2>Related blogs</h2>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/iso-20022"}>
                    <img src={blogCart2} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 8, 2023<span>•</span>5 mins
                    read
                  </div>
                  <h4 className="related-title">
                    <a href={"/iso-20022"}>
                      ISO 20022 <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>ISO 20022 is a standards development methodology. </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Payments</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/efficient-system-for-payment"}>
                    <img src={blogCart3} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 22, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/efficient-system-for-payment"}>
                      Building an efficient system...{" "}
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    We know that cross-border payments have already become quick
                    and...
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-62">
                  <a href={"/blog-single"}>
                    <img src={blogCart1} alt="..." className="img-fluid" />
                  </a>
                  <div className="blog-date-time py-2">
                    PayStreet team <span>•</span>May 29, 2023<span>•</span>5
                    mins read
                  </div>
                  <h4 className="related-title">
                    <a href={"/blog-single"}>
                      Neo Banks vs Traditional Banks{" "}
                      <i className="fal fa-long-arrow-right"></i>
                    </a>
                  </h4>
                  <p>
                    The Neo-banks are changing the canvas of fin-tech by rapidly
                    bridging...{" "}
                  </p>
                  <ul className="link-related-btn">
                    <li className="link-single-blog">Business</li>
                    <li className="link-single-blog">Send Money</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bg">
          {/* <section className="testimonial_section"> */}
          <div className="container scale_business_section contact-subtribe">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <SubscribeComponent 
                        heading={<><span>Start crossing borders with ease.</span>
                          <br></br> Unlock a world of possibilities for your business
                          with just a few clicks.</>}
                        subheading={<p className="text-white mtb">Join our community today.</p>}
                        showVerticle={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </section> */}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default BlogSinglecbdc;
