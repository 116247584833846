import axios from "axios";
import React from "react";
import { useState } from "react";
import { SubscribtionMailurl } from "../../../constant";
import PageLoading from "../loading/page-loading";

function SubscribeComponent({heading, subheading, showVerticle}) {
    const [loading, setLoading] = useState(false);
    const [apiScsMessage, setApiScsMessage] = useState("")
    const [apiErrMessage, setApiErrMessage] = useState("")
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const [emailInput, setEmailInput] = useState({
        emailAddress: ''
    });

    const emailHandler = (e) => {
        const { name, value } = e.target
        setEmailInput({ ...emailInput, [name]: value })
    }

    const emailSubmit = () => {
        try {
            setApiScsMessage('')
            setApiErrMessage('')
            if (emailRegex.test(emailInput.emailAddress)) {
                setLoading(true)
                axios.post(SubscribtionMailurl, emailInput)
                    .then((res) => {
                        if (res.status === 200) {
                            setApiScsMessage(res.data.message)
                            setLoading(false)
                            setEmailInput({
                                emailAddress: ''
                            })
                        } else {
                            setApiErrMessage(res.data.message)
                            setEmailInput({
                                emailAddress: ''
                            })
                            setLoading(false)
                        }
                    })
                    .catch((err) => {
                        setLoading(false)
                        setApiErrMessage(err.response.data.message)
                    })
            } else {
                setApiErrMessage('Enter a valid email')
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <h2>{heading}</h2>
            <p className="text-white mtb">{subheading}</p>
            {showVerticle && <div className="vertical-line" />}
            <div className="input-group banner_input_btn m-0 " >
                <input type="email" name="emailAddress" onChange={emailHandler} value={emailInput.emailAddress} className="form-control form-manage-subscribe" placeholder="Enter your email" required />

                <div className="input-group-append btn-subscribe-append">
                    <button className="btn" type="button" onClick={emailSubmit}>
                        {loading ? <PageLoading /> : 'Subscribe now'}
                    </button>
                </div>
            </div>
            <div style={{ marginLeft: '1rem' }} className="mt-3">
                {apiScsMessage && <p className="text-success" style={{ fontSize: 16 }}>
                    {apiScsMessage} </p>}
                {apiErrMessage && <p className="text-danger" style={{ fontSize: 16 }}>
                    {apiErrMessage} </p>}
            </div>
        </>
    );
}

export default SubscribeComponent;